import React, { useContext, useEffect, useMemo, useState } from 'react';

import { ZoomIn, ZoomOut } from '@mui/icons-material';
import { uuid4 } from '@sentry/utils';
import { Button, Modal, notification, Space, Tooltip, Typography } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import {
  TransformComponent,
  TransformWrapper,
  useControls,
} from 'react-zoom-pan-pinch';
import { createSelector } from 'reselect';
import { TextArea } from 'semantic-ui-react';
import Loading from 'src/components/Loading/Loading';
import Page from 'src/components/Page/Page';
import PaywallPreview from 'src/components/PaywallPreview/PaywallPreview';
import { TChangeOptionNames } from 'src/utils/paywall';
import { namiPrimaryBlue } from 'src/variables';
import styled from 'styled-components';

import api from '../../../../api';
import type { LegalCitationType } from '../../../../api/types/legalCitation.types';
import type {
  PaywallType,
  TProductGroup,
} from '../../../../api/types/paywall.types';
import {
  TTemplateWarning,
  type TCarouselSettings,
  type TCarouselSlide,
} from '../../../../api/types/paywallTemplate.types';
import { useBooleanState } from '../../../../hooks';
import {
  useFontsQuery,
  useOrgFontsQuery,
} from '../../../../hooks/queries/font.hooks';
import { useTemplateIconsQuery } from '../../../../hooks/queries/paywall.hooks';
import { useActions, useAppSelector } from '../../../../hooks/redux.hooks';
import type { RootState } from '../../../../redux';
import PaywallBuilderSlice from '../../../../redux/PaywallBuilderSlice';
import {
  buildLegalVariables,
  constructIdMetadata,
  createPaywallWarnings,
  interpolate,
} from '../../../../utils/interpolation';
import { NavigationContext } from '../../AdminRouter';
import GroupsMenuLegacy from '../legacy/GroupsMenuLegacy';
import TemplateEditorLegacy from '../legacy/TemplateEditorLegacy';
import PaywallPreviewHeader from '../preview-menu/PaywallPreviewHeader';
import { buildMediaVariables, buildStateGroups } from '../utils/variables';
import PaywallBuilderBottomDrawer from './PaywallBuilderBottomDrawer';
import PaywallBuilderDrawer from './PaywallBuilderDrawer';
import PaywallHeader from './PaywallHeader';
import PaywallVerticalMenu from './PaywallVerticalMenu';
import CapabilitiesList from './capabilities/CapabilitiesList';
import CarouselSlideEditor from './editor/CarouselSlideEditor';
import GroupsMenu from './editor/GroupsMenu';
import SectionAndComponentEditor from './editor/SectionAndComponentEditor';
import TemplateEditor from './editor/TemplateEditor';
import TreeView from './editor/TreeView';
import PaywallSettingsModal from './modals/PaywallSettingsModal';
import ProductGroupsEditModal from './modals/ProductGroupsEditModal';
import ProductGroupsModal from './modals/ProductGroupsModal';
import UnsavedChangesModal from './modals/UnsavedChangesModal';
import ProductEditor from './products/ProductEditor';
import ProductEditorDrawer from './products/ProductEditorDrawer';

const Wrapper = styled(TransformWrapper)`
  height: 100% !important;
  width: 100% !important;
`;

const PreviewerWrapper = styled(TransformComponent)`
  position: relative;
  width: 100%;
`;

const selector = createSelector(
  [
    ({ paywallBuilder }: RootState) => paywallBuilder,
    ({ root }: RootState) => root.currentApp,
  ],
  (paywallBuilder, app) => ({
    appId: app?.id,
    paywall: paywallBuilder.paywall || null,
    template: paywallBuilder.paywall?.template || null,
    mediaList: paywallBuilder.mediaList,
    variables: paywallBuilder.paywall?.template.variables || {},
    defaultLanguage: app?.default_language,
    menus: paywallBuilder.menus,
    skuItems: paywallBuilder.skuItems,
    editingMenuItem: paywallBuilder.editingMenuItem,
    hasChanges: paywallBuilder.hasChanges,
    productGroups: paywallBuilder.productGroups,
    currentGroupId: paywallBuilder.groupId,
    platformId: paywallBuilder.platformId,
    platforms: paywallBuilder.platforms,
    inDarkMode: paywallBuilder.inDarkMode,
    formFactor: paywallBuilder.formFactor,
    previewDevice: paywallBuilder.previewDevice,
    orientation: paywallBuilder.orientation,
    launch: paywallBuilder.launch,
    focusedState: paywallBuilder.focusedState,
    flowState: paywallBuilder.flowState,
    safeAreaTop: paywallBuilder.safeAreaTop,
    hasNotch: paywallBuilder.hasNotch,
    isLoggedIn: paywallBuilder.isLoggedIn,
    anySkuHasTrialOffer: paywallBuilder.anySkuHasTrialOffer,
    anySkuHasIntroOffer: paywallBuilder.anySkuHasIntroOffer,
    anySkuHasPromoOffer: paywallBuilder.anySkuHasPromoOffer,
    currentPage: paywallBuilder.currentPage,
    customer: paywallBuilder.customer,
    menusWithChanges: paywallBuilder.menusWithChanges,
    groupName: paywallBuilder.fieldGroupName,
    componentId: paywallBuilder.editingComponentId,
    openHeaderIds: paywallBuilder.openHeaderIds,
    minSDKVersion: paywallBuilder.minSDKVersion,
    pendingMinSDKVersion: paywallBuilder.pendingSDKVersion,
    pendingSDKChanges: paywallBuilder.pendingSDKChanges,
  })
);

export default function PaywallBuilderPage() {
  const history = useHistory();
  const paywallId = useParams<{ paywallID: string }>().paywallID;
  const iconsQuery = useTemplateIconsQuery();
  const navigationContext = useContext(NavigationContext);
  const [isLoading, , stopLoading] = useBooleanState(true);
  const [isOpen, openModal, closeModal] = useBooleanState(false);
  // const [groupName, setGroupName] = useState<string | null>(null);
  const [citations, setCitations] = useState<Record<string, LegalCitationType>>(
    {}
  );
  const [isUpdating, startUpdating, stopUpdating] = useBooleanState(false);
  // Caches and loads fonts into the browser
  useFontsQuery();
  useOrgFontsQuery();

  const actions = useActions(PaywallBuilderSlice.actions);
  const {
    paywall,
    template,
    variables,
    appId,
    defaultLanguage,
    mediaList,
    menus,
    skuItems,
    editingMenuItem,
    hasChanges,
    productGroups,
    currentGroupId,
    platformId,
    platforms,
    inDarkMode,
    formFactor,
    previewDevice,
    orientation,
    launch,
    focusedState,
    flowState,
    safeAreaTop,
    hasNotch,
    isLoggedIn,
    anySkuHasTrialOffer,
    anySkuHasIntroOffer,
    anySkuHasPromoOffer,
    currentPage,
    customer,
    menusWithChanges,
    groupName,
    componentId,
    openHeaderIds,
    minSDKVersion,
    pendingMinSDKVersion,
    pendingSDKChanges,
  } = useAppSelector(selector);
  const initialScale = formFactor === 'television' ? 0.6 : 0.75;

  const language = paywall?.language!;

  const selectedProducts = useMemo(() => {
    if (platformId === null) return {};
    return Object.entries(skuItems).reduce(
      (output, [groupId, platformItems]) => {
        const items = Object.values(platformItems[platformId]);
        const item = items.find((item) => item.selected) || items[0];
        return { ...output, [groupId]: item?.sku_id || null };
      },
      {}
    );
  }, [skuItems, platformId]);

  const currentPlatform = useMemo(() => {
    if (platformId === null) return undefined;
    return platforms.find((value) => value.id === platformId);
  }, [platforms, platformId]);

  const parsedTemplate = useMemo(() => {
    if (template === null) return null;
    const citation = citations[language] || citations[defaultLanguage] || null;
    const groups = buildStateGroups(template, productGroups);
    const state = {
      ...(template.initialState || {}),
      groups,
      currentGroupId,
      selectedProducts,
      darkMode: inDarkMode,
      formFactor,
      orientation,
      launch,
      focusedState,
      safeAreaTop,
      hasNotch,
      isLoggedIn,
      anySkuHasTrialOffer,
      anySkuHasIntroOffer,
      anySkuHasPromoOffer,
      currentPage,
      openHeaderIds,
    };
    const replacements = {
      state: {
        ...state,
        openHeaderIds: Array.from(openHeaderIds),
      },
      launch,
      customer,
      var: variables,
      media: buildMediaVariables(mediaList, { convertToUrl: true }),
      legal: citation ? buildLegalVariables(citation) : {},
      icon: (iconsQuery.data || []).reduce((output, icon) => {
        return { ...output, [icon.name]: icon.ant };
      }, {}),
      platform: {
        id: currentPlatform?.id,
        type: currentPlatform?.type,
      },
      flow: {
        previousStepAvailable: flowState,
        nextStepAvailable: flowState,
        exists: flowState,
      },
    };
    return interpolate(template, interpolate(replacements, replacements));
  }, [
    template,
    variables,
    citations,
    defaultLanguage,
    language,
    iconsQuery.data,
    currentGroupId,
    mediaList,
    productGroups,
    selectedProducts,
    inDarkMode,
    formFactor,
    orientation,
    launch,
    focusedState,
    flowState,
    safeAreaTop,
    isLoggedIn,
    anySkuHasTrialOffer,
    anySkuHasIntroOffer,
    anySkuHasPromoOffer,
    currentPage,
    customer,
    openHeaderIds,
    currentPlatform,
    hasNotch,
  ]);

  const v2Ready =
    parsedTemplate &&
    !Array.isArray(parsedTemplate['ui.v2Ready']) &&
    parsedTemplate['ui.v2Ready'];

  useEffect(() => {
    if (parsedTemplate !== null && v2Ready) {
      const newMeta = constructIdMetadata(parsedTemplate.pages);
      actions.setIdLocations(newMeta.idLocations);
      actions.setIdLocationTypes(newMeta.idLocationsByType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedTemplate]);

  const templateWarnings = useMemo(() => {
    if (parsedTemplate === null || !v2Ready) return new Set<TTemplateWarning>();
    return createPaywallWarnings(
      parsedTemplate,
      skuItems,
      productGroups,
      formFactor
    );
  }, [parsedTemplate, skuItems, productGroups, v2Ready, formFactor]);

  const formVersion: 'formGroups' | 'v2Form' = useMemo(() => {
    if (parsedTemplate && v2Ready) return 'v2Form';
    return 'formGroups';
  }, [parsedTemplate, v2Ready]);

  useEffect(() => {
    const pendingChangesNiceNames: string[] = pendingSDKChanges.map(
      (change) => TChangeOptionNames[change]
    );
    const pendingChangesString = (
      pendingChangesNiceNames || ['this feature']
    ).join(' and ');
    if (pendingMinSDKVersion) {
      Modal.warning({
        title: 'New Minimum Nami SDK Version',
        content: (
          <>
            <Typography.Paragraph>
              Adding{' '}
              <span style={{ fontWeight: '500' }}>{pendingChangesString}</span>{' '}
              will increase the minimum required Nami SDK version to{' '}
              <span style={{ fontWeight: '600', color: namiPrimaryBlue }}>
                {pendingMinSDKVersion?.semver}
              </span>
              .
            </Typography.Paragraph>
            <Typography.Link
              href="https://learn.namiml.com/public-docs/get-started/sdks/release-notes"
              target="_blank"
              rel="noreferrer"
            >
              Release Notes
            </Typography.Link>
          </>
        ),
        centered: true,
        closable: false,
        maskClosable: false,
        onOk: () => actions.acknowledgePendingMinSDKVersion(),
        keyboard: false,
        zIndex: 1002,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingMinSDKVersion]);

  const formGroup = useMemo(() => {
    const groups = parsedTemplate && parsedTemplate['ui.formGroups'];
    return (groups && groups.find(({ title }) => title === groupName)) || null;
  }, [groupName, parsedTemplate]);

  useEffect(() => {
    navigationContext.hideNavigation();
    return () => navigationContext.showNavigation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    actions.resetState();
    Promise.all([
      api
        .getPaywall(paywallId)
        .then(checkForMatchingApp)
        .then(fetchAndSetPaywallData),
      api.getPlatforms(appId).then(actions.setPlatforms),
      fetchAndSetMedia(),
      fetchAndSetCitations(),
    ]).finally(stopLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId, paywallId]);

  const isRendering = paywall === null || parsedTemplate === null || isLoading;

  const [previewScale, setPreviewScale] = useState<number>(1.0);

  const Controls = () => {
    const { zoomIn, zoomOut, setTransform, centerView, resetTransform } =
      useControls();
    return (
      <Space direction="horizontal" size={0}>
        <Tooltip title="Zoom out">
          <Button
            size="small"
            icon={
              <ZoomOut style={{ fontSize: 16, transform: 'translateY(3px)' }} />
            }
            onClick={() => zoomOut(0.2)}
            disabled={previewScale <= 0.4}
          />
        </Tooltip>
        <Tooltip title="Reset zoom and position">
          <Button
            size="small"
            onClick={() => {
              centerView(initialScale);
            }}
          >{`${Math.round(previewScale * 100)}%`}</Button>
        </Tooltip>
        <Tooltip title={'Zoom in'}>
          <Button
            size="small"
            icon={
              <ZoomIn style={{ fontSize: 16, transform: 'translateY(3px)' }} />
            }
            onClick={() => zoomIn(0.2)}
            disabled={previewScale >= 5}
          />
        </Tooltip>
      </Space>
    );
  };

  return (
    <>
      {isRendering ? (
        <Loading />
      ) : (
        <>
          <PaywallHeader
            paywallName={
              paywall?.name
                ? paywall.name + (paywall.archived ? ' [Archived]' : '')
                : 'Loading...'
            }
            isSaving={isUpdating}
            isLoading={isLoading}
            onSave={() => performPaywallUpdate()} // Cannot receive an event
            onOpenSettings={openModal}
            archived={!!paywall?.archived}
            version={formVersion}
            warnings={templateWarnings}
          />
          <PaywallPreviewHeader />
          <Page
            title="Paywall Editor"
            layout="full"
            contentContainerClass="full-main-container"
          >
            <Wrapper
              centerOnInit={true}
              doubleClick={{ excluded: ['namipreviewer'] }}
              onTransformed={(_ref, state) => {
                setPreviewScale(state.scale);
              }}
              limitToBounds={false}
              minScale={0.4}
              initialScale={initialScale}
              wheel={{ excluded: ['namipreviewer'] }}
            >
              <PreviewerWrapper>
                {parsedTemplate && (
                  <PaywallPreview
                    template={parsedTemplate}
                    device={formFactor}
                    previewDevice={previewDevice}
                    orientation={orientation}
                    focusedState={focusedState}
                    currentPage={currentPage}
                    groupId={currentGroupId}
                    minSDKVersion={minSDKVersion}
                  />
                )}
              </PreviewerWrapper>
              <PaywallBuilderDrawer
                open={editingMenuItem === 'components'}
                title={<span style={{ fontSize: 14 }}>Components</span>}
                closable={false}
                className="verticalScrollDrawer"
              >
                {formVersion === 'formGroups' && (
                  <>
                    <GroupsMenu
                      formGroups={parsedTemplate['ui.formGroups']}
                      onGroupClick={(groupName) =>
                        actions.setFieldGroupName(groupName)
                      }
                    />
                    <GroupsMenuLegacy
                      onGroupClick={(groupName) =>
                        actions.setFieldGroupName(groupName)
                      }
                    />
                  </>
                )}
                {formVersion === 'v2Form' && (
                  <TreeView template={parsedTemplate} />
                )}
              </PaywallBuilderDrawer>
              <ProductEditorDrawer formVersion={formVersion} />
              <PaywallBuilderDrawer
                open={editingMenuItem === 'capabilities'}
                title={<span style={{ fontSize: 14 }}>Capabilities</span>}
                closable={false}
                className="verticalScrollDrawer"
              >
                <CapabilitiesList
                  startLoading={startUpdating}
                  stopLoading={stopUpdating}
                  onProductGroupChange={() => fetchAndSetPaywallData(paywall)}
                />
              </PaywallBuilderDrawer>
              <PaywallBuilderDrawer
                open={editingMenuItem === 'template'}
                title={<span style={{ fontSize: 14 }}>Template</span>}
                closable={false}
                className="verticalScrollDrawer"
              >
                <TextArea
                  style={{
                    width: '100%',
                    height: '100%',
                    fontFamily: 'monospace',
                    fontSize: 'medium',
                  }}
                  defaultValue={JSON.stringify(template, null, 2)}
                  rows={40}
                  wrap="off"
                  onChange={(e) => {
                    try {
                      const value = JSON.parse(e.target.value);
                      actions.setTemplate(value);
                    } catch (e) {
                      return;
                    }
                  }}
                />
              </PaywallBuilderDrawer>
              <PaywallVerticalMenu
                onOpenSettings={openModal}
                hasCapabilities={formVersion === 'v2Form'}
              />
              <TemplateEditorLegacy
                isOpen={!!groupName}
                groupName={groupName}
                onClose={() => actions.setFieldGroupName(null)}
              />
              <TemplateEditor
                formGroup={formGroup}
                onClose={() => actions.setFieldGroupName(null)}
              />
              <SectionAndComponentEditor componentId={componentId} />
              <ProductEditor formVersion={formVersion} />
              <CarouselSlideEditor
                carouselSettings={parsedTemplate['ui.carousels']}
                slides={parsedTemplate?.initialState?.slides}
                formVersion={formVersion}
              />
              <PaywallSettingsModal
                open={isOpen}
                isSaving={isUpdating}
                onSave={(value) =>
                  updatePaywallFromSettings(value).then(closeModal)
                }
                onArchive={() => {
                  updatePaywallFromSettings({
                    archived: !paywall.archived,
                  }).then(closeModal);
                }}
                archived={!!paywall.archived}
                onClose={closeModal}
              />
              <UnsavedChangesModal
                title="You have unsaved changes"
                description="Are you sure you want to leave without saving your changes?"
                hasChanges={hasChanges}
                onSave={() => performPaywallUpdate()} // Cannot receive an event
              />
              <ProductGroupsModal
                onChange={() => {
                  startUpdating();
                  fetchAndSetPaywallData(paywall).finally(stopUpdating);
                }}
              />
              <ProductGroupsEditModal
                onChange={() => {
                  startUpdating();
                  actions.setEditingProductId(null);
                  actions.setEditingSlideId(null);
                  fetchAndSetPaywallData(paywall).finally(() => {
                    actions.updateProductGroupState();
                    stopUpdating();
                  });
                }}
                onClose={() => actions.setEditingProductGroups(false)}
              />
              <PaywallBuilderBottomDrawer Controls={Controls} />
            </Wrapper>
          </Page>
        </>
      )}
    </>
  );

  function updatePaywallFromSettings(values: Partial<PaywallType>) {
    return performPaywallUpdate(values).then(actions.setPaywall);
  }

  function performPaywallUpdate(
    paywallValues?: Partial<PaywallType>
  ): Promise<PaywallType> {
    startUpdating();
    const itemPromises = Object.entries(skuItems).reduce(
      (promises, [groupId, platformItems]) => [
        ...promises,
        ...Object.entries(platformItems)
          .filter(([platform, _items]) => {
            return menusWithChanges.has(menus[groupId][platform].id);
          })
          .map(([platform, items]) => {
            const data = { platform, ordered_items: Object.values(items) };
            return api.updatePaywallMenu(menus[groupId][platform].id, data);
          }),
      ],
      [] as Promise<PaywallType>[]
    );
    const mediaPromises = Object.values(mediaList)
      .filter((media) => typeof media.content !== 'string')
      .map((media) =>
        media.id
          ? api.updatePaywallMedia(media.id, media)
          : api.addPaywallMedia(media).then(actions.updateMedia)
      );

    const data = {
      ...paywall,
      ...(paywallValues || {}),
      template: template || undefined,
    };
    return Promise.all([
      api.updatePaywall(paywallId, data),
      ...itemPromises,
      ...mediaPromises,
    ])
      .then(([paywall]) => {
        notification.success({ message: 'Paywall saved.', top: 0 });
        actions.resetChangeState();
        actions.resetMenuChangeState();
        return paywall;
      })
      .finally(stopUpdating);
  }

  function checkForMatchingApp(paywall: PaywallType): PaywallType | null {
    if (!paywall || paywall.app !== appId) {
      notification.error({
        message: "Paywall can't be found",
      });
      history.push('/paywalls');
      return null;
    }
    return paywall;
  }

  function fetchAndSetPaywallData(paywall: PaywallType | null): Promise<void> {
    if (!paywall) return new Promise<void>((resolve) => resolve);
    return Promise.all([
      api.getProductGroups(paywallId, { pageSize: 200 }),
      api.getPaywallMenus(paywallId, { pageSize: 1000 }),
      api.getProducts(appId, { pageSize: 2500, archived: false }),
    ])
      .then(([productGroups, menus, { results: products }]) =>
        Promise.all([
          productGroups,
          menus,
          products,
          attachPaywallSlidesIfNeeded(paywall, productGroups),
        ] as const)
      )
      .then(([productGroups, menus, products, paywall]) => {
        actions.initializeState({ productGroups, menus, products, paywall });
      });
  }

  function fetchAndSetCitations(): Promise<void> {
    return api.getAppCitations(appId).then((citations) => {
      const citationsMap = citations.reduce(
        (output, citation) => ({ ...output, [citation.language]: citation }),
        {}
      );
      setCitations(citationsMap);
    });
  }

  function fetchAndSetMedia(): Promise<unknown> {
    return api.getPaywallMedia(paywallId).then(actions.setMediaList);
  }
}

function attachPaywallSlidesIfNeeded(
  paywall: PaywallType,
  productGroups: TProductGroup[]
): PaywallType | Promise<PaywallType> {
  // Check if groups need to be created
  const requiredGroups = paywall?.template['ui.requiredGroups'];
  const dynamicGroups = !!paywall?.template['ui.dynamicAddGroups'];
  const groupsAreSet =
    (!dynamicGroups &&
      (typeof requiredGroups !== 'number' ||
        requiredGroups === productGroups.length)) ||
    (dynamicGroups && productGroups.length);
  if (!groupsAreSet) return paywall;

  // Check if slides exist and were populated
  const templateState = paywall.template.initialState || {};
  const groupSlides = templateState.slides || {};
  const carousels = paywall.template['ui.carousels'] || null;
  const shouldPopulateSlides =
    carousels &&
    (dynamicGroups ||
      (!dynamicGroups &&
        Object.keys(groupSlides).length !== productGroups.length));

  if (!shouldPopulateSlides) return paywall;
  const productGroupCarousel =
    (carousels['carouselName'] &&
      carousels['carouselName'].productGroupSlides) ||
    false;

  let newGroupSlides;
  if (productGroupCarousel) {
    newGroupSlides = productGroups.slice(0, 1).reduce((output, group) => {
      const existingSlides =
        Object.keys(templateState.slides || {}).length > 0
          ? (templateState.slides || {})[group.id]['carouselName']
          : [];
      return {
        ...output,
        [group.id]: fillSlidesForEachProductGroup(
          carousels,
          productGroups,
          existingSlides
        ),
      };
    }, templateState.slides || {});
  } else {
    // Populate slides in the initial state
    newGroupSlides = productGroups.reduce((output, group) => {
      if (group.id in output) return output;
      return { ...output, [group.id]: fillSlidesIds(carousels) };
    }, templateState.slides || {});
  }
  const newTemplate = {
    ...paywall.template,
    initialState: { ...templateState, slides: newGroupSlides },
  };
  return api.updatePaywall(paywall.id, { template: newTemplate });
}

function fillSlidesIds(carousels: TCarouselSettings): {
  [carouselName: string]: TCarouselSlide[];
} {
  return Object.entries(carousels).reduce((output, [name, carousel]) => {
    const slides = carousel.slides.map((s) => ({ ...s, id: uuid4() }));
    return { ...output, [name]: slides };
  }, {});
}

function fillSlidesForEachProductGroup(
  carousels: TCarouselSettings,
  productGroups: TProductGroup[],
  existingSlides: TCarouselSlide[]
) {
  const productGroupMap: Record<string, TProductGroup> = productGroups.reduce(
    (output, group) => {
      return { ...output, [group.id]: group };
    },
    {}
  );
  let productGroupIds = new Set(Object.keys(productGroupMap));

  return Object.entries(carousels).reduce((output, [name, carousel]) => {
    existingSlides.forEach((slide) => {
      if (slide.productGroupId) productGroupIds.delete(slide.productGroupId);
    });
    let newSlideGroup = existingSlides.reduce((output, item) => {
      //If slide product group id matches a paywall product group, update ref
      if (productGroupMap[item.productGroupId]) {
        return [
          ...output,
          {
            ...item,
            productGroupRefId: productGroupMap[item.productGroupId].ref,
          },
        ];
      }

      //If slide product group id doesn't match, look for a match by ref
      const productGroupByRef = productGroups.find(
        (value) => value.ref === item?.productGroupRefId
      );
      if (productGroupByRef) {
        productGroupIds.delete(productGroupByRef.id);
        return [
          ...output,
          {
            ...item,
            productGroupId: productGroupByRef.id,
          },
        ];
      }
      //If no match by either product group id or ref id, don't return
      return output;
    }, [] as TCarouselSlide[]);

    productGroupIds.forEach((missingGroupId) => {
      const group = productGroupMap[missingGroupId];
      newSlideGroup.push({
        ...{ ...carousel.newSlide, title: group.display_name },
        id: uuid4(),
        productGroupId: group.id,
        productGroupRefId: group.ref,
      });
    });

    return { ...output, [name]: newSlideGroup };
  }, {});
}

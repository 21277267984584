/* eslint-disable no-template-curly-in-string */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import { uuid4 } from '@sentry/utils';
import { TSchemaDefinition } from 'src/api/types/schema.types';
import { TFormFactor } from 'src/pages/admin/campaigns/detail/selectors/mapsAndOptions';
import {
  addFeaturedToComponent,
  addIntroEligibilityToComponent,
  addPromoEligibilityToComponent,
  addScreenreaderTextToComponent,
  addSelectedToComponent,
  addToCapabilitiesArray,
  addTrialEligibilityToComponent,
  ComponentCapabilitySettings,
  duplicateCollapseComponent,
  duplicateComponentAndChildren,
  findContentContainerFooterChildLocations,
  findHeaderChildLocations,
  findProductContainerChildLocations,
  formFieldReadableLabel,
  generateFullComponent,
  generateNewComponentVariable,
  getIdFromLocation,
  removeCapability,
  removeFromCapabilitiesArray,
  TAssertionType,
  TNonProtectedComponentProperty,
  TVariableMeta,
} from 'src/pages/admin/paywalls/utils/componentGeneration';
import { addToArray, moveInArray } from 'src/utils/array';
import { constructIdMetadata } from 'src/utils/interpolation';
import { parseToSemver, TSemverObj } from 'src/utils/parsing';
import {
  deconstructVariable,
  findComponentFromLocation,
  findPositionBelowLeaf,
  generateNextIdForType,
  getChangeKeyForNewComponent,
  getChangeKeysForNewConditions,
  getChangeKeysForNewTestObjects,
  getChangeKeysForNewVariable,
  getChangeKeysForVariableValueChange,
  getNewMinSDKVersionFromChange,
  moveUpTreeFromLocation,
  parseLocationString,
  platformCompatibleWithFormFactor,
  pullOutAllColorsFromPaywall,
  setValue,
  TChangeOptions,
} from 'src/utils/paywall';
import { returnSchemaProperties } from 'src/utils/schema';
import { toCamelCase } from 'src/utils/string';

import { PlatformType } from '../api/types/main.types';
import {
  PaywallType,
  PreviewDeviceMeta,
  SelectableItemType,
  TDevice,
  TDeviceOrientation,
  TMenuItem,
  TPaywallMedia,
  TPaywallMenu,
  TPreviewDevice,
  TProductErrorState,
  TProductGroup,
} from '../api/types/paywall.types';
import {
  TAllComponentDetailValues,
  TBaseComponent,
  TButtonContainer,
  TCarouselFieldType,
  TCarouselSettings,
  TCarouselSlide,
  TCarouselSlidesState,
  TComponent,
  TComponentLocation,
  TConditionalComponent,
  TContainer,
  TFieldSettings,
  TIdLocations,
  TIdTypeLocation,
  TImageComponent,
  TNewComponentBuilderMeta,
  TPaywallPage,
  TPaywallTemplate,
  TProductContainer,
  TProductVariable,
  TProductVariableType,
  TRepeatingList,
  TTemplateCapability,
  TTestObject,
  TTextComponent,
} from '../api/types/paywallTemplate.types';
import { TProduct } from '../api/types/sku.types';
import {
  buildFieldGroups,
  IFieldGroups,
} from '../pages/admin/paywalls/utils/formFieldBuilding';
import { convertProductToItem } from '../pages/admin/paywalls/utils/skusAndItems';
import { Optional } from '../utils/types';

export type TPlatformMenuMap = {
  [groupId: string]: { [platformId: string]: TPaywallMenu };
};

export type TPlatformProducts = {
  [groupId: string]: {
    [platformId: string]: { [itemId: string]: SelectableItemType };
  };
};

export type TAddedGroupProducts = {
  [platformId: string]: Array<string>;
};

export type TAvailableProducts = {
  [platformId: string]: { [productGroupId: string]: SelectableItemType[] };
};

type PaywallBuilderState = {
  formFactor: TDevice;
  orientation: TDeviceOrientation;
  inDarkMode: boolean;
  anySkuInvalid: boolean;
  anySkuUnavailable: boolean;
  focusedState: boolean;
  flowState: boolean;
  isLoggedIn: boolean;
  hasChanges: boolean;
  platformId: string | null;
  groupId: string | null;
  paywall: PaywallType | null;
  platforms: PlatformType[];
  productGroups: TProductGroup[];
  products: { [productId: string]: TProduct };
  skuItems: TPlatformProducts;
  availableItems: TAvailableProducts;
  mediaList: { [name: string]: Optional<TPaywallMedia, 'id'> };
  menus: TPlatformMenuMap;
  menusWithChanges: Set<string>;
  editingMenuItem: string | null;
  editingProductMenuTabItem: string | null;
  editingComponentEditorTabItem: string | null;
  editingProductId: string | null;
  editingSlideId: string | null;
  viewingSlideId: string | null;
  // TODO: Kill this once we can confirm "ui.formGroups" exists in all paywall templates
  fieldGroups: IFieldGroups | null;
  fieldGroupName: string | null;
  editingComponentId: string | null;
  slides: { [key: string]: string | null };
  editingProductGroups: boolean;
  launch: {
    productGroups: string[];
    customAttributes: {};
    customObject: { [key: string]: any };
  };
  customer: {};
  previewDevice: TPreviewDevice;
  safeAreaTop: number;
  hasNotch: boolean;
  anySkuHasTrialOffer: boolean;
  anySkuHasIntroOffer: boolean;
  anySkuHasPromoOffer: boolean;
  currentPage: string;
  expandedTreeKeys: string[];
  selectedTreeKey: string | null;
  idLocations: TIdLocations;
  idTypeLocations: TIdTypeLocation;
  customObjectSchema: TSchemaDefinition[];
  openHeaderIds: Set<string>;
  videoPlaying: boolean;
  videoMuted: boolean;
  minSDKVersion: TSemverObj;
  pendingSDKVersion: TSemverObj | null;
  pendingSDKChanges: TChangeOptions[];
  recentColors: Set<string>;
  hasSelectedFlag: boolean;
};

const initialState: PaywallBuilderState = {
  formFactor: 'phone',
  orientation: 'portrait',
  inDarkMode: false,
  anySkuInvalid: false,
  anySkuUnavailable: false,
  focusedState: false,
  flowState: false,
  isLoggedIn: false,
  hasChanges: false,
  platformId: null,
  groupId: null,
  paywall: null,
  products: {},
  platforms: [],
  productGroups: [],
  skuItems: {},
  availableItems: {},
  menus: {},
  menusWithChanges: new Set(),
  mediaList: {},
  editingMenuItem: 'components',
  editingProductMenuTabItem: 'menu',
  editingComponentEditorTabItem: 'appearance',
  editingProductId: null,
  editingSlideId: null,
  viewingSlideId: null,
  fieldGroups: null,
  fieldGroupName: null,
  editingComponentId: null,
  slides: {},
  editingProductGroups: false,
  launch: { productGroups: [], customAttributes: {}, customObject: {} },
  customer: {},
  previewDevice: 'iphone121314',
  safeAreaTop: 0,
  hasNotch: false,
  anySkuHasTrialOffer: false,
  anySkuHasIntroOffer: false,
  anySkuHasPromoOffer: false,
  currentPage: 'page1',
  expandedTreeKeys: [
    'root',
    'backgroundContainer',
    'header',
    'contentContainer',
    'footer',
  ],
  selectedTreeKey: null,
  idLocations: {},
  idTypeLocations: {},
  customObjectSchema: [],
  openHeaderIds: new Set(),
  videoPlaying: true,
  videoMuted: true,
  minSDKVersion: parseToSemver('3.1.0'),
  pendingSDKVersion: null,
  pendingSDKChanges: [],
  recentColors: new Set(),
  hasSelectedFlag: false,
};

export default createSlice({
  name: 'PaywallBuilder',
  initialState,
  reducers: {
    resetState: () => initialState,
    initializeState(
      state,
      {
        payload: { paywall, menus, products, productGroups },
      }: PayloadAction<{
        paywall: PaywallType;
        menus: TPaywallMenu[];
        products: TProduct[];
        productGroups: TProductGroup[];
      }>
    ) {
      const platformMenus: TPlatformMenuMap = {};
      const items: TPlatformProducts = {};
      const selectedProducts: TAddedGroupProducts = {};
      const availableItems: TAvailableProducts = {};
      menus.forEach((menu) => {
        const { product_group: groupId, platform: platformId } = menu;
        platformMenus[groupId] = platformMenus[groupId] || {};
        platformMenus[groupId][platformId] = menu;

        items[groupId] = items[groupId] || {};
        items[groupId][platformId] = items[groupId][platformId] || {};

        availableItems[platformId] = {
          [groupId]: [],
        };

        selectedProducts[groupId] = selectedProducts[groupId] || [];

        menu.ordered_items.forEach((item) => {
          const itemEntitlements =
            products.find((product) => product.id === item.sku_id)
              ?.entitlements || [];
          const itemWithEntitlements: TMenuItem = {
            ...item,
            entitlements: itemEntitlements,
          };
          selectedProducts[groupId].push(item.sku_id);
          items[groupId][platformId][item.id] = itemWithEntitlements;
        });
      });

      let productVars: { [key: string]: string } = {};
      if (
        paywall.template['ui.productSettings']?.variablesList &&
        paywall.template['ui.productSettings'].variablesList.length
      ) {
        productVars = (
          paywall.template['ui.productSettings']?.variablesList || []
        ).reduce((output, variable) => {
          return {
            ...output,
            [variable.variable]: variable.value,
          };
        }, {} as { [key: string]: string });
      } else {
        productVars = paywall.template['ui.productSettings']?.variables || {};
      }
      products.forEach((product) => {
        const { id: productId, platform: platformId } = product;
        productGroups.forEach((group) => {
          if (selectedProducts[group.id].includes(productId)) return;
          availableItems[platformId][group.id] = [
            ...(availableItems[platformId][group.id] || []),
            convertProductToItem(product, productVars),
          ];
        });
      });

      state.paywall = paywall;
      if (!paywall.template['ui.formGroups']) {
        // TODO: Kill this once we can confirm "ui.formGroups" exists in all paywall templates
        state.fieldGroups = buildFieldGroups(paywall.template);
      }
      state.productGroups = productGroups;
      state.groupId =
        productGroups.find((group) => group.default)?.id || productGroups[0].id;
      state.menus = platformMenus;
      state.skuItems = items;
      state.availableItems = availableItems;
      state.products = products.reduce((output, product) => {
        return { ...output, [product.id]: product };
      }, {});
      state.orientation = 'portrait';
      const firstFactor = paywall.template?.['ui.formFactors']?.[0];
      if (firstFactor) {
        // TODO: Kill this if we can confirm "ui.formFactors" exists in all paywall templates
        state.orientation = firstFactor.supports_landscape
          ? 'landscape'
          : 'portrait';
        state.formFactor = firstFactor.form_factor;

        let platformsByFormFactor: Record<TFormFactor, string[]> = {
          phone: [],
          tablet: [],
          television: [],
          desktop: [],
        };

        state.platforms.forEach((value) => {
          if (platformCompatibleWithFormFactor(value.type, 'phone')) {
            platformsByFormFactor.phone.push(value.id);
          }
          if (platformCompatibleWithFormFactor(value.type, 'tablet')) {
            platformsByFormFactor.tablet.push(value.id);
          }
          if (platformCompatibleWithFormFactor(value.type, 'television')) {
            platformsByFormFactor.television.push(value.id);
          }
          if (platformCompatibleWithFormFactor(value.type, 'desktop')) {
            platformsByFormFactor.desktop.push(value.id);
          }
        });

        if (firstFactor.form_factor === 'desktop') {
          state.previewDevice = 'desktop_1280x1024';
          state.safeAreaTop = 0;
          state.hasNotch = false;
          if (platformsByFormFactor['desktop'].length > 0) {
            state.platformId = platformsByFormFactor['desktop'][0];
          }
        } else if (firstFactor.form_factor === 'television') {
          state.previewDevice = 'tv_1080p';
          state.safeAreaTop = 0;
          state.hasNotch = false;
          if (platformsByFormFactor['television'].length > 0) {
            state.platformId = platformsByFormFactor['television'][0];
          }
        } else if (firstFactor.form_factor === 'tablet') {
          state.hasNotch = false;
          state.safeAreaTop = 0;
          if (platformsByFormFactor['tablet'].length > 0) {
            state.platformId = platformsByFormFactor['tablet'][0];
          }
        } else {
          state.safeAreaTop = PreviewDeviceMeta['iphone121314'].safeArea;
          state.hasNotch =
            PreviewDeviceMeta['iphone121314'].hasDynamicIsland ||
            PreviewDeviceMeta['iphone121314'].hasNotch;
          if (platformsByFormFactor['phone'].length > 0) {
            state.platformId = platformsByFormFactor['phone'][0];
          }
        }
      }

      state.launch = {
        productGroups: productGroups.map((group) => group.ref),
        customAttributes: {},
        customObject: {},
      };

      state.minSDKVersion = parseToSemver(
        paywall.template.min_sdk_version || '3.0.9'
      );

      //Parse colors used in template
      const paywallColors = pullOutAllColorsFromPaywall(
        state.paywall.template.variables || {}
      );
      state.recentColors = paywallColors;

      state.hasSelectedFlag = paywall.template['ui.hasSelectedFlag'] || false;

      if (
        !Array.isArray(paywall.template['ui.v2Ready']) &&
        paywall.template['ui.v2Ready']
      ) {
        const newMeta = constructIdMetadata(paywall.template.pages);
        state.idLocations = newMeta.idLocations;
        state.idTypeLocations = newMeta.idLocationsByType;
        state.minSDKVersion = parseToSemver(
          paywall.template.min_sdk_version || '3.2.1'
        );

        if (state.paywall.template['ui.customObjectSchema']) {
          state.customObjectSchema = returnSchemaProperties(
            state.paywall.template['ui.customObjectSchema'] || {},
            'customObject',
            []
          );
        }

        if (state.paywall.template['initialState'].openHeaderIds) {
          state.openHeaderIds = new Set(
            state.paywall.template['initialState'].openHeaderIds
          );
        }
      }
    },
    setDarkMode: (state, { payload: flag }: PayloadAction<boolean>) => {
      state.inDarkMode = flag;
    },
    setProductErrorState: (
      state,
      { payload: flag }: PayloadAction<TProductErrorState>
    ) => {
      state.anySkuInvalid = flag === 'invalid';
      state.anySkuUnavailable = flag === 'unavailable';
    },
    setFormFactor: (state, { payload: factor }: PayloadAction<TDevice>) => {
      state.formFactor = factor;
    },
    setOrientation: (
      state,
      { payload: flag }: PayloadAction<TDeviceOrientation>
    ) => {
      state.orientation = flag;
    },
    setLaunchProductGroups: (
      state,
      { payload: groupIds }: PayloadAction<string[]>
    ) => {
      state.launch.productGroups = groupIds;
    },
    setLaunchCustomContext: (
      state,
      { payload: customAttributes }: PayloadAction<Object>
    ) => {
      state.launch.customAttributes = customAttributes;
    },
    setLaunchCustomObject: (
      state,
      { payload: customObject }: PayloadAction<Object>
    ) => {
      state.launch.customObject = customObject;
    },
    resetLaunchCustomContext: (state) => {
      state.launch.customAttributes = {};
    },
    resetLaunchCustomObject: (state) => {
      state.launch.customObject = {};
    },
    setCustomerAttributes: (
      state,
      { payload: attributes }: PayloadAction<Object>
    ) => {
      state.customer = attributes;
    },
    resetCustomerAttributes: (state) => {
      state.customer = {};
    },
    updateCustomerAttributeOptions(
      state,
      action: PayloadAction<{
        tokens: {};
      }>
    ) {
      const { tokens } = action.payload;
      state.hasChanges = true;
      const newAttributes = {
        ...state.paywall!.template['ui.personalizationTokens'],
        ...tokens,
      };
      state.paywall!.template['ui.personalizationTokens'] = newAttributes;
    },
    setPreviewDevice: (
      state,
      { payload: device }: PayloadAction<TPreviewDevice>
    ) => {
      state.previewDevice = device;
      const currentDeviceMeta = PreviewDeviceMeta[device];
      state.hasNotch =
        currentDeviceMeta.hasDynamicIsland || currentDeviceMeta.hasNotch;
      state.safeAreaTop = currentDeviceMeta.safeArea;
    },
    setPage: (state, { payload: pageName }: PayloadAction<string>) => {
      state.currentPage = pageName;
      state.editingComponentId = null;
    },
    setFocusedState: (state, { payload: focused }: PayloadAction<boolean>) => {
      state.focusedState = focused;
    },
    setFlowState: (state, { payload: flow }: PayloadAction<boolean>) => {
      state.flowState = flow;
    },
    setIsLoggedIn: (state, { payload: loggedIn }: PayloadAction<boolean>) => {
      state.isLoggedIn = loggedIn;
    },
    setVideoPlaying: (state, { payload: playing }: PayloadAction<boolean>) => {
      state.videoPlaying = playing;
    },
    setVideoMuted: (state, { payload: muted }: PayloadAction<boolean>) => {
      state.videoMuted = muted;
    },
    setAnySkuHasTrialOffer: (
      state,
      { payload: eligibility }: PayloadAction<boolean>
    ) => {
      state.anySkuHasTrialOffer = eligibility;
    },
    setAnySkuHasIntroOffer: (
      state,
      { payload: eligibility }: PayloadAction<boolean>
    ) => {
      state.anySkuHasIntroOffer = eligibility;
    },
    setAnySkuHasPromoOffer: (
      state,
      { payload: eligibility }: PayloadAction<boolean>
    ) => {
      state.anySkuHasPromoOffer = eligibility;
    },
    setExpandedTreeKeys(state, { payload: keys }: PayloadAction<string[]>) {
      state.expandedTreeKeys = keys;
    },
    addToExpandedTreeKeys(state, { payload: key }: PayloadAction<string>) {
      const currentKeyLocation = state.idLocations[key];
      const newIds = Object.keys(state.idLocations).reduce((output, id) => {
        if (
          id !== key &&
          currentKeyLocation.startsWith(state.idLocations[id])
        ) {
          output = [...output, id];
        }
        return output;
      }, [] as string[]);

      const currentKeys = state.expandedTreeKeys;
      state.expandedTreeKeys = [...currentKeys, key, ...newIds];
    },
    removeFromExpandedTreeKeys(state, { payload: id }: PayloadAction<string>) {
      const expandedKeys = state.expandedTreeKeys;
      const newExpandedKeys = expandedKeys.reduce((output, key) => {
        if (key === id) return output;
        return [...output, key];
      }, [] as string[]);
      state.expandedTreeKeys = newExpandedKeys;
    },
    setIdLocations(state, { payload: ids }: PayloadAction<TIdLocations>) {
      state.idLocations = ids;
    },
    setIdLocationTypes(
      state,
      { payload: types }: PayloadAction<TIdTypeLocation>
    ) {
      state.idTypeLocations = types;
    },
    setSelectedTreeKey(state, { payload: key }: PayloadAction<string | null>) {
      state.selectedTreeKey = key;
      //Set collapse open while editing if necessary
      let parentCollapseId;
      if (
        key &&
        state.idLocations.hasOwnProperty(key) &&
        state.idTypeLocations.hasOwnProperty('collapse')
      ) {
        state.idTypeLocations['collapse'].forEach((loc) => {
          if (
            state.idLocations[key] !== loc &&
            state.idLocations[key].startsWith(loc)
          ) {
            const idOfParentCollapse = getIdFromLocation(
              state.idLocations,
              loc
            );
            if (idOfParentCollapse) parentCollapseId = idOfParentCollapse;
          }
        });

        if (parentCollapseId) {
          state.openHeaderIds.add(parentCollapseId);
        } else {
          //Reset collapse
          state.openHeaderIds = new Set(
            state.paywall?.template.initialState.openHeaderIds || []
          );
        }
      }
    },
    setEditingMenuItem(
      state,
      { payload: menuItem }: PayloadAction<string | null>
    ) {
      state.editingMenuItem = menuItem;
    },
    setEditingProductMenuTabItem(
      state,
      { payload: menuTab }: PayloadAction<string | null>
    ) {
      state.editingProductMenuTabItem = menuTab;
    },
    setEditingComponentEditorTabItem(
      state,
      { payload: menuTab }: PayloadAction<string | null>
    ) {
      state.editingComponentEditorTabItem = menuTab;
    },
    setEditingProductId(
      state,
      { payload: itemId }: PayloadAction<string | null>
    ) {
      state.editingProductId = itemId;
    },
    setEditingSlideId(
      state,
      { payload: slideId }: PayloadAction<string | null>
    ) {
      const templateState = state.paywall!.template?.initialState;
      const productGroupCarousel =
        state.paywall!.template?.['ui.carousels']?.['carouselName']
          .productGroupSlides || false;
      if (!(state.groupId && templateState?.slides)) return;
      const currentSlides = productGroupCarousel
        ? templateState.slides[state.productGroups[0].id]
        : templateState.slides[state.groupId];

      state.editingSlideId = slideId;

      if (!slideId) {
        return;
      }
      const [carouselName] =
        Object.entries(currentSlides).find(
          ([, slides]) => !!slides.find((slide) => slide.id === slideId)
        ) || [];
      if (!carouselName) return;
      state.slides[carouselName] = slideId;
    },
    setViewingSlideId(
      state,
      { payload: slideId }: PayloadAction<string | null>
    ) {
      const templateState = state.paywall!.template?.initialState;
      if (!(state.groupId && templateState?.slides)) return;
      const currentSlides = templateState.slides[state.productGroups[0].id];

      state.viewingSlideId = slideId;

      if (!slideId) {
        return;
      }
      const [carouselName] =
        Object.entries(currentSlides).find(
          ([, slides]) => !!slides.find((slide) => slide.id === slideId)
        ) || [];
      if (!carouselName) return;
      state.slides[carouselName] = slideId;
    },
    setProductGroupId(state, { payload: groupId }: PayloadAction<string>) {
      state.groupId = groupId;
    },
    setDefaultProductGroup(state, { payload: groupId }: PayloadAction<string>) {
      state.productGroups = state.productGroups.map((group) => ({
        ...group,
        default: groupId === group.id,
      }));
    },
    setEditingProductGroups(
      state,
      { payload: editing }: PayloadAction<boolean>
    ) {
      state.editingProductGroups = editing;
    },
    setPaywall(state, { payload: paywall }: PayloadAction<PaywallType>) {
      state.paywall = paywall;
      // TODO: Kill this once we can confirm "ui.formGroups" exists in all paywall templates
      if (!paywall.template['ui.formGroups']) {
        state.fieldGroups = buildFieldGroups(paywall.template);
      }
    },
    setTemplate(state, action: PayloadAction<TPaywallTemplate>) {
      state.hasChanges = true;
      state.paywall!.template = action.payload;
    },
    setTemplateVariable(
      state,
      { payload: { key, value } }: PayloadAction<{ key: string; value: any }>
    ) {
      const [domain, ...path] = key.split('.');

      const changesWithMinSDKVersions =
        getChangeKeysForVariableValueChange(value);
      const newMinSDK = getNewMinSDKVersionFromChange(
        changesWithMinSDKVersions,
        state.formFactor,
        state.minSDKVersion
      );
      if (newMinSDK !== state.minSDKVersion) {
        state.pendingSDKVersion = newMinSDK;
        state.pendingSDKChanges = changesWithMinSDKVersions;
      }

      if (domain === 'var') {
        if (
          (state.paywall!.template.variables || {})[path[path.length - 1]] !==
          value
        ) {
          state.hasChanges = true;
          setValue(state.paywall!.template.variables, path, value);
          if (
            path[0].toLowerCase().endsWith('color') &&
            !value.toLowerCase().includes('gradient')
          ) {
            state.recentColors.add(value);
          }
        }
      } else if (domain === 'media') {
        state.hasChanges = true;
        setValue(state.mediaList, [...path, 'content'], value);
      } else if (domain === 'state') {
        state.hasChanges = true;
        setValue(state.paywall!.template.initialState, path, value);
      } else {
        state.hasChanges = true;
        Sentry.captureMessage(
          `Unknown variable domain found: "${domain}" ` +
            `(Paywall ID: ${state.paywall!.id}).`
        );
      }
    },
    setComponentMedia(
      state,
      action: PayloadAction<{
        location?: string;
        mediaValue: string;
        property: string;
      }>
    ) {
      const { location, mediaValue, property } = action.payload;
      if (!location) return;
      const componentLocation = parseLocationString(location);
      const component = findComponentFromLocation(
        state.paywall!.template.pages,
        componentLocation
      );

      if (component && mediaValue) {
        state.hasChanges = true;
        (component as any)[property] = mediaValue;
      }
    },
    setComponentMediaInCondition(
      state,
      action: PayloadAction<{
        location?: string;
        mediaValue: string;
        property: string;
        assertionIndex: number;
      }>
    ) {
      const { location, mediaValue, property, assertionIndex } = action.payload;
      if (!location) return;
      const componentLocation = parseLocationString(location);
      const component = findComponentFromLocation(
        state.paywall!.template.pages,
        componentLocation
      );

      if (component && mediaValue) {
        state.hasChanges = true;
        const newConditions = (component.conditionAttributes || []).reduce(
          (output, group, index) => {
            if (index === assertionIndex) {
              return [
                ...output,
                {
                  ...group,
                  attributes: {
                    ...group.attributes,
                    [property]: mediaValue,
                  },
                },
              ];
            }
            return [...output, group];
          },
          [] as Array<
            Omit<TConditionalComponent, 'components'> & {
              attributes: Partial<TBaseComponent>;
            }
          >
        );
        component.conditionAttributes = newConditions;
      }
    },
    setPlatforms(state, { payload: platforms }: PayloadAction<PlatformType[]>) {
      state.platforms = platforms;
      if (platforms.length === 0) return;
      state.platformId = platforms[0].id;
    },
    setPlatformId(state, { payload: platformId }: PayloadAction<string>) {
      state.platformId = platformId;
      state.editingProductId = null;
    },
    acknowledgePendingMinSDKVersion(state) {
      if (state.pendingSDKVersion) {
        state.minSDKVersion = state.pendingSDKVersion;
        state.paywall!.template.min_sdk_version =
          state.pendingSDKVersion.semver;
      }
      state.pendingSDKVersion = null;
      state.pendingSDKChanges = [];
    },
    resetChangeState(state) {
      state.hasChanges = false;
    },
    resetMenuChangeState(state) {
      state.menusWithChanges = new Set();
    },
    updateProductItems(state, action: PayloadAction<SelectableItemType[]>) {
      const items = action.payload;
      state.hasChanges = true;

      const selectedItem = items.find((item) => item.selected);
      state.skuItems[state.groupId!][state.platformId!] = items.reduce(
        (output, item, i) => {
          const selected = selectedItem ? selectedItem.id === item.id : i === 0;
          return { ...output, [item.id]: { ...item, selected } };
        },
        {} as { [itemId: string]: SelectableItemType }
      );

      const currentMenu = state.menus[state.groupId!][state.platformId!];
      state.menusWithChanges.add(currentMenu.id);
    },
    toggleFeaturedFlag(state, { payload: itemId }: PayloadAction<string>) {
      state.hasChanges = true;
      const currentMenu = state.menus[state.groupId!][state.platformId!];
      state.menusWithChanges.add(currentMenu.id);
      const item = state.skuItems[state.groupId!][state.platformId!][itemId];
      item.featured = !item.featured;
    },
    setSelectedItem(state, { payload: itemId }: PayloadAction<string>) {
      state.hasChanges = true;
      const currentMenu = state.menus[state.groupId!][state.platformId!];
      state.menusWithChanges.add(currentMenu.id);
      const items = state.skuItems[state.groupId!][state.platformId!];
      Object.values(items).forEach((item) => {
        item.selected = item.id === itemId;
      });
    },
    removeFromAvailableItems(
      state,
      { payload: itemToRemove }: PayloadAction<SelectableItemType>
    ) {
      const filteredAvailableItems = state.availableItems[state.platformId!][
        state.groupId!
      ].filter((item) => {
        return item.id !== itemToRemove.id;
      });
      state.availableItems[state.platformId!][state.groupId!] =
        filteredAvailableItems;
    },
    updateAvailableItems(
      state,
      { payload: items }: PayloadAction<SelectableItemType[]>
    ) {
      state.availableItems[state.platformId!][state.groupId!] = items;
    },
    setMediaList(state, action: PayloadAction<TPaywallMedia[]>) {
      state.mediaList = action.payload.reduce(
        (output, media) => ({ ...output, [media.name]: media }),
        {}
      );
    },
    updateMedia(state, { payload: media }: PayloadAction<TPaywallMedia>) {
      state.mediaList[media.name] = media;
    },
    updateProductVariables(
      state,
      action: PayloadAction<{ itemId: string; data: { [key: string]: string } }>
    ) {
      const { itemId, data } = action.payload;
      state.hasChanges = true;
      const currentMenu = state.menus[state.groupId!][state.platformId!];
      state.menusWithChanges.add(currentMenu.id);
      const item = state.skuItems[state.groupId!][state.platformId!][itemId];
      item.variables = data;
    },
    pushToAllProductVariablesInGroups(
      state,
      action: PayloadAction<{ originId: string; groups: string[] }>
    ) {
      const { originId, groups } = action.payload;
      state.hasChanges = true;
      const originItem =
        state.skuItems[state.groupId!][state.platformId!][originId];
      const itemVariables = originItem.variables;
      for (const group in state.skuItems) {
        if (groups.includes(group)) {
          for (const platform in state.skuItems[group]) {
            for (const productId in state.skuItems[group][platform]) {
              const currentMenu = state.menus[group][platform];
              state.menusWithChanges.add(currentMenu.id);
              const item = state.skuItems[group][platform][productId];
              item.variables = itemVariables;
            }
          }
        }
      }
    },
    pushToAllProductVariablesInPlatforms(
      state,
      action: PayloadAction<{ originId: string; platforms: string[] }>
    ) {
      const { originId, platforms } = action.payload;
      state.hasChanges = true;
      const originItem =
        state.skuItems[state.groupId!][state.platformId!][originId];
      const itemVariables = originItem.variables;
      for (const group in state.skuItems) {
        for (const platform in state.skuItems[group]) {
          if (platforms.includes(platform)) {
            for (const productId in state.skuItems[group][platform]) {
              const currentMenu = state.menus[group][platform];
              state.menusWithChanges.add(currentMenu.id);
              const item = state.skuItems[group][platform][productId];
              item.variables = itemVariables;
            }
          }
        }
      }
    },
    updateSlide(
      state,
      action: PayloadAction<{
        slideId: string;
        carouselName: string;
        productGroupCarousel: boolean;
        attr: string;
        value: any;
      }>
    ) {
      const { slideId, carouselName, productGroupCarousel, attr, value } =
        action.payload;
      const templateState = state.paywall!.template.initialState!;
      if (!templateState.slides) return;
      state.hasChanges = true;
      const [domain, key] = attr.split('.');
      const slideArray = productGroupCarousel
        ? templateState.slides[state.productGroups[0].id][carouselName]
        : templateState.slides[state.groupId!][carouselName];
      const slide = slideArray.find((slide) => slide.id === slideId);
      if (!slide) return;
      if (domain === 'var') {
        slide[key] = value;
      } else if (domain === 'media') {
        const mediaName = `slide_${key}_${slideId}`;
        slide[key] = `\${media.${mediaName}}`;
        if (mediaName in state.mediaList) {
          state.mediaList[mediaName].content = value;
        } else {
          state.mediaList[mediaName] = {
            content: value,
            paywall: state.paywall!.id,
            name: mediaName,
          };
        }
      } else {
        Sentry.captureMessage(
          `Unknown variable domain found: "${domain}" ` +
            `(Paywall ID: ${state.paywall!.id}, Slide ID: ${slideId}, ` +
            `carousel: ${carouselName}).`
        );
      }
    },
    addSlide(state, { payload: carouselName }: PayloadAction<string | null>) {
      const template = state.paywall!.template;
      const templateState = template.initialState!;
      if (!(carouselName && templateState.slides)) return;
      state.hasChanges = true;
      const settings = template['ui.carousels']![carouselName];
      const slides = templateState.slides[state.groupId!][carouselName];
      const newSlideId = uuid4();
      slides.push({ ...settings.newSlide, id: newSlideId } as TCarouselSlide);
    },
    removeSlide(state) {
      const slideId = state.editingSlideId;
      const templateState = state.paywall!.template?.initialState;
      if (!(slideId && templateState?.slides && state.groupId)) return;
      const groupId = state.groupId;
      const currentSlides = templateState.slides[groupId];
      const [carouselName] =
        Object.entries(currentSlides).find(
          ([, slides]) => !!slides.find((slide) => slide.id === slideId)
        ) || [];
      if (!carouselName) return;
      const groupSlides = templateState.slides[groupId];

      groupSlides[carouselName] = groupSlides[carouselName].filter(
        (slide) => slide.id !== slideId
      );
      state.slides[carouselName] = null;
      state.hasChanges = true;
      state.editingSlideId = null;
    },
    editSlideFieldLabel(
      state,
      action: PayloadAction<{
        fieldVariable: string;
        newLabel: string;
      }>
    ) {
      const { fieldVariable, newLabel } = action.payload;

      if (state.paywall!.template['ui.carousels']) {
        const newCarouselFields = state.paywall!.template['ui.carousels'][
          'carouselName'
        ].fields.reduce((output, field) => {
          if (
            field.variable === `var.${fieldVariable}` ||
            field.variable === `media.${fieldVariable}`
          ) {
            return [
              ...output,
              {
                ...field,
                label: newLabel,
              } as TFieldSettings,
            ];
          }
          return [...output, field];
        }, [] as TFieldSettings[]);
        state.hasChanges = true;
        state.paywall!.template['ui.carousels']['carouselName'].fields =
          newCarouselFields;
      }
    },
    editSlideFieldDefault(
      state,
      action: PayloadAction<{
        fieldVariable: string;
        newValue: string;
      }>
    ) {
      const { fieldVariable, newValue } = action.payload;

      if (state.paywall!.template['ui.carousels']) {
        const newNewSlideState =
          state.paywall!.template['ui.carousels']['carouselName'].newSlide;
        state.hasChanges = true;
        newNewSlideState[fieldVariable] = newValue;
        state.paywall!.template['ui.carousels']['carouselName'].newSlide =
          newNewSlideState;
      }
    },
    removeCarouselField(state, { payload: variable }) {
      if (state.paywall!.template['ui.carousels']) {
        state.hasChanges = true;

        //Remove from fields
        const newCarouselFields = state.paywall!.template['ui.carousels'][
          'carouselName'
        ].fields.reduce((output, field) => {
          if (
            field.variable === `var.${variable}` ||
            field.variable === `media.${variable}`
          ) {
            return [...output];
          }
          return [...output, field];
        }, [] as TFieldSettings[]);
        state.paywall!.template['ui.carousels']['carouselName'].fields =
          newCarouselFields;

        //Remove from newSlide definition
        const newSlide = Object.entries(
          state.paywall!.template['ui.carousels']['carouselName'].newSlide
        ).reduce((output, [key, value]) => {
          if (key === variable) return output;
          return {
            ...output,
            [key]: value,
          };
        }, {} as Omit<TCarouselSlide, 'id'>);
        state.paywall!.template['ui.carousels']['carouselName'].newSlide =
          newSlide;
      }

      //Remove from all slide definitions
      if (state.paywall?.template.initialState.slides) {
        const updatedSlideState = Object.entries(
          state.paywall!.template.initialState.slides
        ).reduce((output, [slideKey, slideSettings]) => {
          const updatedSlides = slideSettings['carouselName'].reduce(
            (output, slide) => {
              delete slide[variable];
              return [...output, slide];
            },
            [] as TCarouselSlide[]
          );

          return {
            ...output,
            [slideKey]: {
              ...slideSettings,
              carouselName: updatedSlides,
            },
          };
        }, {} as TCarouselSlidesState);
        state.paywall!.template.initialState.slides = updatedSlideState;
      }
    },
    addCarouselField(
      state,
      action: PayloadAction<{
        name: string;
        value: any;
        type: TCarouselFieldType;
      }>
    ) {
      const { name, value, type } = action.payload;
      const newName = toCamelCase(name);
      state.hasChanges = true;

      const resultFormField: TFieldSettings = {
        type: type,
        label: name,
        variable: type === 'image' ? `media.${newName}` : `var.${newName}`,
        showVariableCloud: type === 'image' ? true : undefined,
      };
      const slideVariableMap = {
        [newName]: type === 'image' ? `\${media.default}` : value,
      };

      if (state.paywall!.template['ui.carousels']) {
        //Add to fields
        const newCarouselFields = [
          ...state.paywall!.template['ui.carousels']['carouselName'].fields,
          resultFormField,
        ];
        state.paywall!.template['ui.carousels']['carouselName'].fields =
          newCarouselFields;

        //Add to newSlide settings
        const carouselNewSlideSettings = {
          ...state.paywall!.template['ui.carousels']['carouselName'].newSlide,
          ...slideVariableMap,
        };
        state.paywall!.template['ui.carousels']['carouselName'].newSlide =
          carouselNewSlideSettings;
      } else {
        console.warn('Carousel settings not found');
      }

      //Add new slide field to all existing slides in initialState
      if (state.paywall!.template.initialState.slides) {
        const updatedSlideState = Object.entries(
          state.paywall!.template.initialState.slides
        ).reduce((output, [slideKey, slideSettings]) => {
          const updatedSlides = slideSettings['carouselName'].reduce(
            (output, slide) => {
              return [
                ...output,
                {
                  ...slide,
                  ...slideVariableMap,
                },
              ];
            },
            [] as TCarouselSlide[]
          );

          return {
            ...output,
            [slideKey]: {
              ...slideSettings,
              carouselName: updatedSlides,
            },
          };
        }, {} as TCarouselSlidesState);
        state.paywall!.template.initialState.slides = updatedSlideState;
      } else {
        console.warn('Carousel state not found');
      }
    },
    setFieldGroupName(
      state,
      { payload: groupName }: PayloadAction<string | null>
    ) {
      state.fieldGroupName = groupName;
    },
    setEditingComponentId(
      state,
      { payload: groupId }: PayloadAction<string | null>
    ) {
      state.editingComponentId = groupId;
    },
    addFeaturedCapability(state) {
      if (!state.idLocations || !state.paywall || !state.paywall.template)
        return;
      state.hasChanges = true;

      const productContainerChildren = findProductContainerChildLocations(
        state.idLocations,
        state.idTypeLocations
      );

      //Add featured condition attributes to all product elements
      productContainerChildren.forEach((child) => {
        const parsedProductContainerChildLocation = parseLocationString(child);
        const productContainerChild = findComponentFromLocation(
          state.paywall!.template?.pages,
          parsedProductContainerChildLocation
        );
        const result = addFeaturedToComponent(productContainerChild);

        const variableMap: Record<string, string> = Object.keys(
          result.variables
        ).reduce((output, key) => {
          const currentValue = result.variables[key];
          return {
            ...output,
            [key]: currentValue.value,
          };
        }, {});
        const newVars = {
          ...variableMap,
          ...state.paywall!.template.variables,
        };
        state.paywall!.template.variables = newVars;
      });

      //Add to template capabilities array
      state.paywall!.template['ui.capabilities'] = addToCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'featured_product'
      );
      state.paywall!.template['ui.hasFeaturedFlag'] = true;

      //Mark 1 product in current menu as featured
      const currentSkuItems = state.skuItems[state.groupId!][state.platformId!];
      if (Object.keys(currentSkuItems).length > 0) {
        state.skuItems[state.groupId!][state.platformId!][
          Object.keys(currentSkuItems)[0]
        ].featured = true;

        const currentMenu = state.menus[state.groupId!][state.platformId!];
        state.menusWithChanges.add(currentMenu.id);
      }
    },
    removeFeaturedCapability(state) {
      if (!state.idLocations || !state.paywall || !state.paywall.template)
        return;
      state.hasChanges = true;

      removeCapability(
        state.idLocations,
        state.idTypeLocations,
        state.paywall.template!.pages,
        ['${sku.featured}'],
        false
      );

      //Remove from template capabilities array
      state.paywall!.template['ui.capabilities'] = removeFromCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'featured_product'
      );
      state.paywall!.template['ui.hasFeaturedFlag'] = false;
    },
    addSelectedCapability(state) {
      if (!state.idLocations || !state.paywall || !state.paywall.template)
        return;
      state.hasChanges = true;

      const hasPromoEligibility = (
        state.paywall!.template['ui.capabilities'] || []
      ).includes('conditional_product_offer');

      const productContainerChildren = findProductContainerChildLocations(
        state.idLocations,
        state.idTypeLocations
      );
      if (!productContainerChildren.length) return;

      //Add selected conditionAttributes to all product elements
      productContainerChildren.forEach((child) => {
        const parsedProductContainerChildLocation = parseLocationString(child);
        const productContainerChild = findComponentFromLocation(
          state.paywall!.template?.pages,
          parsedProductContainerChildLocation
        );
        const result = addSelectedToComponent(
          productContainerChild,
          hasPromoEligibility
        );
        const variableMap: Record<string, string> = Object.keys(
          result.variables
        ).reduce((output, key) => {
          const currentValue = result.variables[key];
          return {
            ...output,
            [key]: currentValue.value,
          };
        }, {});
        const newVars = {
          ...variableMap,
          ...state.paywall!.template.variables,
        };
        state.paywall!.template.variables = newVars;
      });

      //Add purchase button
      const purchaseButtonResult: TNewComponentBuilderMeta =
        generateFullComponent(
          'purchaseButton',
          'purchaseButton',
          state.formFactor,
          state.idLocations
        );

      const purchaseButtonComponent = purchaseButtonResult.component;
      if (hasPromoEligibility) {
        (purchaseButtonComponent as TButtonContainer).onTap = {
          function: 'namiPurchaseSelectedSKU',
          parameters: {
            promo: '${sku.promoKey}',
          },
        };
      }

      const variableMap: Record<string, string> = Object.keys(
        purchaseButtonResult.variables
      ).reduce((output, key) => {
        const currentValue = purchaseButtonResult.variables[key];
        return {
          ...output,
          [key]: currentValue.value,
        };
      }, {});
      const newVars = { ...variableMap, ...state.paywall!.template.variables };
      state.paywall!.template.variables = newVars;
      const productContainerLoc = parseLocationString(
        state.idLocations['productContainer']
      );
      const productContainerIndexInsideParent =
        productContainerLoc.pos[productContainerLoc.pos.length - 1];
      const productContainerParent = findComponentFromLocation(
        state.paywall!.template?.pages,
        moveUpTreeFromLocation(productContainerLoc)
      );

      if (typeof productContainerIndexInsideParent === 'number') {
        (productContainerParent as TContainer).components.splice(
          productContainerIndexInsideParent + 1,
          0,
          purchaseButtonComponent
        );
      }

      //Add to template capabilities array
      state.paywall!.template['ui.capabilities'] = addToCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'selected_product'
      );
      state.hasSelectedFlag = true;
      state.paywall!.template['ui.hasSelectedFlag'] = true;
    },
    removeSelectedCapability(state) {
      if (!state.idLocations || !state.paywall || !state.paywall.template)
        return;
      state.hasChanges = true;

      const hasPromoEligibility = (
        state.paywall!.template['ui.capabilities'] || []
      ).includes('conditional_product_offer');

      removeCapability(
        state.idLocations,
        state.idTypeLocations,
        state.paywall!.template.pages,
        ['${state.selectedProducts.${state.currentGroupId}}'],
        false
      );

      if (state.idLocations['productButton']) {
        //Change productButton onTap
        const parsedProductButtonLoc = parseLocationString(
          state.idLocations['productButton']
        );
        const productButtonComponent = findComponentFromLocation(
          state.paywall!.template?.pages,
          parsedProductButtonLoc
        );

        (productButtonComponent as TButtonContainer).onTap = {
          function: 'namiBuySKU',
        };
        if (hasPromoEligibility) {
          const newPromoKeyCondition: Omit<
            TConditionalComponent,
            'components'
          > & {
            attributes: Partial<
              TBaseComponent | TTextComponent | TButtonContainer
            >;
          } = {
            attributes: {
              onTap: {
                function: 'namiBuySKU',
                parameters: {
                  promo: '${sku.promoKey}',
                },
              },
            },
            component: 'condition',
            assertions: [
              {
                value: '${sku.promoEligible}',
                expected: true,
                operator: 'equals',
              },
            ],
          };

          const newButtonConditions = [
            newPromoKeyCondition,
            ...(productButtonComponent.conditionAttributes || []),
          ];
          productButtonComponent.conditionAttributes = newButtonConditions;
        }
      }

      //Remove purchase button
      const purchaseButtonLocation = state.idLocations['purchaseButton'];
      findComponentFromLocation(
        state.paywall!.template.pages,
        parseLocationString(purchaseButtonLocation),
        true
      );

      //Remove from template capabilities array
      state.paywall!.template['ui.capabilities'] = removeFromCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'selected_product'
      );
      state.hasSelectedFlag = false;
      state.paywall!.template['ui.hasSelectedFlag'] = false;
    },
    addCarousel(
      state,
      action: PayloadAction<{
        parentId?: string;
        location?: string;
      }>
    ) {
      const { parentId, location } = action.payload;
      if (!state.idLocations || !state.paywall || !state.paywall.template)
        return;
      state.hasChanges = true;

      const putIntoHeaderStack = location && location.includes('header');

      //Add carouselComponent
      const carouselResult: TNewComponentBuilderMeta = generateFullComponent(
        'carousel',
        'carousel',
        state.formFactor,
        state.idLocations
      );

      const variableMap: Record<string, string> = Object.keys(
        carouselResult.variables
      ).reduce((output, key) => {
        const currentValue = carouselResult.variables[key];
        return {
          ...output,
          [key]: currentValue.value,
        };
      }, {});

      const newVars = {
        ...variableMap,
        ...state.paywall!.template.variables,
      };
      state.paywall!.template.variables = newVars;

      if (putIntoHeaderStack) {
        //Add a stack
        const stackResult = generateFullComponent(
          'stack',
          'stack',
          state.formFactor,
          state.idLocations
        );
        stackResult.component.id = parentId || 'header';

        const stackVariableMap: Record<string, string> = Object.keys(
          stackResult?.variables || {}
        ).reduce((output, key) => {
          const currentValue = stackResult.variables[key];
          return {
            ...output,
            [key]: currentValue.value,
          };
        }, {});

        const newVars = {
          ...stackVariableMap,
          ...state.paywall!.template.variables,
        };
        state.paywall!.template.variables = newVars;

        (stackResult.component as TContainer).components = [
          ...((stackResult.component as TContainer).components || []),
          carouselResult.component,
        ];
        stackResult.component.title = 'Header Stack';
        const locationParsed = parseLocationString(location);

        const contentParent = findComponentFromLocation(
          state.paywall!.template?.pages,
          locationParsed
        );
        contentParent.id = 'headerWrapper';
        contentParent.title = 'Header Wrapper';
        (contentParent as TContainer).position = 'center-top';
        (stackResult.component as TContainer).components.push(contentParent);
        state.paywall!.template.pages[locationParsed.page].header = [
          stackResult.component as TContainer,
        ];
      } else {
        const contentParent = findComponentFromLocation(
          state.paywall!.template?.pages,
          parseLocationString(location || state.idLocations['contentContainer'])
        );
        (contentParent as TContainer).components.unshift(
          carouselResult.component
        );
      }

      const templateState = state.paywall!.template.initialState!;

      let newSlide: Omit<TCarouselSlide, 'id'> = {
        ...Object.entries(carouselResult.slideVariables).reduce(
          (output, [key, meta]) => {
            return {
              ...output,
              [key]: meta.value,
            };
          },
          {}
        ),
      };

      //Add ui.carousels settings
      const carouselSettings: TCarouselSettings = {
        carouselName: {
          maxSlides: 25,
          productGroupSlides: false,
          slides: [],
          fields: carouselResult.slideFormFields,
          newSlide: newSlide,
        },
      };
      state.paywall!.template['ui.carousels'] = carouselSettings;

      if (templateState.slides) {
        //TODO - Handle when multiple carousels are allowed
        console.warn(`Carousel state is already set, cannot add new carousel`);
      } else {
        const newSlides = {
          ...carouselSettings['carouselName'].newSlide,
          title: carouselSettings['carouselName'].newSlide.title,
          carouselSlideFillImage: `\${media.default}`,
          id: uuid4(),
        } as TCarouselSlide;
        const newSlideState: {
          [groupId: string]: { [carouselName: string]: TCarouselSlide[] };
        } = {
          [state.groupId!]: {
            carouselName: [newSlides],
          },
        };
        templateState.slides = newSlideState;
      }

      //Add to template capabilities array
      state.paywall!.template['ui.capabilities'] = addToCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'carousel'
      );
    },
    removeCarousel(state) {
      if (!state.idLocations || !state.paywall || !state.paywall.template)
        return;
      state.hasChanges = true;

      const carouselLocation = state.idLocations['carousel'];

      if (!carouselLocation) {
        console.warn(`No carousel component can be found`);
      } else {
        //Remove carousel component
        findComponentFromLocation(
          state.paywall!.template.pages,
          parseLocationString(carouselLocation),
          true
        );
      }

      //Remove ui.carousels
      state.paywall!.template['ui.carousels'] = null;

      //Update initial state
      const templateState = state.paywall!.template.initialState!;
      delete templateState.slides;

      //Remove from template capabilities array
      state.paywall!.template['ui.capabilities'] = removeFromCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'carousel'
      );
    },
    addTrialEligibility(state) {
      if (!state.idLocations || !state.paywall || !state.paywall.template)
        return;

      state.hasChanges = true;

      let allSkuVariables: { [key: string]: TVariableMeta } = {};

      const productContainerChildren = findProductContainerChildLocations(
        state.idLocations,
        state.idTypeLocations
      );

      const contentContainerChildren = findContentContainerFooterChildLocations(
        state.idLocations,
        false,
        false
      );

      const allChildren = [
        ...productContainerChildren,
        ...contentContainerChildren,
      ];

      const componentTypesWithEligibilityApplied: {
        [key: string]: Array<string>;
      } = {};

      //Add trial conditionAttributes to all product elements
      allChildren.forEach((child) => {
        const parsedChildLocation = parseLocationString(child);
        const childComponent = findComponentFromLocation(
          state.paywall!.template?.pages,
          parsedChildLocation
        );
        const currentSkuVariables =
          state.paywall!.template['ui.productSettings']?.variablesList || [];

        //Only add eligibility to component types from ComponentCapabilitySettings if this is the first compnonent of that type. I.e don't add promo eligibility to second title
        //Otherwise if component type is not in ComponentCapabilitySettings always add eligibility
        const canAdd =
          (ComponentCapabilitySettings[childComponent.namiComponentType!] &&
            !componentTypesWithEligibilityApplied[
              childComponent.namiComponentType!
            ]) ||
          !ComponentCapabilitySettings[childComponent.namiComponentType!];

        if (canAdd) {
          const result = addTrialEligibilityToComponent(
            childComponent,
            productContainerChildren.includes(child),
            currentSkuVariables
          );

          if (childComponent.namiComponentType) {
            componentTypesWithEligibilityApplied[
              childComponent.namiComponentType
            ] = [
              ...(componentTypesWithEligibilityApplied[
                childComponent.namiComponentType
              ] || []),
              childComponent.id!,
            ];
          }

          const variableMap: Record<string, string> = Object.keys(
            result.variables
          ).reduce((output, key) => {
            const currentValue = result.variables[key];
            return {
              ...output,
              [key]: currentValue.value,
            };
          }, {});
          const newVars = {
            ...variableMap,
            ...state.paywall!.template.variables,
          };
          state.paywall!.template.variables = newVars;

          if (Object.keys(result.skuVariables || {}).length > 0) {
            allSkuVariables = { ...allSkuVariables, ...result.skuVariables };
          }
        }
      });

      //Push new sku variables
      if (
        state.paywall!.template['ui.productSettings'] &&
        state.paywall!.template['ui.productSettings'].variablesList
      ) {
        const skuVariableMap: TProductVariable[] = Object.keys(
          allSkuVariables
        ).reduce((output, key) => {
          const currentValue = allSkuVariables[key];
          return [
            ...output,
            {
              variable: key,
              value: currentValue.value,
              type: 'string',
              display_name: currentValue.title,
              offerType: 'trial',
            } as TProductVariable,
          ] as TProductVariable[];
        }, [] as TProductVariable[]);
        const newSkuVars = [
          ...(state.paywall!.template['ui.productSettings'] || [])
            .variablesList,
          ...skuVariableMap,
        ];
        state.paywall!.template['ui.productSettings'].variablesList =
          newSkuVars;
      }

      //Push variables to product menu items
      for (const group in state.skuItems) {
        for (const platform in state.skuItems[group]) {
          for (const productId in state.skuItems[group][platform]) {
            const currentMenu = state.menus[group][platform];
            state.menusWithChanges.add(currentMenu.id);
            const item = state.skuItems[group][platform][productId];
            item.variables = {
              ...item.variables,
              ...Object.entries(allSkuVariables).reduce(
                (output, [key, meta]) => {
                  return {
                    ...output,
                    [key]: meta.value,
                  };
                },
                {}
              ),
            };
          }
        }
      }

      //Push variables to available items
      for (const platform in state.availableItems) {
        for (const group in state.availableItems[platform]) {
          for (const productIndex in state.availableItems[platform][group]) {
            const item = state.availableItems[platform][group][productIndex];
            item.variables = {
              ...item.variables,
              ...Object.entries(allSkuVariables).reduce(
                (output, [key, meta]) => {
                  return {
                    ...output,
                    [key]: meta.value,
                  };
                },
                {}
              ),
            };
          }
        }
      }

      //Add to template capabilities array
      state.paywall!.template['ui.capabilities'] = addToCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'conditional_product_trial'
      );
      state.anySkuHasTrialOffer = true;
    },
    removeTrialEligibility(state) {
      if (!state.idLocations || !state.paywall || !state.paywall.template)
        return;
      state.hasChanges = true;

      removeCapability(
        state.idLocations,
        state.idTypeLocations,
        state.paywall.template!.pages,
        ['${sku.freeTrialEligible}', '${state.anySkuHasTrialOffer}'],
        true
      );

      //Remove from sku variables
      if (state.paywall!.template['ui.productSettings']?.variablesList) {
        const newSkuVars = (
          state.paywall!.template['ui.productSettings'].variablesList || []
        ).reduce((output, variable) => {
          if (variable.offerType && variable.offerType === 'trial')
            return output;
          return [...output, variable];
        }, [] as TProductVariable[]);
        state.paywall!.template['ui.productSettings'].variablesList =
          newSkuVars;
      }

      //Remove from template capabilities array
      state.paywall!.template['ui.capabilities'] = removeFromCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'conditional_product_trial'
      );
      state.anySkuHasTrialOffer = false;
    },
    addDeeplink(
      state,
      action: PayloadAction<{
        location?: string;
      }>
    ) {
      const { location } = action.payload;
      if (!state.idLocations || !state.paywall || !state.paywall.template)
        return;
      const capabilities: TTemplateCapability[] =
        state.paywall!.template['ui.capabilities'] || [];
      state.hasChanges = true;

      //Add deeplink button
      const nextDeeplinkId = generateNextIdForType(
        'deeplinkButton',
        state.idLocations
      );
      let deeplinkButtonResult: TNewComponentBuilderMeta =
        generateFullComponent(
          nextDeeplinkId,
          'deeplinkButton',
          state.formFactor,
          state.idLocations
        );

      const variableMap: Record<string, string> = Object.keys(
        deeplinkButtonResult.variables
      ).reduce((output, key) => {
        const currentValue = deeplinkButtonResult.variables[key];
        return {
          ...output,
          [key]: currentValue.value,
        };
      }, {});
      let newVars = { ...variableMap, ...state.paywall!.template.variables };

      if (capabilities.includes('accessibility')) {
        const screenreaderResult = addScreenreaderTextToComponent(
          deeplinkButtonResult.component
        );
        deeplinkButtonResult.component = screenreaderResult.component;
        const screenreaderVariableMap: Record<string, string> = Object.keys(
          screenreaderResult.variables
        ).reduce((output, key) => {
          const currentValue = screenreaderResult.variables[key];
          return {
            ...output,
            [key]: currentValue.value,
          };
        }, {});
        newVars = {
          ...newVars,
          ...screenreaderVariableMap,
        };
      }

      state.paywall!.template.variables = newVars;

      const parent = location
        ? findComponentFromLocation(
            state.paywall!.template?.pages,
            parseLocationString(location)
          )
        : findComponentFromLocation(
            state.paywall!.template?.pages,
            parseLocationString(state.idLocations['contentContainer'])
          );
      (parent as TContainer).components.push(deeplinkButtonResult.component);

      state.paywall!.template['ui.capabilities'] = addToCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'deeplink'
      );
    },
    removeDeeplink(state) {
      if (!state.idLocations || !state.paywall || !state.paywall.template)
        return;
      state.hasChanges = true;
      if (state.editingComponentId === 'deeplinkButton')
        state.editingComponentId = null;

      //Remove deeplink buttons
      const deeplinkButtons = Object.keys(state.idLocations).reduce(
        (output, id) => {
          if (id.match(/deeplinkButton[0-9]?$/)) {
            return [...output, id];
          }
          return output;
        },
        [] as string[]
      );

      deeplinkButtons.forEach((button) => {
        const deeplinkButtonLocation = state.idLocations[button];
        findComponentFromLocation(
          state.paywall!.template.pages,
          parseLocationString(deeplinkButtonLocation),
          true
        );
        const newMeta = constructIdMetadata(state.paywall!.template.pages);
        state.idLocations = newMeta.idLocations;
        state.idTypeLocations = newMeta.idLocationsByType;
      });

      state.paywall!.template['ui.capabilities'] = removeFromCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'deeplink'
      );
    },
    addPromoEligibility(state) {
      if (!state.idLocations || !state.paywall || !state.paywall.template)
        return;
      state.hasChanges = true;

      const selectedStateActive = state.hasSelectedFlag;

      let allSkuVariables: { [key: string]: TVariableMeta } = {};

      const productContainerChildren = findProductContainerChildLocations(
        state.idLocations,
        state.idTypeLocations
      );

      const contentContainerChildren = findContentContainerFooterChildLocations(
        state.idLocations,
        false,
        false
      );

      const allChildren = [
        ...productContainerChildren,
        ...contentContainerChildren,
      ];

      const componentTypesWithEligibilityApplied: {
        [key: string]: Array<string>;
      } = {};

      //Add promo conditionAttributes to all elements
      allChildren.forEach((child) => {
        const parsedChildLocation = parseLocationString(child);
        const childComponent = findComponentFromLocation(
          state.paywall!.template?.pages,
          parsedChildLocation
        );
        const currentSkuVariables =
          state.paywall!.template['ui.productSettings']?.variablesList || [];

        //Only add eligibility to component types from ComponentCapabilitySettings if this is the first compnonent of that type. I.e don't add promo eligibility to second title
        //Otherwise if component type is not in ComponentCapabilitySettings always add eligibility
        const canAdd =
          (ComponentCapabilitySettings[childComponent.namiComponentType!] &&
            !componentTypesWithEligibilityApplied[
              childComponent.namiComponentType!
            ]) ||
          !ComponentCapabilitySettings[childComponent.namiComponentType!];
        if (canAdd) {
          //Add eligibility to this component
          const result = addPromoEligibilityToComponent(
            childComponent,
            productContainerChildren.includes(child),
            currentSkuVariables,
            selectedStateActive
          );
          if (childComponent.namiComponentType) {
            componentTypesWithEligibilityApplied[
              childComponent.namiComponentType
            ] = [
              ...(componentTypesWithEligibilityApplied[
                childComponent.namiComponentType
              ] || []),
              childComponent.id!,
            ];
          }

          const variableMap: Record<string, string> = Object.keys(
            result.variables
          ).reduce((output, key) => {
            const currentValue = result.variables[key];
            return {
              ...output,
              [key]: currentValue.value,
            };
          }, {});

          const newVars = {
            ...variableMap,
            ...state.paywall!.template.variables,
          };
          state.paywall!.template.variables = newVars;

          if (Object.keys(result.skuVariables || {}).length > 0) {
            allSkuVariables = { ...allSkuVariables, ...result.skuVariables };
          }
        }
      });

      if (
        state.paywall!.template['ui.productSettings'] &&
        state.paywall!.template['ui.productSettings'].variablesList
      ) {
        const skuVariableMap: TProductVariable[] = Object.keys(
          allSkuVariables
        ).reduce((output, key) => {
          const currentValue = allSkuVariables[key];
          return [
            ...output,
            {
              variable: key,
              value: currentValue.value,
              type:
                currentValue.propertyType === 'onTap' ? 'offerKey' : 'string',
              display_name: currentValue.title,
              offerType: 'promo',
            } as TProductVariable,
          ] as TProductVariable[];
        }, [] as TProductVariable[]);
        const newSkuVars = [
          ...(state.paywall!.template['ui.productSettings'] || [])
            .variablesList,
          ...skuVariableMap,
        ];
        state.paywall!.template['ui.productSettings'].variablesList =
          newSkuVars;
      }

      //Push variables to product menu items
      for (const group in state.skuItems) {
        for (const platform in state.skuItems[group]) {
          for (const productId in state.skuItems[group][platform]) {
            const currentMenu = state.menus[group][platform];
            state.menusWithChanges.add(currentMenu.id);
            const item = state.skuItems[group][platform][productId];
            item.variables = {
              ...item.variables,
              ...Object.entries(allSkuVariables).reduce(
                (output, [key, meta]) => {
                  return {
                    ...output,
                    [key]: meta.value,
                  };
                },
                {}
              ),
            };
          }
        }
      }

      //Push variables to available items
      for (const platform in state.availableItems) {
        for (const group in state.availableItems[platform]) {
          for (const productIndex in state.availableItems[platform][group]) {
            const item = state.availableItems[platform][group][productIndex];
            item.variables = {
              ...item.variables,
              ...Object.entries(allSkuVariables).reduce(
                (output, [key, meta]) => {
                  return {
                    ...output,
                    [key]: meta.value,
                  };
                },
                {}
              ),
            };
          }
        }
      }

      //Add to template capabilities array
      state.paywall!.template['ui.capabilities'] = addToCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'conditional_product_offer'
      );
      state.anySkuHasPromoOffer = true;
    },
    removePromoEligibility(state) {
      if (!state.idLocations || !state.paywall || !state.paywall.template)
        return;
      state.hasChanges = true;

      const selectedStateActive = state.hasSelectedFlag;

      removeCapability(
        state.idLocations,
        state.idTypeLocations,
        state.paywall!.template?.pages,
        [
          '${sku.promoEligible}',
          '${sku.introEligible}',
          '${state.anySkuHasPromoOffer}',
          '${state.anySkuHasIntroOffer}',
        ],
        true
      );

      if (selectedStateActive && state.idLocations['purchaseButton']) {
        //Change purchaseButton onTap
        const parsedPurchaseButtonLoc = parseLocationString(
          state.idLocations['purchaseButton']
        );
        const purchaseButtonComponent = findComponentFromLocation(
          state.paywall!.template?.pages,
          parsedPurchaseButtonLoc
        );
        (purchaseButtonComponent as TButtonContainer).onTap = {
          function: 'namiPurchaseSelectedSKU',
        };
      }

      //Remove from sku variables
      if (state.paywall!.template['ui.productSettings']?.variablesList) {
        const newSkuVars = (
          state.paywall!.template['ui.productSettings'].variablesList || []
        ).reduce((output, variable) => {
          if (
            variable.offerType &&
            (variable.offerType === 'promo' || variable.offerType === 'intro')
          )
            return output;
          return [...output, variable];
        }, [] as TProductVariable[]);
        state.paywall!.template['ui.productSettings'].variablesList =
          newSkuVars;
      }

      //Remove from template capabilities array
      state.paywall!.template['ui.capabilities'] = removeFromCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'conditional_product_offer'
      );
      state.anySkuHasPromoOffer = false;
    },
    addIntroEligibility(state) {
      if (!state.idLocations || !state.paywall || !state.paywall.template)
        return;
      state.hasChanges = true;

      let allSkuVariables: { [key: string]: TVariableMeta } = {};

      const productContainerChildren = findProductContainerChildLocations(
        state.idLocations,
        state.idTypeLocations
      );

      const contentContainerChildren = findContentContainerFooterChildLocations(
        state.idLocations,
        false,
        false
      );

      const allChildren = [
        ...productContainerChildren,
        ...contentContainerChildren,
      ];

      const componentTypesWithEligibilityApplied: {
        [key: string]: Array<string>;
      } = {};

      //Add intro conditionAttributes to all elements
      allChildren.forEach((child) => {
        const parsedChildLocation = parseLocationString(child);
        const childComponent = findComponentFromLocation(
          state.paywall!.template?.pages,
          parsedChildLocation
        );
        const currentSkuVariables =
          state.paywall!.template['ui.productSettings']?.variablesList || [];

        //Only add eligibility to component types from ComponentCapabilitySettings if this is the first compnonent of that type. I.e don't add promo eligibility to second title
        //Otherwise if component type is not in ComponentCapabilitySettings always add eligibility
        const canAdd =
          (ComponentCapabilitySettings[childComponent.namiComponentType!] &&
            !componentTypesWithEligibilityApplied[
              childComponent.namiComponentType!
            ]) ||
          !ComponentCapabilitySettings[childComponent.namiComponentType!];

        if (canAdd) {
          const result = addIntroEligibilityToComponent(
            childComponent,
            productContainerChildren.includes(child),
            currentSkuVariables
          );

          if (childComponent.namiComponentType) {
            componentTypesWithEligibilityApplied[
              childComponent.namiComponentType
            ] = [
              ...(componentTypesWithEligibilityApplied[
                childComponent.namiComponentType
              ] || []),
              childComponent.id!,
            ];
          }

          const variableMap: Record<string, string> = Object.keys(
            result.variables
          ).reduce((output, key) => {
            const currentValue = result.variables[key];
            return {
              ...output,
              [key]: currentValue.value,
            };
          }, {});
          const newVars = {
            ...variableMap,
            ...state.paywall!.template.variables,
          };
          state.paywall!.template.variables = newVars;

          if (Object.keys(result.skuVariables || {}).length > 0) {
            allSkuVariables = { ...allSkuVariables, ...result.skuVariables };
          }
        }
      });

      if (
        state.paywall!.template['ui.productSettings'] &&
        state.paywall!.template['ui.productSettings'].variablesList
      ) {
        const skuVariableMap: TProductVariable[] = Object.keys(
          allSkuVariables
        ).reduce((output, key) => {
          const currentValue = allSkuVariables[key];
          return [
            ...output,
            {
              variable: key,
              value: currentValue.value,
              type:
                currentValue.propertyType === 'onTap' ? 'offerKey' : 'string',
              display_name: currentValue.title,
              offerType: 'intro',
            } as TProductVariable,
          ] as TProductVariable[];
        }, [] as TProductVariable[]);
        const newSkuVars = [
          ...(state.paywall!.template['ui.productSettings'] || [])
            .variablesList,
          ...skuVariableMap,
        ];
        state.paywall!.template['ui.productSettings'].variablesList =
          newSkuVars;
      }

      //Push variables to product menu items
      for (const group in state.skuItems) {
        for (const platform in state.skuItems[group]) {
          for (const productId in state.skuItems[group][platform]) {
            const currentMenu = state.menus[group][platform];
            state.menusWithChanges.add(currentMenu.id);
            const item = state.skuItems[group][platform][productId];
            item.variables = {
              ...item.variables,
              ...Object.entries(allSkuVariables).reduce(
                (output, [key, meta]) => {
                  return {
                    ...output,
                    [key]: meta.value,
                  };
                },
                {}
              ),
            };
          }
        }
      }

      //Push variables to available items
      for (const platform in state.availableItems) {
        for (const group in state.availableItems[platform]) {
          for (const productIndex in state.availableItems[platform][group]) {
            const item = state.availableItems[platform][group][productIndex];
            item.variables = {
              ...item.variables,
              ...Object.entries(allSkuVariables).reduce(
                (output, [key, meta]) => {
                  return {
                    ...output,
                    [key]: meta.value,
                  };
                },
                {}
              ),
            };
          }
        }
      }

      //Add to template capabilities array
      state.paywall!.template['ui.capabilities'] = addToCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'conditional_product_intro'
      );
      state.anySkuHasIntroOffer = true;
    },
    removeIntroEligibility(state) {
      if (!state.idLocations || !state.paywall || !state.paywall.template)
        return;
      state.hasChanges = true;

      removeCapability(
        state.idLocations,
        state.idTypeLocations,
        state.paywall!.template?.pages,
        ['${sku.introEligible}', '${state.anySkuHasIntroOffer}'],
        true
      );

      //Remove from sku variables
      if (state.paywall!.template['ui.productSettings']?.variablesList) {
        const newSkuVars = (
          state.paywall!.template['ui.productSettings'].variablesList || []
        ).reduce((output, variable) => {
          if (variable.offerType && variable.offerType === 'intro')
            return output;
          return [...output, variable];
        }, [] as TProductVariable[]);
        state.paywall!.template['ui.productSettings'].variablesList =
          newSkuVars;
      }

      //Remove from template capabilities array
      state.paywall!.template['ui.capabilities'] = removeFromCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'conditional_product_intro'
      );
      state.anySkuHasIntroOffer = false;
    },
    addDynamicSignin(state) {
      //Add to template capabilities array
      state.hasChanges = true;
      state.paywall!.template['ui.capabilities'] = addToCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'dynamic_signin'
      );
    },
    removeDynamicSignin(state) {
      state.hasChanges = true;

      removeCapability(
        state.idLocations,
        state.idTypeLocations,
        state.paywall!.template?.pages,
        ['${state.isLoggedIn}'],
        true
      );

      state.paywall!.template['ui.capabilities'] = removeFromCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'dynamic_signin'
      );
    },
    addProductGroups(state) {
      state.hasChanges = true;
      state.paywall!.template['ui.capabilities'] = addToCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'product_groups'
      );
      state.paywall!.template['ui.requiredGroups'] = state.productGroups.length;
      state.paywall!.template['ui.dynamicAddGroups'] = true;

      //Add segment picker
      const segmentPickerResult: TNewComponentBuilderMeta =
        generateFullComponent(
          'productGroupToggle',
          'productGroupToggle',
          state.formFactor,
          state.idLocations
        );
      if (state.productGroups.length > 2) {
        const firstToggleItem = (segmentPickerResult.component as TContainer)
          .components[0];
        for (let i = 2; i < state.productGroups.length; i++) {
          const newToggleItem = {
            ...firstToggleItem,
          };
          newToggleItem.id = `\${state.groups.${i}.id}`;
          (newToggleItem as any).text = `\${state.groups.${i}.displayName}`;
          newToggleItem.title = 'Toggle Item';

          (segmentPickerResult.component as TContainer).components.push(
            newToggleItem
          );
        }
      }

      const variableMap: Record<string, string> = Object.keys(
        segmentPickerResult.variables
      ).reduce((output, key) => {
        const currentValue = segmentPickerResult.variables[key];
        return {
          ...output,
          [key]: currentValue.value,
        };
      }, {});
      const newVars = { ...variableMap, ...state.paywall!.template.variables };
      state.paywall!.template.variables = newVars;
      const contentContainer = findComponentFromLocation(
        state.paywall!.template?.pages,
        parseLocationString(state.idLocations['contentContainer'])
      );
      (contentContainer as TContainer).components.unshift(
        segmentPickerResult.component
      );
    },
    removeProductGroups(state) {
      //TODO - code duplicated in updateProductGroupState()
      state.hasChanges = true;
      state.editingComponentId = null;

      const groupCheckArray = state.productGroups.reduce(
        (output, _group, index) => {
          return [...output, `\${state.groups.${index}.id}`];
        },
        [] as Array<string>
      );

      removeCapability(
        state.idLocations,
        state.idTypeLocations,
        state.paywall!.template.pages,
        groupCheckArray,
        true
      );

      state.paywall!.template['ui.capabilities'] = removeFromCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'product_groups'
      );
      state.paywall!.template['ui.requiredGroups'] = 1;
      state.groupId = state.productGroups[0].id;

      //Remove conditional product groups if necessary
      if (
        (state.paywall!.template['ui.capabilities'] || []).includes(
          'conditional_product_groups'
        )
      ) {
        removeCapability(
          state.idLocations,
          state.idTypeLocations,
          state.paywall!.template.pages,
          ['${launch.productGroups}'],
          true
        );

        state.paywall!.template['ui.capabilities'] =
          removeFromCapabilitiesArray(
            state.paywall!.template['ui.capabilities'] || [],
            'conditional_product_groups'
          );
      }

      //Remove product group toggle
      const toggleLocation = state.idLocations['productGroupToggle'];
      if (toggleLocation) {
        findComponentFromLocation(
          state.paywall!.template.pages,
          parseLocationString(toggleLocation),
          true
        );

        //Recalculate ids
        const newMeta = constructIdMetadata(state.paywall!.template.pages);
        state.idLocations = newMeta.idLocations;
        state.idTypeLocations = newMeta.idLocationsByType;
      }

      if (!state.paywall!.template['ui.displaySingleGroup']) {
        //Update product container data source
        if (state.idLocations['productContainer']) {
          const productContainer = findComponentFromLocation(
            state.paywall!.template.pages,
            parseLocationString(state.idLocations['productContainer'])
          );
          if (productContainer) {
            (productContainer as TProductContainer).products = 'all';
            delete (productContainer as TProductContainer).subsetGroup;
          }
        }

        //Remove additional product containers
        if (!state.paywall?.template['ui.capabilities'].includes('multipage')) {
          const allContainerLocations =
            state.idTypeLocations['productContainer'];
          if (allContainerLocations.length > 1) {
            for (let i = allContainerLocations.length - 1; i >= 1; i--) {
              findComponentFromLocation(
                state.paywall!.template.pages,
                parseLocationString(allContainerLocations[i]),
                true
              );
            }
          }
        }
      }

      state.paywall!.template['ui.displaySingleGroup'] = true;
      state.paywall!.template['ui.dynamicAddGroups'] = false;
    },
    addDynamicProductGroups(state) {
      state.hasChanges = true;
      state.paywall!.template['ui.capabilities'] = addToCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'conditional_product_groups'
      );
    },
    removeDynamicProductGroups(state) {
      state.hasChanges = true;

      removeCapability(
        state.idLocations,
        state.idTypeLocations,
        state.paywall!.template?.pages,
        ['${launch.productGroups}'],
        true
      );

      state.paywall!.template['ui.capabilities'] = removeFromCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'conditional_product_groups'
      );
    },
    addMultipleProductGroups(state) {
      state.hasChanges = true;
      state.paywall!.template['ui.displaySingleGroup'] = false;
      state.paywall!.template['ui.capabilities'] = addToCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'product_groups'
      );
      state.paywall!.template['ui.requiredGroups'] = 2;

      //Update product container data source
      const productContainerLocation = parseLocationString(
        state.idLocations['productContainer']
      );
      const productContainer = findComponentFromLocation(
        state.paywall!.template.pages,
        productContainerLocation
      );
      (productContainer as TProductContainer).products = 'subset';
      (productContainer as TProductContainer).subsetGroup =
        '${state.groups.0.id}';

      //Add new product container
      let newProductContainer = duplicateComponentAndChildren(
        productContainer,
        state.paywall!.template.variables || {},
        state.idLocations
      );
      (newProductContainer.component as TProductContainer).subsetGroup =
        '${state.groups.1.id}';
      newProductContainer.component.title = 'Repeating Product Group 1';

      const parentObject = findComponentFromLocation(
        state.paywall!.template.pages,
        moveUpTreeFromLocation(productContainerLocation)
      );
      const productContainerIndexInsideParent =
        productContainerLocation.pos[productContainerLocation.pos.length - 1];

      if (typeof productContainerIndexInsideParent === 'number') {
        (parentObject as TContainer).components.splice(
          productContainerIndexInsideParent + 1,
          0,
          newProductContainer.component
        );
      }

      const newVariables = {
        ...state.paywall!.template.variables,
        ...newProductContainer.variables,
      };
      state.paywall!.template.variables = newVariables;

      //Update all product containers
      if (
        state.paywall?.template['ui.capabilities']?.includes('multipage') &&
        state.idLocations['productContainer0']
      ) {
        const addtlProductContainerLocation = parseLocationString(
          state.idLocations['productContainer0']
        );
        const addtlProductContainer = findComponentFromLocation(
          state.paywall!.template.pages,
          addtlProductContainerLocation
        );
        (addtlProductContainer as TProductContainer).products = 'subset';
        (addtlProductContainer as TProductContainer).subsetGroup =
          '${state.groups.0.id}';
      }

      //Remove product group conditionals
      const groupCheckArray = state.productGroups.reduce(
        (output, _group, index) => {
          return [...output, `\${state.groups.${index}.id}`];
        },
        [] as Array<string>
      );
      removeCapability(
        state.idLocations,
        state.idTypeLocations,
        state.paywall!.template.pages,
        groupCheckArray,
        true
      );

      //Remove product group toggle
      if (state.idLocations['productGroupToggle']) {
        findComponentFromLocation(
          state.paywall!.template.pages,
          parseLocationString(state.idLocations['productGroupToggle']),
          true
        );
      }
    },
    removeMultipleProductGroups(state) {
      state.hasChanges = true;
      state.paywall!.template['ui.displaySingleGroup'] = true;

      //Update product container data source
      const productContainerLocation = parseLocationString(
        state.idLocations['productContainer']
      );
      const productContainer = findComponentFromLocation(
        state.paywall!.template.pages,
        productContainerLocation
      );
      (productContainer as TProductContainer).products = 'all';
      delete (productContainer as TProductContainer).subsetGroup;

      //Restore toggle
      const segmentPickerResult: TNewComponentBuilderMeta =
        generateFullComponent(
          'productGroupToggle',
          'productGroupToggle',
          state.formFactor,
          state.idLocations
        );
      if (state.productGroups.length > 2) {
        //Generate more toggle items
        const firstToggleItem = (segmentPickerResult.component as TContainer)
          .components[0];
        for (let i = 2; i < state.productGroups.length; i++) {
          const newToggleItem = {
            ...firstToggleItem,
          };
          newToggleItem.id = `\${state.groups.${i}.id}`;
          (newToggleItem as any).text = `\${state.groups.${i}.displayName}`;
          newToggleItem.title = 'Toggle Item';

          (segmentPickerResult.component as TContainer).components.push(
            newToggleItem
          );
        }
      }

      const variableMap: Record<string, string> = Object.keys(
        segmentPickerResult.variables
      ).reduce((output, key) => {
        const currentValue = segmentPickerResult.variables[key];
        return {
          ...output,
          [key]: currentValue.value,
        };
      }, {});
      const newVars = { ...variableMap, ...state.paywall!.template.variables };
      state.paywall!.template.variables = newVars;
      const contentContainer = findComponentFromLocation(
        state.paywall!.template?.pages,
        parseLocationString(state.idLocations['contentContainer'])
      );
      (contentContainer as TContainer).components.unshift(
        segmentPickerResult.component
      );

      //Recalculate ids
      const newMeta = constructIdMetadata(state.paywall!.template.pages);
      state.idLocations = newMeta.idLocations;
      state.idTypeLocations = newMeta.idLocationsByType;

      //Remove addtl product containers
      const allContainerLocations = state.idTypeLocations['productContainer'];
      if (allContainerLocations.length > 1) {
        for (let i = allContainerLocations.length - 1; i >= 1; i--) {
          findComponentFromLocation(
            state.paywall!.template.pages,
            parseLocationString(allContainerLocations[i]),
            true
          );
        }
      }
    },
    updateProductGroupState(state) {
      if (state.paywall?.template['ui.v2Ready']) {
        //Remove product groups if necessary
        if (
          !state.paywall?.template['ui.capabilities']?.includes(
            'product_group_carousel'
          ) &&
          state.productGroups.length === 1
        ) {
          //TODO - code duplicated from removeProductGroups()
          state.hasChanges = true;
          state.editingComponentId = null;

          const groupCheckArray = state.productGroups.reduce(
            (output, _group, index) => {
              return [...output, `\${state.groups.${index}.id}`];
            },
            [] as Array<string>
          );

          removeCapability(
            state.idLocations,
            state.idTypeLocations,
            state.paywall!.template.pages,
            groupCheckArray,
            true
          );

          state.paywall!.template['ui.capabilities'] =
            removeFromCapabilitiesArray(
              state.paywall!.template['ui.capabilities'] || [],
              'product_groups'
            );
          state.paywall!.template['ui.requiredGroups'] = 1;
          state.groupId = state.productGroups[0].id;

          //Remove conditional product groups if necessary
          if (
            (state.paywall!.template['ui.capabilities'] || []).includes(
              'conditional_product_groups'
            )
          ) {
            removeCapability(
              state.idLocations,
              state.idTypeLocations,
              state.paywall!.template.pages,
              ['${launch.productGroups}'],
              true
            );

            state.paywall!.template['ui.capabilities'] =
              removeFromCapabilitiesArray(
                state.paywall!.template['ui.capabilities'] || [],
                'conditional_product_groups'
              );
          }

          //Remove product group toggle
          const toggleLocation = state.idLocations['productGroupToggle'];
          if (toggleLocation) {
            findComponentFromLocation(
              state.paywall!.template.pages,
              parseLocationString(toggleLocation),
              true
            );

            //Recalculate ids
            const newMeta = constructIdMetadata(state.paywall!.template.pages);
            state.idLocations = newMeta.idLocations;
            state.idTypeLocations = newMeta.idLocationsByType;
          }

          if (!state.paywall!.template['ui.displaySingleGroup']) {
            //Update product container data source
            if (state.idLocations['productContainer']) {
              const productContainer = findComponentFromLocation(
                state.paywall!.template.pages,
                parseLocationString(state.idLocations['productContainer'])
              );
              if (productContainer) {
                (productContainer as TProductContainer).products = 'all';
                delete (productContainer as TProductContainer).subsetGroup;
              }
            }

            //Remove additional product containers
            if (
              !state.paywall?.template['ui.capabilities'].includes('multipage')
            ) {
              const allContainerLocations =
                state.idTypeLocations['productContainer'];
              if (allContainerLocations.length > 1) {
                for (let i = allContainerLocations.length - 1; i >= 1; i--) {
                  findComponentFromLocation(
                    state.paywall!.template.pages,
                    parseLocationString(allContainerLocations[i]),
                    true
                  );
                }
              }
            }
          }

          state.paywall!.template['ui.displaySingleGroup'] = true;
          state.paywall!.template['ui.dynamicAddGroups'] = false;
        } else {
          //Add or remove toggle items as necessary
          if (state.paywall.template['ui.dynamicAddGroups']) {
            const productGroupToggleLocation =
              state.idTypeLocations['productGroupToggle'];
            if (
              productGroupToggleLocation &&
              state.paywall?.template['ui.displaySingleGroup']
            ) {
              const productGroupToggle = findComponentFromLocation(
                state.paywall!.template?.pages,
                parseLocationString(
                  state.idTypeLocations['productGroupToggle'][0]
                )
              );

              //Add toggle items if necessary
              if (
                (productGroupToggle as TContainer).components.length <
                state.productGroups.length
              ) {
                state.hasChanges = true;
                const firstToggleItem = (productGroupToggle as TContainer)
                  .components[0];

                const startIndex = (productGroupToggle as TContainer).components
                  .length;
                for (let i = startIndex; i < state.productGroups.length; i++) {
                  const newToggleItem = {
                    ...firstToggleItem,
                  };
                  newToggleItem.id = `\${state.groups.${i}.id}`;
                  (
                    newToggleItem as any
                  ).text = `\${state.groups.${i}.displayName}`;
                  newToggleItem.title = 'Toggle Item';

                  (productGroupToggle as TContainer).components.push(
                    newToggleItem
                  );
                }
              }

              //Remove toggle items if necessary
              if (
                (productGroupToggle as TContainer).components.length >
                state.productGroups.length
              ) {
                state.hasChanges = true;
                const newComponents = (
                  productGroupToggle as TContainer
                ).components.slice(
                  0,
                  state.productGroups.length -
                    (productGroupToggle as TContainer).components.length
                );
                (productGroupToggle as TContainer).components = newComponents;
              }
            }
          }
        }
      }
    },
    updateComponentSubsetGroupValues(
      state,
      action: PayloadAction<{
        idLocation: TComponentLocation;
        subsetGroup: string;
      }>
    ) {
      const { idLocation, subsetGroup } = action.payload;
      if (!idLocation) return;
      state.hasChanges = true;
      const component = findComponentFromLocation(
        state.paywall!.template.pages,
        idLocation
      );

      (component as TProductContainer).subsetGroup = subsetGroup;
    },
    updateRepeatingGridComponentDataSource(
      state,
      action: PayloadAction<{
        idLocation: TComponentLocation;
        dataSource: string;
      }>
    ) {
      const { idLocation, dataSource } = action.payload;
      if (!idLocation) return;
      state.hasChanges = true;
      const component = findComponentFromLocation(
        state.paywall!.template.pages,
        idLocation
      );

      if (component.namiComponentType === 'repeatingList') {
        (component as TRepeatingList).loopSource = dataSource;
      } else if (component.namiComponentType === 'repeatingTextSource') {
        (component as TTextComponent).text = dataSource;
      } else if (component.namiComponentType === 'repeatingImageSource') {
        (component as TImageComponent).url = dataSource;
      }
    },
    addCustomLaunchContext(state) {
      state.hasChanges = true;
      state.paywall!.template['ui.capabilities'] = addToCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'custom_launch_context'
      );
    },
    removeCustomLaunchContext(state) {
      state.hasChanges = true;

      removeCapability(
        state.idLocations,
        state.idTypeLocations,
        state.paywall!.template?.pages,
        ['${launch.customAttributes'],
        true,
        true
      );

      state.paywall!.template['ui.capabilities'] = removeFromCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'custom_launch_context'
      );
      state.launch.customAttributes = {};
    },
    addAdvancedVideo(state) {
      state.hasChanges = true;
      state.paywall!.template['ui.capabilities'] = addToCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'video'
      );
      const newMinSDK = getNewMinSDKVersionFromChange(
        ['advanced_video'],
        state.formFactor,
        state.minSDKVersion
      );
      if (newMinSDK !== state.minSDKVersion) {
        state.pendingSDKVersion = newMinSDK;
        state.pendingSDKChanges = ['advanced_video'];
      }
    },
    removeAdvancedVideo(state) {
      state.hasChanges = true;

      //Remove all custom video buttons
      if (state.idTypeLocations['playPauseButton']) {
        state.editingComponentId = null;
        state.idTypeLocations['playPauseButton'].forEach((buttonLocation) => {
          const parsedLocation = parseLocationString(buttonLocation);
          findComponentFromLocation(
            state.paywall!.template?.pages,
            parsedLocation,
            true
          );
        });
        const newMeta = constructIdMetadata(state.paywall!.template.pages);
        state.idLocations = newMeta.idLocations;
        state.idTypeLocations = newMeta.idLocationsByType;
      }

      if (state.idTypeLocations['volumeButton']) {
        state.editingComponentId = null;
        state.idTypeLocations['volumeButton'].forEach((buttonLocation) => {
          const parsedLocation = parseLocationString(buttonLocation);
          findComponentFromLocation(
            state.paywall!.template?.pages,
            parsedLocation,
            true
          );
        });
        const newMeta = constructIdMetadata(state.paywall!.template.pages);
        state.idLocations = newMeta.idLocations;
        state.idTypeLocations = newMeta.idLocationsByType;
      }

      removeCapability(
        state.idLocations,
        state.idTypeLocations,
        state.paywall!.template?.pages,
        ['${state.appSuppliedVideoUrl}'],
        true
      );

      state.paywall!.template['ui.capabilities'] = removeFromCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'video'
      );
    },
    addCustomObject(state) {
      state.hasChanges = true;
      state.paywall!.template['ui.capabilities'] = addToCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'product_groups'
      );
    },
    removeCustomObject(state) {
      state.hasChanges = true;

      removeCapability(
        state.idLocations,
        state.idTypeLocations,
        state.paywall!.template?.pages,
        ['${launch.customObject'],
        true,
        true
      );

      state.paywall!.template['ui.capabilities'] = removeFromCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'custom_launch_object'
      );
    },
    enableScreenreaderText(state) {
      state.hasChanges = true;

      const productContainerChildren = findProductContainerChildLocations(
        state.idLocations,
        state.idTypeLocations
      );

      const contentContainerChildren = findContentContainerFooterChildLocations(
        state.idLocations,
        false,
        false
      );

      const headerChildren = findHeaderChildLocations(state.idLocations);

      const allChildren = [
        ...productContainerChildren,
        ...contentContainerChildren,
        ...headerChildren,
      ];

      //Add screenreader text to all buttons
      allChildren.forEach((child) => {
        const parsedChildLocation = parseLocationString(child);
        const childComponent = findComponentFromLocation(
          state.paywall!.template?.pages,
          parsedChildLocation
        );

        if (
          childComponent.component === 'button' ||
          childComponent.component === 'volumeButton' ||
          childComponent.component === 'playPauseButton'
        ) {
          const result = addScreenreaderTextToComponent(childComponent);

          const variableMap: Record<string, string> = Object.keys(
            result.variables
          ).reduce((output, key) => {
            const currentValue = result.variables[key];
            return {
              ...output,
              [key]: currentValue.value,
            };
          }, {});
          const newVars = {
            ...variableMap,
            ...state.paywall!.template.variables,
          };
          state.paywall!.template.variables = newVars;
        }
      });

      //Add product field
      if (
        state.paywall!.template['ui.productSettings'] &&
        state.paywall!.template['ui.productSettings'].variablesList
      ) {
        const newProductField: TProductVariable = {
          display_name: 'Screenreader Text',
          variable: 'screenreaderText',
          type: 'string',
          value: 'tap to purchase product for ${sku.price}/${sku.period}',
        };
        const newSkuVars = [
          ...(state.paywall!.template['ui.productSettings'] || [])
            .variablesList,
          newProductField,
        ];
        state.paywall!.template['ui.productSettings'].variablesList =
          newSkuVars;

        //Push variables to product menu items
        for (const group in state.skuItems) {
          for (const platform in state.skuItems[group]) {
            for (const productId in state.skuItems[group][platform]) {
              const currentMenu = state.menus[group][platform];
              state.menusWithChanges.add(currentMenu.id);
              const item = state.skuItems[group][platform][productId];
              item.variables = {
                ...item.variables,
                [newProductField.variable]: newProductField.value,
              };
            }
          }
        }

        //Push variable to available items
        for (const platform in state.availableItems) {
          for (const group in state.availableItems[platform]) {
            for (const productIndex in state.availableItems[platform][group]) {
              const item = state.availableItems[platform][group][productIndex];
              item.variables = {
                ...item.variables,
                [newProductField.variable]: newProductField.value,
              };
            }
          }
        }
      }

      state.paywall!.template['ui.capabilities'] = addToCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'accessibility'
      );
    },
    removeScreenreaderText(state) {
      //TODO - remove conditional screenreader text
      state.hasChanges = true;

      const productContainerChildren = findProductContainerChildLocations(
        state.idLocations,
        state.idTypeLocations
      );

      const contentContainerChildren = findContentContainerFooterChildLocations(
        state.idLocations,
        false,
        false
      );

      const headerChildren = findHeaderChildLocations(state.idLocations);

      const allChildren = [
        ...productContainerChildren,
        ...contentContainerChildren,
        ...headerChildren,
      ];

      allChildren.forEach((child) => {
        const parsedChildLocation = parseLocationString(child);
        const childComponent = findComponentFromLocation(
          state.paywall!.template?.pages,
          parsedChildLocation
        );

        if (
          childComponent.component === 'button' ||
          childComponent.component === 'volumeButton' ||
          childComponent.component === 'playPauseButton'
        ) {
          delete childComponent.screenreaderText;
        }
      });

      //Remove product field
      if (
        state.paywall!.template['ui.productSettings'] &&
        state.paywall!.template['ui.productSettings'].variablesList
      ) {
        const newSkuVars = state.paywall!.template[
          'ui.productSettings'
        ].variablesList.reduce((output, productVariable) => {
          if (productVariable.variable === 'screenreaderText') return output;
          return [...output, productVariable];
        }, [] as TProductVariable[]);

        state.paywall!.template['ui.productSettings'].variablesList =
          newSkuVars;

        //Remove variable from product menu items
        for (const group in state.skuItems) {
          for (const platform in state.skuItems[group]) {
            for (const productId in state.skuItems[group][platform]) {
              const currentMenu = state.menus[group][platform];
              state.menusWithChanges.add(currentMenu.id);
              const item = state.skuItems[group][platform][productId];
              delete item.variables['screenreaderText'];
            }
          }
        }

        //Remove variable from available items
        for (const platform in state.availableItems) {
          for (const group in state.availableItems[platform]) {
            for (const productIndex in state.availableItems[platform][group]) {
              const item = state.availableItems[platform][group][productIndex];
              delete item.variables['screenreaderText'];
            }
          }
        }
      }

      state.paywall!.template['ui.capabilities'] = removeFromCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'accessibility'
      );
    },
    enableMultipage(state) {
      //Construct second page
      let newContentContainer = generateFullComponent(
        'contentContainer0',
        'contentContainer',
        state.formFactor,
        state.idLocations,
        'page2'
      );

      let newBackground = generateFullComponent(
        'backgroundContainer0',
        'backgroundContainer',
        state.formFactor,
        state.idLocations,
        'page2'
      );

      let newProductContainer = generateFullComponent(
        'productContainer0', //TODO - unique id
        'productContainer',
        state.formFactor,
        state.idLocations,
        'page2'
      );

      let navigationButtonVariableMap: Record<string, string> = {};
      const contentContainerLocation = parseLocationString(
        state.idLocations['contentContainer']
      );

      if (newBackground && newContentContainer) {
        state.hasChanges = true;
        state.paywall!.template['ui.capabilities'] = addToCapabilitiesArray(
          state.paywall!.template['ui.capabilities'] || [],
          'multipage'
        );

        //Add navigatePageButton to bottom of page 1
        if (contentContainerLocation) {
          const currentContentContainer = findComponentFromLocation(
            state.paywall!.template.pages,
            contentContainerLocation
          );
          const page1NavigateButton = generateFullComponent(
            'navigatePageButton',
            'navigatePageButton',
            state.formFactor,
            state.idLocations,
            'page1'
          );

          navigationButtonVariableMap = Object.keys(
            page1NavigateButton.variables
          ).reduce((output, key) => {
            const currentValue = page1NavigateButton.variables[key];
            return {
              ...output,
              [key]: currentValue.value,
            };
          }, {});

          (currentContentContainer as TContainer).components.push(
            page1NavigateButton.component
          );

          const newMeta = constructIdMetadata(state.paywall!.template.pages);
          state.idLocations = newMeta.idLocations;
          state.idTypeLocations = newMeta.idLocationsByType;
        }

        const contentContainerVariableMap = Object.keys(
          newContentContainer.variables
        ).reduce((output, key) => {
          const currentValue = newContentContainer.variables[key];
          return {
            ...output,
            [key]: currentValue.value,
          };
        }, {});

        const backgroundVariableMap = Object.keys(
          newBackground.variables
        ).reduce((output, key) => {
          const currentValue = newBackground.variables[key];
          return {
            ...output,
            [key]: currentValue.value,
          };
        }, {});

        //Generate header if necessary
        let headerVariableMap = {};
        const newHeader =
          state.formFactor === 'phone' || state.formFactor === 'tablet'
            ? generateFullComponent(
                'header0',
                'header',
                state.formFactor,
                state.idLocations,
                'page2'
              )
            : undefined;

        if (newHeader) {
          headerVariableMap = Object.keys(newHeader.variables || {}).reduce(
            (output, key) => {
              const currentValue = newHeader.variables[key];
              return {
                ...output,
                [key]: currentValue.value,
              };
            },
            {}
          );
        }

        const productVariableMap = Object.keys(
          newProductContainer.variables
        ).reduce((output, key) => {
          const currentValue = newProductContainer.variables[key];
          return {
            ...output,
            [key]: currentValue.value,
          };
        }, {});

        //Add product container to page 2
        (newContentContainer.component as TContainer).components = [
          newProductContainer.component,
        ];

        //Add navigation button to page 2
        const page2NavigateButton = generateFullComponent(
          'navigatePageButton0',
          'navigatePageButton',
          state.formFactor,
          state.idLocations,
          'page2'
        );

        navigationButtonVariableMap = Object.keys(
          page2NavigateButton.variables
        ).reduce((output, key) => {
          const currentValue = page2NavigateButton.variables[key];
          return {
            ...output,
            [key]: currentValue.value,
          };
        }, navigationButtonVariableMap);

        (newContentContainer.component as TContainer).components.push(
          page2NavigateButton.component
        );

        const newPage: TPaywallPage = {
          name: 'page2',
          contentContainer: newContentContainer.component as TContainer,
          header: newHeader ? [newHeader.component as TContainer] : [],
          footer: null,
          backgroundContainer: newBackground.component as TContainer,
        };

        state.paywall!.template.pages.push(newPage);

        //TODO - handle sku/media variables?
        const newVariables = {
          ...state.paywall!.template.variables,
          ...contentContainerVariableMap,
          ...backgroundVariableMap,
          ...headerVariableMap,
          ...productVariableMap,
          ...navigationButtonVariableMap,
        };
        state.paywall!.template.variables = newVariables;
      } else {
        if (!newBackground) {
          console.warn(
            'Cannot add new page, page 1 background container missing'
          );
        } else if (!newContentContainer) {
          console.warn('Cannot add new page, page 1 content container missing');
        }
      }
    },
    removeMultipage(state) {
      if (state.paywall?.template.pages.length === 1) return;
      state.hasChanges = true;

      //Remove additional pages
      state.paywall?.template.pages.pop();

      const newMeta = constructIdMetadata(state.paywall!.template.pages);
      state.idLocations = newMeta.idLocations;
      state.idTypeLocations = newMeta.idLocationsByType;

      //Remove navigationButton
      if (state.idLocations['navigatePageButton']) {
        findComponentFromLocation(
          state.paywall!.template.pages,
          parseLocationString(state.idLocations['navigatePageButton']),
          true
        );
      }

      //Remove capability
      state.paywall!.template['ui.capabilities'] = removeFromCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'multipage'
      );
    },
    addFlow(state) {
      state.hasChanges = true;

      //Add previous flow button to top of paywall content
      const previousButtonId = generateNextIdForType(
        'flowButton',
        state.idLocations
      );
      const previousButtonResult: TNewComponentBuilderMeta =
        generateFullComponent(
          previousButtonId,
          'flowButton',
          state.formFactor,
          state.idLocations,
          state.currentPage
        );
      previousButtonResult.component.title = 'Flow Back Button';
      //Change button action to previous
      const previousButtonOnTapVariable = deconstructVariable(
        (previousButtonResult.component as any).onTap,
        false,
        true
      );
      previousButtonResult.variables[previousButtonOnTapVariable] = {
        ...previousButtonResult.variables[previousButtonOnTapVariable],
        value: { function: 'namiFlowPreviousStep' },
      };

      //Change button text to 'Back'
      if (
        (previousButtonResult.component as any).components &&
        (previousButtonResult.component as any).components[0]
      ) {
        const previousButtonTextVariable = deconstructVariable(
          (previousButtonResult.component as any).components[0].text,
          false,
          true
        );
        previousButtonResult.variables[previousButtonTextVariable] = {
          ...previousButtonResult.variables[previousButtonTextVariable],
          value: 'Back',
        };
      }

      const previousConditionId = generateNextIdForType(
        'condition',
        state.idLocations
      );
      const previousConditionWrapper: TConditionalComponent = {
        id: previousConditionId,
        title: 'Show Back Button?',
        namiComponentType: 'condition',
        component: 'condition',
        assertions: [
          {
            value: '${flow.exists}',
            expected: true,
            operator: 'equals',
          },
          {
            value: '${flow.previousStepAvailable}',
            expected: true,
            operator: 'equals',
          },
        ],
        components: [previousButtonResult.component],
      };

      //Add next/skip flow button to top of paywall content
      const nextButtonId = generateNextIdForType('flowButton', {
        ...state.idLocations,
        [previousButtonId]: 'todo',
      });
      const nextButtonResult: TNewComponentBuilderMeta = generateFullComponent(
        nextButtonId,
        'flowButton',
        state.formFactor,
        state.idLocations,
        state.currentPage
      );
      nextButtonResult.component.title = 'Flow Skip Button';
      const nextConditionId = generateNextIdForType('condition', {
        ...state.idLocations,
        [previousConditionId]: 'todo',
      });

      const nextConditionWrapper: TConditionalComponent = {
        id: nextConditionId,
        title: 'Show Skip Button?',
        namiComponentType: 'condition',
        component: 'condition',
        assertions: [
          {
            value: '${flow.exists}',
            expected: true,
            operator: 'equals',
          },
          {
            value: '${flow.nextStepAvailable}',
            expected: true,
            operator: 'equals',
          },
        ],
        components: [nextButtonResult.component],
      };

      //Add continue flow button to top of paywall content
      const continueButtonId = generateNextIdForType('flowButton', {
        ...state.idLocations,
        [previousButtonId]: 'todo',
        [nextButtonId]: 'todo',
      });
      const continueButtonResult: TNewComponentBuilderMeta =
        generateFullComponent(
          continueButtonId,
          'flowButton',
          state.formFactor,
          state.idLocations,
          state.currentPage
        );
      continueButtonResult.component.title = 'Flow Continue Button';
      //Change button action to previous
      const continueButtonOnTapVariable = deconstructVariable(
        (continueButtonResult.component as any).onTap,
        false,
        true
      );
      continueButtonResult.variables[continueButtonOnTapVariable] = {
        ...continueButtonResult.variables[continueButtonOnTapVariable],
        value: { function: 'namiFlowConfirmStep' },
      };

      //Change button text to 'Continue'
      if (
        (continueButtonResult.component as any).components &&
        (continueButtonResult.component as any).components[0]
      ) {
        const continueButtonTextVariable = deconstructVariable(
          (continueButtonResult.component as any).components[0].text,
          false,
          true
        );
        continueButtonResult.variables[continueButtonTextVariable] = {
          ...continueButtonResult.variables[continueButtonTextVariable],
          value: 'Continue',
        };
      }
      const continueConditionId = generateNextIdForType('condition', {
        ...state.idLocations,
        [previousConditionId]: 'todo',
        [nextConditionId]: 'todo',
      });

      const continueConditionWrapper: TConditionalComponent = {
        id: continueConditionId,
        title: 'Show Flow Continue Button?',
        namiComponentType: 'condition',
        component: 'condition',
        assertions: [
          {
            value: '${flow.exists}',
            expected: true,
            operator: 'equals',
          },
        ],
        components: [continueButtonResult.component],
      };

      const nextButtonVariableMap: Record<string, string> = Object.keys(
        nextButtonResult.variables
      ).reduce((output, key) => {
        const currentValue = nextButtonResult.variables[key];
        return {
          ...output,
          [key]: currentValue.value,
        };
      }, {});
      const prevButtonVariableMap: Record<string, string> = Object.keys(
        previousButtonResult.variables
      ).reduce((output, key) => {
        const currentValue = previousButtonResult.variables[key];
        return {
          ...output,
          [key]: currentValue.value,
        };
      }, {});
      const continueButtonVariableMap: Record<string, string> = Object.keys(
        continueButtonResult.variables
      ).reduce((output, key) => {
        const currentValue = continueButtonResult.variables[key];
        return {
          ...output,
          [key]: currentValue.value,
        };
      }, {});
      const newVars = {
        ...nextButtonVariableMap,
        ...prevButtonVariableMap,
        ...continueButtonVariableMap,
        ...state.paywall!.template.variables,
      };
      state.paywall!.template.variables = newVars;

      const parent = findComponentFromLocation(
        state.paywall!.template?.pages,
        parseLocationString(state.idLocations['contentContainer'])
      );
      (parent as TContainer).components = [
        ...(parent as TContainer).components,
        previousConditionWrapper,
        continueConditionWrapper,
        nextConditionWrapper,
      ];

      state.paywall!.template['ui.capabilities'] = addToCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'flow'
      );
      state.flowState = true;
    },
    removeFlow(state) {
      //Remove all flow buttons
      state.hasChanges = true;
      if (state.idTypeLocations['flowButton']) {
        state.editingComponentId = null;
        state.idTypeLocations['flowButton'].forEach((buttonLocation) => {
          const parsedLocation = parseLocationString(buttonLocation);
          findComponentFromLocation(
            state.paywall!.template?.pages,
            parsedLocation,
            true
          );
        });
        const newMeta = constructIdMetadata(state.paywall!.template.pages);
        state.idLocations = newMeta.idLocations;
        state.idTypeLocations = newMeta.idLocationsByType;
      }

      //Remove capability
      removeCapability(
        state.idLocations,
        state.idTypeLocations,
        state.paywall!.template?.pages,
        [
          '${flow.nextStepAvailable}',
          '${flow.previousStepAvailable}',
          '${flow.exists}',
        ],
        true
      );

      state.paywall!.template['ui.capabilities'] = removeFromCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'flow'
      );
      state.flowState = false;
    },
    addProductErrorStates(state) {
      if (
        state.idLocations['productButton'] &&
        state.idLocations['productContainer']
      ) {
        state.paywall!.template['ui.capabilities'] = addToCapabilitiesArray(
          state.paywall!.template['ui.capabilities'] || [],
          'product_error_states'
        );
        state.anySkuInvalid = true;
        state.hasChanges = true;

        const productContainerLocation = parseLocationString(
          state.idLocations['productContainer']
        );
        const productContainerComponent = findComponentFromLocation(
          state.paywall!.template?.pages,
          productContainerLocation
        );

        if (productContainerComponent) {
          //Wrap existing product container content inside condition
          const validProductCondition: TConditionalComponent = {
            component: 'condition',
            namiComponentType: 'condition',
            id: 'validProductCondition',
            title: 'Product is Valid and Available?',
            assertions: [
              {
                value: '${sku.invalid}',
                expected: false,
                operator: 'equals',
              },
              {
                value: '${sku.unavailable}',
                expected: false,
                operator: 'equals',
              },
            ],
          };
          validProductCondition.components = (
            productContainerComponent as TProductContainer
          ).components;

          //Add invalid sku condition and product button
          const invalidConditionComponent: TConditionalComponent = {
            component: 'condition',
            namiComponentType: 'condition',
            id: 'invalidProductCondition',
            title: 'Product is Invalid?',
            assertions: [
              {
                value: '${sku.invalid}',
                expected: true,
                operator: 'equals',
              },
              {
                value: '${sku.unavailable}',
                expected: false,
                operator: 'equals',
              },
            ],
          };
          const invalidSkuButton = generateFullComponent(
            'invalidProductButton',
            'productButton',
            state.formFactor,
            state.idLocations
          );
          (invalidSkuButton.component as TButtonContainer).onTap =
            '${var.invalidProductButtonOnTap}';
          invalidSkuButton.variables['invalidProductButtonOnTap'] = {
            value: {
              function: 'namiClosePaywall',
            },
            propertyType: 'onTap',
          };
          (
            (invalidSkuButton.component as TContainer).components[0] as any
          ).text = '${var.invalidProductButtonTextText}';
          invalidSkuButton.variables['invalidProductButtonTextText'] = {
            value: 'This offer is unavailable.',
            propertyType: 'text',
          };
          const invalidSkuButtonTextFontSize = (
            (invalidSkuButton.component as TContainer)
              .components[0] as TTextComponent
          ).fontSize;

          const invalidSkuButtonTextFontSizeVariable = deconstructVariable(
            invalidSkuButtonTextFontSize?.toString() ||
              `\${var.invalidProductButtonTextFontSize}`,
            false,
            true
          );
          invalidSkuButton.variables[
            invalidSkuButtonTextFontSizeVariable
          ].value = 18;

          invalidConditionComponent.components = [invalidSkuButton.component];
          const invalidComponentVariableMap: Record<string, string> =
            Object.keys(invalidSkuButton.variables).reduce((output, key) => {
              const currentValue = invalidSkuButton.variables[key];
              return {
                ...output,
                [key]: currentValue.value,
              };
            }, {});

          //Add unavailable sku condition and product button
          const unavailableConditionComponent: TConditionalComponent = {
            component: 'condition',
            namiComponentType: 'condition',
            id: 'unavailableProductCondition',
            title: 'Product is Unavailable?',
            assertions: [
              {
                value: '${sku.invalid}',
                expected: false,
                operator: 'equals',
              },
              {
                value: '${sku.unavailable}',
                expected: true,
                operator: 'equals',
              },
            ],
          };
          const unavailableSkuButton = generateFullComponent(
            'unavailableProductButton',
            'productButton',
            state.formFactor,
            state.idLocations
          );
          (unavailableSkuButton.component as TButtonContainer).onTap =
            '${var.unavailableProductButtonOnTap}';
          unavailableSkuButton.variables['unavailableProductButtonOnTap'] = {
            value: {
              function: 'namiReloadProducts',
            },
            propertyType: 'onTap',
          };
          (
            (unavailableSkuButton.component as TContainer).components[0] as any
          ).text = '${var.unavailableProductButtonTextText}';
          unavailableSkuButton.variables['unavailableProductButtonTextText'] = {
            value: 'Retry connection to the store?',
            propertyType: 'text',
          };
          const unavailableSkuButtonTextFontSize = (
            (unavailableSkuButton.component as TContainer)
              .components[0] as TTextComponent
          ).fontSize;

          const unavailableSkuButtonTextFontSizeVariable = deconstructVariable(
            unavailableSkuButtonTextFontSize?.toString() ||
              `\${var.unavailableProductButtonTextFontSize}`,
            false,
            true
          );
          unavailableSkuButton.variables[
            unavailableSkuButtonTextFontSizeVariable
          ].value = 18;

          unavailableConditionComponent.components = [
            unavailableSkuButton.component,
          ];
          const unavailableComponentVariableMap: Record<string, string> =
            Object.keys(unavailableSkuButton.variables).reduce(
              (output, key) => {
                const currentValue = unavailableSkuButton.variables[key];
                return {
                  ...output,
                  [key]: currentValue.value,
                };
              },
              {}
            );

          (productContainerComponent as TContainer).components = [
            validProductCondition,
            invalidConditionComponent,
            unavailableConditionComponent,
          ];

          const newVars = {
            ...invalidComponentVariableMap,
            ...unavailableComponentVariableMap,
            ...state.paywall!.template.variables,
          };
          state.paywall!.template.variables = newVars;
        }

        const newMinSDK = getNewMinSDKVersionFromChange(
          ['productErrorStates'],
          state.formFactor,
          state.minSDKVersion
        );
        if (newMinSDK !== state.minSDKVersion) {
          state.pendingSDKVersion = newMinSDK;
          state.pendingSDKChanges = ['productErrorStates'];
        }
      }
    },
    removeProductErrorStates(state) {
      state.hasChanges = true;
      //Remove capability
      removeCapability(
        state.idLocations,
        state.idTypeLocations,
        state.paywall!.template?.pages,
        ['${sku.unavailable}', '${sku.invalid}'],
        true
      );

      state.paywall!.template['ui.capabilities'] = removeFromCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'product_error_states'
      );

      //Delete invalid and unavailable product wrappers
      if (state.idLocations['invalidProductCondition']) {
        findComponentFromLocation(
          state.paywall!.template?.pages,
          parseLocationString(state.idLocations['invalidProductCondition']),
          true
        );
        const newMeta = constructIdMetadata(state.paywall!.template.pages);
        state.idLocations = newMeta.idLocations;
        state.idTypeLocations = newMeta.idLocationsByType;
      }

      if (state.idLocations['unavailableProductCondition']) {
        findComponentFromLocation(
          state.paywall!.template?.pages,
          parseLocationString(state.idLocations['unavailableProductCondition']),
          true
        );
      }
      state.anySkuInvalid = false;
      state.anySkuUnavailable = false;
    },
    enablePersonalization(state) {
      state.hasChanges = true;
      state.paywall!.template['ui.capabilities'] = addToCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'personalization'
      );
    },
    removePersonalization(state) {
      state.hasChanges = true;

      state.paywall!.template['ui.capabilities'] = removeFromCapabilitiesArray(
        state.paywall!.template['ui.capabilities'] || [],
        'personalization'
      );

      //TODO - remove conditions
    },
    updateComponentConditionals(
      state,
      action: PayloadAction<{
        idLocation: TComponentLocation;
        conditionals: TTestObject[];
        joinType: TAssertionType;
      }>
    ) {
      const { idLocation, conditionals, joinType } = action.payload;
      if (!idLocation) return;
      state.hasChanges = true;
      const component = findComponentFromLocation(
        state.paywall!.template.pages,
        idLocation
      );

      const changesWithMinSDKVersions =
        getChangeKeysForNewTestObjects(conditionals);
      const newMinSDK = getNewMinSDKVersionFromChange(
        changesWithMinSDKVersions,
        state.formFactor,
        state.minSDKVersion
      );
      if (newMinSDK !== state.minSDKVersion) {
        state.pendingSDKVersion = newMinSDK;
        state.pendingSDKChanges = changesWithMinSDKVersions;
      }

      if (joinType === 'or') {
        (component as TConditionalComponent).orAssertions = conditionals;
        (component as TConditionalComponent).assertions = undefined;
      } else {
        (component as TConditionalComponent).assertions = conditionals;
        (component as TConditionalComponent).orAssertions = undefined;
      }
    },
    addComponentConditionalAttributeAssertion(
      state,
      action: PayloadAction<{
        idLocation: TComponentLocation;
        conditionalAttributes: Omit<TConditionalComponent, 'components'> & {
          attributes: Partial<TBaseComponent>;
        };
      }>
    ) {
      const { idLocation, conditionalAttributes } = action.payload;
      if (!idLocation) return;

      state.hasChanges = true;
      const component = findComponentFromLocation(
        state.paywall!.template.pages,
        idLocation
      );
      const newConditions = [
        ...(component.conditionAttributes || []),
        conditionalAttributes,
      ];

      (component as TConditionalComponent).conditionAttributes = newConditions;
    },
    updateComponentConditionAttributes(
      state,
      action: PayloadAction<{
        idLocation: TComponentLocation;
        assertionIndex: number;
        attributes: Partial<TBaseComponent | TTextComponent>;
      }>
    ) {
      const { idLocation, assertionIndex, attributes } = action.payload;

      if (!idLocation) return;
      state.hasChanges = true;
      const component = findComponentFromLocation(
        state.paywall!.template.pages,
        idLocation
      );

      const currentAttributes = component.conditionAttributes || [];
      const newAttributeBlock: Omit<TConditionalComponent, 'components'> & {
        attributes: Partial<TBaseComponent | TTextComponent>;
      } = {
        component: 'condition',
        assertions: currentAttributes[assertionIndex].assertions,
        attributes: {
          ...currentAttributes[assertionIndex].attributes,
          ...attributes,
        },
      };
      currentAttributes.splice(assertionIndex, 1, newAttributeBlock);
    },
    updateComponentConditionAttributeAssertions(
      state,
      action: PayloadAction<{
        idLocation: TComponentLocation;
        conditionals: TTestObject[];
        groupIndex: number;
        joinType: TAssertionType;
      }>
    ) {
      const { idLocation, conditionals, groupIndex, joinType } = action.payload;
      if (!idLocation) return;
      state.hasChanges = true;
      const component = findComponentFromLocation(
        state.paywall!.template.pages,
        idLocation
      );

      const newConditions = (component.conditionAttributes || []).reduce(
        (output, group, index) => {
          if (index === groupIndex) {
            return [
              ...output,
              {
                ...group,
                assertions: joinType === 'and' ? conditionals : undefined,
                orAssertions: joinType === 'or' ? conditionals : undefined,
              },
            ];
          }
          return [...output, group];
        },
        [] as Array<
          Omit<TConditionalComponent, 'components'> & {
            attributes: Partial<TBaseComponent>;
          }
        >
      );

      const changesWithMinSDKVersions =
        getChangeKeysForNewConditions(newConditions);
      const newMinSDK = getNewMinSDKVersionFromChange(
        changesWithMinSDKVersions,
        state.formFactor,
        state.minSDKVersion
      );
      if (newMinSDK !== state.minSDKVersion) {
        state.pendingSDKVersion = newMinSDK;
        state.pendingSDKChanges = changesWithMinSDKVersions;
      }

      (component as TConditionalComponent).conditionAttributes = newConditions;
    },
    removeComponentConditionalAttributes(
      state,
      action: PayloadAction<{
        idLocation: TComponentLocation;
        groupIndex: number;
      }>
    ) {
      const { idLocation, groupIndex } = action.payload;
      if (!idLocation) return;
      state.hasChanges = true;
      const component = findComponentFromLocation(
        state.paywall!.template.pages,
        idLocation
      );

      const newConditions = (component.conditionAttributes || []).reduce(
        (output, group, index) => {
          if (index === groupIndex) {
            return output;
          }
          return [...output, group];
        },
        [] as Array<
          Omit<TConditionalComponent, 'components'> & {
            attributes: Partial<TBaseComponent>;
          }
        >
      );

      const changesWithMinSDKVersions =
        getChangeKeysForNewConditions(newConditions);
      const newMinSDK = getNewMinSDKVersionFromChange(
        changesWithMinSDKVersions,
        state.formFactor,
        state.minSDKVersion
      );
      if (newMinSDK !== state.minSDKVersion) {
        state.pendingSDKVersion = newMinSDK;
        state.pendingSDKChanges = changesWithMinSDKVersions;
      }

      (component as TConditionalComponent).conditionAttributes = newConditions;
    },
    reorderComponentConditionalAttribute(
      state,
      action: PayloadAction<{
        idLocation: TComponentLocation;
        groupIndex: number;
        moveUp: boolean;
      }>
    ) {
      const { idLocation, groupIndex, moveUp } = action.payload;
      if (!idLocation) return;
      state.hasChanges = true;
      const component = findComponentFromLocation(
        state.paywall!.template.pages,
        idLocation
      );

      const currentConditions = component.conditionAttributes || [];
      if (!currentConditions) return;
      if (moveUp && groupIndex === 0) return;
      if (!moveUp && groupIndex === currentConditions.length - 1) return;

      const newIndex = moveUp ? groupIndex - 1 : groupIndex + 1;
      component.conditionAttributes = moveInArray(
        currentConditions,
        groupIndex,
        newIndex
      );
    },
    updateComponentLoopSourceConditions(
      state,
      action: PayloadAction<{
        idLocation: TComponentLocation;
        conditionals: TTestObject[];
      }>
    ) {
      const { idLocation, conditionals } = action.payload;

      if (!idLocation) return;
      state.hasChanges = true;
      const component = findComponentFromLocation(
        state.paywall!.template.pages,
        idLocation
      );

      (component as TRepeatingList).loopSourceConditions = conditionals;
    },
    reorderComponent(
      state,
      action: PayloadAction<{
        id: string;
        idLocation: string;
        parentLocation: string;
        location: number;
        dropToGap: boolean;
      }>
    ) {
      const { id, idLocation, parentLocation, location, dropToGap } =
        action.payload;
      if (!idLocation || !parentLocation) return;

      const parsedLocation = parseLocationString(idLocation);
      const parsedParentLocation = parseLocationString(parentLocation);

      let pageSection: TComponent;
      let parentObject: TComponent;
      let indexInNewLocation: number = location || 0;

      if (dropToGap) {
        //Drop in the space between this leaf node
        const newLocation = moveUpTreeFromLocation(parsedParentLocation);
        parentObject = findComponentFromLocation(
          state.paywall!.template?.pages,
          newLocation
        );
        indexInNewLocation = findPositionBelowLeaf(parsedParentLocation);
      } else {
        //Drop into this parent object
        parentObject = findComponentFromLocation(
          state.paywall!.template?.pages,
          parsedParentLocation
        );
      }

      if (parentObject && (parentObject as TContainer).components) {
        pageSection = findComponentFromLocation(
          state.paywall!.template?.pages,
          parsedLocation,
          true
        );

        if (pageSection) {
          state.hasChanges = true;
          addToArray(
            (parentObject as TContainer).components,
            indexInNewLocation,
            pageSection
          );
        } else {
          console.warn(`couldn't find page section with id ${id}`);
        }
      } else {
        console.warn(`parent ${parentLocation} not a container`);
      }
    },
    addComponent(
      state,
      action: PayloadAction<{
        id: string;
        location: string;
        type: TAllComponentDetailValues;
        productChild: boolean;
      }>
    ) {
      const { id, location, type, productChild } = action.payload;
      if (!id) return;
      const capabilities: TTemplateCapability[] =
        state.paywall!.template['ui.capabilities'] || [];
      const parsedLocation = parseLocationString(location);
      const parentObject = findComponentFromLocation(
        state.paywall!.template?.pages,
        parsedLocation
      );

      if (!(parentObject as TContainer).components) {
        console.warn(`Location ${location} cannot have child nodes`);
        return;
      }

      state.hasChanges = true;
      const result: TNewComponentBuilderMeta = generateFullComponent(
        id,
        type,
        state.formFactor,
        state.idLocations,
        state.currentPage
      );

      const variableMap: Record<string, string> = Object.keys(
        result.variables
      ).reduce((output, key) => {
        const currentValue = result.variables[key];
        return {
          ...output,
          [key]: currentValue.value,
        };
      }, {});
      const newVars = { ...variableMap, ...state.paywall!.template.variables };
      state.paywall!.template.variables = newVars;

      if (Object.keys(result.skuVariables || {}).length > 0) {
        //Push new product variables
        if (
          state.paywall!.template['ui.productSettings'] &&
          state.paywall!.template['ui.productSettings'].variablesList
        ) {
          const skuVariableMap: TProductVariable[] = Object.keys(
            result.skuVariables
          ).reduce((output, key) => {
            const currentValue = result.skuVariables[key];
            return [
              ...output,
              {
                variable: key,
                type: 'string', //TODO
                display_name: formFieldReadableLabel(key, currentValue, false),
                value: currentValue.value,
              } as TProductVariable,
            ];
          }, [] as TProductVariable[]);
          const newSkuVars = [
            ...(state.paywall!.template['ui.productSettings'] || {})
              .variablesList,
            ...skuVariableMap,
          ];
          state.paywall!.template['ui.productSettings'].variablesList =
            newSkuVars;
        }

        //Push variables to product menu items
        for (const group in state.skuItems) {
          for (const platform in state.skuItems[group]) {
            for (const productId in state.skuItems[group][platform]) {
              const currentMenu = state.menus[group][platform];
              state.menusWithChanges.add(currentMenu.id);
              const item = state.skuItems[group][platform][productId];
              item.variables = {
                ...item.variables,
                ...Object.entries(result.skuVariables).reduce(
                  (output, [key, meta]) => {
                    return {
                      ...output,
                      [key]: meta.value,
                    };
                  },
                  {}
                ),
              };
            }
          }
        }

        //Push variables to available items
        for (const platform in state.availableItems) {
          for (const group in state.availableItems[platform]) {
            for (const productIndex in state.availableItems[platform][group]) {
              const item = state.availableItems[platform][group][productIndex];
              item.variables = {
                ...item.variables,
                ...Object.entries(result.skuVariables).reduce(
                  (output, [key, meta]) => {
                    return {
                      ...output,
                      [key]: meta.value,
                    };
                  },
                  {}
                ),
              };
            }
          }
        }
      }

      if (Object.keys(result.stateVariables || {}).length > 0) {
        //Push to initialState
        const stateVariableMap: Record<string, string> = Object.keys(
          result.stateVariables
        ).reduce((output, key) => {
          const currentValue = result.stateVariables[key];
          if (currentValue.propertyType === 'open') {
            return {
              ...output,
              openHeaderIds: [],
            };
          }
          return {
            ...output,
            [key]: currentValue.value,
          };
        }, {});
        const newVars = {
          ...stateVariableMap,
          ...state.paywall!.template.initialState,
        };
        state.paywall!.template.initialState = newVars;
      }

      if (Object.keys(result.slideVariables || {}).length > 0) {
        const slideVariableMap: Record<string, string> = Object.keys(
          result.slideVariables
        ).reduce((output, key) => {
          const currentValue = result.slideVariables[key];
          return {
            ...output,
            [key]: currentValue.value,
          };
        }, {});

        //Add new slide variable to all existing slides in initialState
        if (state.paywall!.template.initialState.slides) {
          const updatedSlideState = Object.entries(
            state.paywall!.template.initialState.slides
          ).reduce((output, [slideKey, slideSettings]) => {
            const updatedSlides = slideSettings['carouselName'].reduce(
              (output, slide) => {
                return [
                  ...output,
                  {
                    ...slide,
                    ...slideVariableMap,
                  },
                ];
              },
              [] as TCarouselSlide[]
            );

            return {
              ...output,
              [slideKey]: {
                ...slideSettings,
                carouselName: updatedSlides,
              },
            };
          }, {} as TCarouselSlidesState);
          state.paywall!.template.initialState.slides = updatedSlideState;
        } else {
          console.warn('Carousel state not found');
        }

        //Add new slide variable to template[ui.carousels].newSlide
        if (state.paywall!.template['ui.carousels']) {
          const carouselNewSlideSettings = {
            ...state.paywall!.template['ui.carousels']['carouselName'].newSlide,
            ...slideVariableMap,
          };
          state.paywall!.template['ui.carousels']['carouselName'].newSlide =
            carouselNewSlideSettings;
        } else {
          console.warn('Carousel settings not found');
        }
      }

      if (result.slideFormFields.length > 0) {
        //Add new slide form fields to template[ui.carousels].fields
        if (state.paywall!.template['ui.carousels']) {
          const newCarouselFields = [
            ...state.paywall!.template['ui.carousels']['carouselName'].fields,
            ...result.slideFormFields,
          ];
          state.paywall!.template['ui.carousels']['carouselName'].fields =
            newCarouselFields;
        } else {
          console.warn('Carousel settings not found');
        }
      }

      if (capabilities.includes('featured_product') && productChild) {
        const featuredResult = addFeaturedToComponent(result.component);

        const variableMap: Record<string, string> = Object.keys(
          featuredResult.variables
        ).reduce((output, key) => {
          const currentValue = featuredResult.variables[key];
          return {
            ...output,
            [key]: currentValue.value,
          };
        }, {});
        state.paywall!.template.variables = {
          ...variableMap,
          ...state.paywall!.template.variables,
        };
        (parentObject as TContainer).components.push(featuredResult.component);
      } else if (
        capabilities.includes('accessibility') &&
        (result.component.component === 'button' ||
          result.component.component === 'playPauseButton' ||
          result.component.component === 'volumeButton') &&
        result.component.namiComponentType !== 'productButton'
      ) {
        const screenreaderResult = addScreenreaderTextToComponent(
          result.component
        );
        const variableMap: Record<string, string> = Object.keys(
          screenreaderResult.variables
        ).reduce((output, key) => {
          const currentValue = screenreaderResult.variables[key];
          return {
            ...output,
            [key]: currentValue.value,
          };
        }, {});
        state.paywall!.template.variables = {
          ...variableMap,
          ...state.paywall!.template.variables,
        };

        (parentObject as TContainer).components.push(
          screenreaderResult.component
        );
      } else {
        (parentObject as TContainer).components.push(result.component);
      }

      const changesWithMinSDKVersions = getChangeKeyForNewComponent(type);
      const newMinSDK = getNewMinSDKVersionFromChange(
        changesWithMinSDKVersions,
        state.formFactor,
        state.minSDKVersion
      );
      if (newMinSDK !== state.minSDKVersion) {
        state.pendingSDKVersion = newMinSDK;
        state.pendingSDKChanges = changesWithMinSDKVersions;
      }

      if (type === 'playPauseButton' || type === 'volumeButton') {
        state.paywall!.template['ui.capabilities'] = addToCapabilitiesArray(
          state.paywall!.template['ui.capabilities'] || [],
          'video'
        );
      }

      if (type === 'flowButton') {
        state.paywall!.template['ui.capabilities'] = addToCapabilitiesArray(
          state.paywall!.template['ui.capabilities'] || [],
          'flow'
        );
      }
    },
    addFooter(state) {
      state.hasChanges = true;
      const result: TNewComponentBuilderMeta = generateFullComponent(
        'footer',
        'footer',
        state.formFactor,
        state.idLocations
      );

      const variableMap: Record<string, string> = Object.keys(
        result.variables
      ).reduce((output, key) => {
        const currentValue = result.variables[key];
        return {
          ...output,
          [key]: currentValue.value,
        };
      }, {});
      const newVars = { ...variableMap, ...state.paywall!.template.variables };
      state.paywall!.template.variables = newVars;

      state.paywall!.template.pages[0].footer?.push(result.component);
    },
    addHeader(state) {
      state.hasChanges = true;
      const result: TNewComponentBuilderMeta = generateFullComponent(
        'header',
        'header',
        state.formFactor,
        state.idLocations
      );

      const variableMap: Record<string, string> = Object.keys(
        result.variables
      ).reduce((output, key) => {
        const currentValue = result.variables[key];
        return {
          ...output,
          [key]: currentValue.value,
        };
      }, {});
      const newVars = { ...variableMap, ...state.paywall!.template.variables };
      state.paywall!.template.variables = newVars;

      state.paywall!.template.pages[0].header.push(
        result.component as TContainer
      );
    },
    removeComponent(
      state,
      action: PayloadAction<{
        componentId: string | null;
        parsedLocation: TComponentLocation | null;
      }>
    ) {
      const { componentId, parsedLocation } = action.payload;
      if (!componentId || !parsedLocation) return;

      state.hasChanges = true;
      findComponentFromLocation(
        state.paywall!.template?.pages,
        parsedLocation,
        true
      );
    },
    removeFooter(state) {
      state.hasChanges = true;
      state.paywall!.template.pages[0].footer = []; //TODO - handle 2nd page
    },
    removeHeader(state) {
      state.hasChanges = true;
      state.paywall!.template.pages[0].header = []; //TODO - handle 2nd page
    },
    duplicateComponent(
      state,
      action: PayloadAction<{
        component: TComponent | null;
      }>
    ) {
      const { component } = action.payload;
      if (!component || !component.id) return;
      state.hasChanges = true;
      const currentComponentLocation = parseLocationString(
        state.idLocations[component.id]
      );
      const componentParentLocation = moveUpTreeFromLocation(
        currentComponentLocation
      );
      const currentComponentLocationWithinParent =
        currentComponentLocation.pos[currentComponentLocation.pos.length - 1];
      const componentParent = findComponentFromLocation(
        state.paywall!.template.pages,
        componentParentLocation
      );

      const newComponent =
        component.namiComponentType === 'collapse'
          ? duplicateCollapseComponent(
              component,
              state.paywall!.template.variables || {},
              state.idLocations
            )
          : duplicateComponentAndChildren(
              component,
              state.paywall!.template.variables || {},
              state.idLocations
            );

      if (typeof currentComponentLocationWithinParent === 'number') {
        (componentParent as TContainer).components.splice(
          currentComponentLocationWithinParent + 1,
          0,
          newComponent.component
        );
      }

      const newVars = {
        ...state.paywall!.template.variables,
        ...newComponent.variables,
      };
      state.paywall!.template.variables = newVars;

      const newMeta = constructIdMetadata(state.paywall!.template.pages);
      state.idLocations = newMeta.idLocations;
      state.idTypeLocations = newMeta.idLocationsByType;

      state.editingComponentId = newComponent.component.id!;
      state.selectedTreeKey = newComponent.component.id!;
    },
    toggleComponentHidden(
      state,
      action: PayloadAction<{
        component: TComponent | null;
        hidden: boolean;
      }>
    ) {
      const { component, hidden } = action.payload;
      if (!component || !component.id) return;
      state.hasChanges = true;

      if (hidden) {
        const newMinSDK = getNewMinSDKVersionFromChange(
          ['hideComponent'],
          state.formFactor,
          state.minSDKVersion
        );
        if (newMinSDK !== state.minSDKVersion) {
          state.pendingSDKVersion = newMinSDK;
          state.pendingSDKChanges = ['hideComponent'];
        }
      }

      const parsedLocation = parseLocationString(
        state.idLocations[component.id]
      );
      const currentComponent = findComponentFromLocation(
        state.paywall!.template?.pages,
        parsedLocation
      );
      currentComponent.hidden = hidden;
    },
    addProductTextComponent(
      state,
      action: PayloadAction<{
        id: string;
        location: string;
        productField: string;
      }>
    ) {
      const { id, location, productField } = action.payload;
      if (!id) return;

      const parsedLocation = parseLocationString(location);
      const parentObject = findComponentFromLocation(
        state.paywall!.template?.pages,
        parsedLocation
      );

      if (!(parentObject as TContainer).components) {
        console.warn(`Location ${location} cannot have child nodes`);
        return;
      }
      state.hasChanges = true;

      const result: TNewComponentBuilderMeta = generateFullComponent(
        id,
        'productText',
        state.formFactor,
        state.idLocations
      );

      const variableMap: Record<string, string> = Object.keys(
        result.variables
      ).reduce((output, key) => {
        const currentValue = result.variables[key];
        return {
          ...output,
          [key]: currentValue.value,
        };
      }, {});
      const newVars = { ...variableMap, ...state.paywall!.template.variables };
      state.paywall!.template.variables = newVars;

      let overridenResultComponent = result.component;
      (
        overridenResultComponent as TTextComponent
      ).text = `\${sku.${productField}}`;
      (parentObject as TContainer).components.push(overridenResultComponent);
    },
    addRepeatingGridComponent(
      state,
      action: PayloadAction<{
        id: string;
        location: string;
        dataSource: string;
      }>
    ) {
      const { id, location, dataSource } = action.payload;
      if (!id) return;

      const parsedLocation = parseLocationString(location);
      const parentObject = findComponentFromLocation(
        state.paywall!.template?.pages,
        parsedLocation
      );

      if (!(parentObject as TContainer).components) {
        console.warn(`Location ${location} cannot have child nodes`);
        return;
      }
      state.hasChanges = true;

      const result: TNewComponentBuilderMeta = generateFullComponent(
        id,
        'repeatingList',
        state.formFactor,
        state.idLocations
      );

      const variableMap: Record<string, string> = Object.keys(
        result.variables
      ).reduce((output, key) => {
        const currentValue = result.variables[key];
        return {
          ...output,
          [key]: currentValue.value,
        };
      }, {});
      const newVars = { ...variableMap, ...state.paywall!.template.variables };
      state.paywall!.template.variables = newVars;

      const newMinSDK = getNewMinSDKVersionFromChange(
        ['repeatingList'],
        state.formFactor,
        state.minSDKVersion
      );
      if (newMinSDK !== state.minSDKVersion) {
        state.pendingSDKVersion = newMinSDK;
        state.pendingSDKChanges = ['repeatingList'];
      }

      let overridenResultComponent = result.component;
      (overridenResultComponent as TRepeatingList).loopSource = dataSource;
      (parentObject as TContainer).components.push(overridenResultComponent);
    },
    addRepeatingGridChild(
      state,
      action: PayloadAction<{
        id: string;
        location: string;
        type: TAllComponentDetailValues;
        repeatingGridParent: TComponent | null;
        schemaField: string;
      }>
    ) {
      const { id, location, type, repeatingGridParent, schemaField } =
        action.payload;
      if (!id || !repeatingGridParent) return;

      const parsedLocation = parseLocationString(location);
      const parentObject = findComponentFromLocation(
        state.paywall!.template?.pages,
        parsedLocation
      );

      if (!(parentObject as TContainer).components) {
        console.warn(`Location ${location} cannot have child nodes`);
        return;
      }

      if (
        !repeatingGridParent ||
        !(repeatingGridParent as TRepeatingList).loopSource
      ) {
        console.warn(`Can't find parent repeating grid for ${location}`);
        return;
      }

      state.hasChanges = true;
      const parentDataSource = deconstructVariable(
        (repeatingGridParent as TRepeatingList).loopSource as string
      );

      const result: TNewComponentBuilderMeta = generateFullComponent(
        id,
        type,
        state.formFactor,
        state.idLocations
      );

      const variableMap: Record<string, string> = Object.keys(
        result.variables
      ).reduce((output, key) => {
        const currentValue = result.variables[key];
        return {
          ...output,
          [key]: currentValue.value,
        };
      }, {});
      const newVars = { ...variableMap, ...state.paywall!.template.variables };
      state.paywall!.template.variables = newVars;

      let overridenResultComponent = result.component;
      if (type === 'repeatingTextSource') {
        (overridenResultComponent as TTextComponent).text = schemaField.replace(
          parentDataSource,
          (repeatingGridParent as TRepeatingList).loopVariable as string
        );
      } else if (type === 'repeatingImageSource') {
        (overridenResultComponent as TImageComponent).url = schemaField.replace(
          parentDataSource,
          (repeatingGridParent as TRepeatingList).loopVariable as string
        );
      }
      (parentObject as TContainer).components.push(overridenResultComponent);
    },
    updateComponentTitle(
      state,
      action: PayloadAction<{
        location: TComponentLocation;
        value: any;
      }>
    ) {
      const { location, value } = action.payload;
      const object = findComponentFromLocation(
        state.paywall!.template?.pages,
        location
      );

      if (!object) return;
      state.hasChanges = true;
      object.title = value;
    },
    updateComponentProductText(
      state,
      action: PayloadAction<{
        location: TComponentLocation;
        value: any;
      }>
    ) {
      const { location, value } = action.payload;
      const object = findComponentFromLocation(
        state.paywall!.template?.pages,
        location
      );

      if (!object) return;
      state.hasChanges = true;

      (object as TTextComponent).text = value;
    },
    updateComponentCarouselProperty(
      state,
      action: PayloadAction<{
        location: TComponentLocation;
        value: any;
        property: string;
      }>
    ) {
      const { location, value, property } = action.payload;
      const object = findComponentFromLocation(
        state.paywall!.template?.pages,
        location
      );

      if (!object) return;
      state.hasChanges = true;

      (object as any)[property] = value;
    },
    addConditionalAttribute(
      state,
      action: PayloadAction<{
        location: TComponentLocation;
        index: number;
        id: string;
        propertyType: TNonProtectedComponentProperty;
        componentType: TAllComponentDetailValues;
      }>
    ) {
      const { location, index, id, propertyType, componentType } =
        action.payload;
      let attributes = {};

      //Handle adding conditional product display field
      if (componentType === 'productText' && propertyType === 'text') {
        //TODO - more types?

        if (
          state.paywall!.template['ui.productSettings'] &&
          state.paywall!.template['ui.productSettings'].variablesList
        ) {
          state.hasChanges = true;
          const newVariable =
            state.paywall!.template['ui.productSettings'].variablesList[0]
              .variable;

          attributes = {
            [propertyType]: `\${sku.${newVariable}}`,
          };
        }
      } else if (
        componentType === 'productButton' &&
        propertyType === 'screenreaderText'
      ) {
        if (
          state.paywall!.template['ui.productSettings'] &&
          state.paywall!.template['ui.productSettings'].variablesList
        ) {
          state.hasChanges = true;
          attributes = {
            [propertyType]: `\${sku.screenreaderText}`,
          };
        }
      } else if (
        (componentType === 'image' && propertyType === 'url') ||
        (componentType === 'videoUrl' && propertyType === 'fallbackImage')
      ) {
        state.hasChanges = true;
        attributes = {
          [propertyType]: `\${media.default}`,
        };
      } else if (
        (componentType === 'carouselTitleText' ||
          componentType === 'carouselBodyText') &&
        propertyType === 'text'
      ) {
        state.hasChanges = true;
        attributes = {
          [propertyType]: `\${slide.carouselTitleText}`, //TODO
        };
      } else if (
        (componentType === 'slide' || componentType === 'carouselImage') &&
        ['fillImage', 'url'].includes(propertyType)
      ) {
        state.hasChanges = true;
        attributes = {
          [propertyType]: `\${slide.carouselSlideFillImage}`, //TODO
        };
      } else {
        //Add condition variable
        const conditionVars: { key: string; meta: TVariableMeta } | null =
          generateNewComponentVariable(
            id,
            componentType,
            propertyType,
            index,
            true
          );

        if (!conditionVars) return;
        state.hasChanges = true;

        const newVars = {
          [conditionVars.key]: conditionVars.meta.value,
          ...state.paywall!.template.variables,
        };
        state.paywall!.template.variables = newVars;

        attributes = {
          [propertyType]: `\${${conditionVars.meta.namespace || 'var'}.${
            conditionVars.key
          }}`,
        };
      }

      //Push to condition attribute block
      const component = findComponentFromLocation(
        state.paywall!.template.pages,
        location
      );

      const currentAttributes = component.conditionAttributes || [];
      const newAttributeBlock: Omit<TConditionalComponent, 'components'> & {
        attributes: Partial<TBaseComponent | TTextComponent>;
      } = {
        component: 'condition',
        assertions: currentAttributes[index].assertions,
        attributes: {
          ...currentAttributes[index].attributes,
          ...attributes,
        },
        orAssertions: currentAttributes[index].orAssertions,
      };
      currentAttributes.splice(index, 1, newAttributeBlock);
    },
    addNewProperty(
      state,
      action: PayloadAction<{
        location: TComponentLocation;
        id: string;
        propertyType: TNonProtectedComponentProperty;
        componentType: TAllComponentDetailValues;
      }>
    ) {
      const { location, id, propertyType, componentType } = action.payload;

      const changesWithMinSDKVersions =
        getChangeKeysForNewVariable(propertyType);
      const newMinSDK = getNewMinSDKVersionFromChange(
        changesWithMinSDKVersions,
        state.formFactor,
        state.minSDKVersion
      );
      if (newMinSDK !== state.minSDKVersion) {
        state.pendingSDKVersion = newMinSDK;
        state.pendingSDKChanges = changesWithMinSDKVersions;
      }

      const conditionVars: { key: string; meta: TVariableMeta } | null =
        generateNewComponentVariable(id, componentType, propertyType, 0, false);

      if (!conditionVars) return;
      state.hasChanges = true;

      const newVars = {
        [conditionVars.key]: conditionVars.meta.value,
        ...state.paywall!.template.variables,
      };
      state.paywall!.template.variables = newVars;

      //Push to component
      const component = findComponentFromLocation(
        state.paywall!.template.pages,
        location
      );

      if (component) {
        (component as any)[propertyType] = `\${${
          conditionVars.meta.namespace || 'var'
        }.${conditionVars.key}}`;
      }
    },
    removeProperty(
      state,
      action: PayloadAction<{
        location?: TComponentLocation;
        propertyType: TNonProtectedComponentProperty;
      }>
    ) {
      const { location, propertyType } = action.payload;
      if (!location) return;
      const component = findComponentFromLocation(
        state.paywall!.template.pages,
        location
      );
      state.hasChanges = true;

      delete (component as any)[propertyType];
    },
    updateButtonOnTap(
      state,
      action: PayloadAction<{
        id: string | undefined;
        value: string;
        variable: string | undefined;
      }>
    ) {
      const { id, value, variable } = action.payload;
      const onTapMap: Record<string, TAllComponentDetailValues> = {
        namiClosePaywall: 'closeButton',
        namiRestorePurchases: 'restoreButton',
        namiSignIn: 'loginButton',
        namiFlowSkipStep: 'flowButton',
        namiFlowPreviousStep: 'flowButton',
        namiFlowConfirmStep: 'flowButton',
      };
      const functionValue = {
        function: value,
      };

      if (id && variable) {
        state.hasChanges = true;
        //Update variable value
        const [domain, ...path] = variable.split('.');

        if (domain === 'var') {
          if (
            (state.paywall!.template.variables || {})[path[path.length - 1]] !==
            functionValue
          ) {
            setValue(state.paywall!.template.variables, path, functionValue);
          }
        }

        //Update component type
        const buttonLocation = state.idLocations[id];
        const buttonComponent = findComponentFromLocation(
          state.paywall!.template.pages,
          parseLocationString(buttonLocation)
        );

        if (buttonComponent && onTapMap[value]) {
          buttonComponent.namiComponentType = onTapMap[value];
        }
      }
    },
    addSkuVariable(
      state,
      action: PayloadAction<{
        name: string;
        value: any;
        type: TProductVariableType;
      }>
    ) {
      const { name, value, type } = action.payload;
      const newName = toCamelCase(name);
      state.hasChanges = true;
      if (
        state.paywall!.template['ui.productSettings'] &&
        state.paywall!.template['ui.productSettings'].variablesList
      ) {
        const newSkuVars = [
          ...(state.paywall!.template['ui.productSettings'] || {})
            .variablesList,
          {
            display_name: name,
            type: type,
            variable: newName,
            value: value,
          },
        ];
        state.paywall!.template['ui.productSettings'].variablesList =
          newSkuVars;
      }

      //Push variable to Added products
      for (const group in state.skuItems) {
        for (const platform in state.skuItems[group]) {
          for (const productId in state.skuItems[group][platform]) {
            const currentMenu = state.menus[group][platform];
            state.menusWithChanges.add(currentMenu.id);
            const item = state.skuItems[group][platform][productId];
            item.variables = {
              ...item.variables,
              [newName]: value,
            };
          }
        }
      }

      //Push variable to Available products
      for (const platform in state.availableItems) {
        for (const group in state.availableItems[platform]) {
          for (const productIndex in state.availableItems[platform][group]) {
            const item = state.availableItems[platform][group][productIndex];
            item.variables = {
              ...item.variables,
              [newName]: value,
            };
          }
        }
      }
    },
    updateSkuVariableDefault(
      state,
      action: PayloadAction<{
        variable: string;
        newValue: string;
      }>
    ) {
      const { variable, newValue } = action.payload;
      if (!state.paywall?.template['ui.productSettings']) return;

      state.hasChanges = true;
      const productVariables =
        state.paywall?.template['ui.productSettings']?.variablesList || [];
      const newProductVariables = productVariables.reduce(
        (output, currentVariable) => {
          if (currentVariable.variable === variable) {
            return [
              ...output,
              {
                ...currentVariable,
                value: newValue,
              } as TProductVariable,
            ];
          }
          return [...output, currentVariable];
        },
        [] as TProductVariable[]
      );

      state.paywall.template['ui.productSettings'].variablesList =
        newProductVariables;

      //Update default variable for Available products
      for (const platform in state.availableItems) {
        for (const group in state.availableItems[platform]) {
          for (const productIndex in state.availableItems[platform][group]) {
            const item = state.availableItems[platform][group][productIndex];
            item.variables = {
              ...item.variables,
              [variable]: newValue,
            };
          }
        }
      }
    },
    updateSkuVariableLabel(
      state,
      action: PayloadAction<{
        variable: string;
        newValue: string;
      }>
    ) {
      const { variable, newValue } = action.payload;
      if (!state.paywall?.template['ui.productSettings']) return;

      state.hasChanges = true;
      const productVariables =
        state.paywall?.template['ui.productSettings']?.variablesList || [];
      const newProductVariables = productVariables.reduce(
        (output, currentVariable) => {
          if (currentVariable.variable === variable) {
            return [
              ...output,
              {
                ...currentVariable,
                display_name: newValue,
              } as TProductVariable,
            ];
          }
          return [...output, currentVariable];
        },
        [] as TProductVariable[]
      );

      state.paywall.template['ui.productSettings'].variablesList =
        newProductVariables;
    },
    removeSkuVariable(state, { payload: variableName }: PayloadAction<string>) {
      state.hasChanges = true;
      if (
        state.paywall!.template['ui.productSettings'] &&
        state.paywall!.template['ui.productSettings'].variablesList
      ) {
        const newSkuVars = (
          state.paywall!.template['ui.productSettings'] || {}
        ).variablesList.reduce((output, variable) => {
          if (variable.variable === variableName) return output;
          return [...output, variable];
        }, [] as TProductVariable[]);

        state.paywall!.template['ui.productSettings'].variablesList =
          newSkuVars;
      }

      //Remove variable from Added products
      for (const group in state.skuItems) {
        for (const platform in state.skuItems[group]) {
          for (const productId in state.skuItems[group][platform]) {
            const currentMenu = state.menus[group][platform];
            state.menusWithChanges.add(currentMenu.id);
            const item = state.skuItems[group][platform][productId];
            const newItemVariables = Object.entries(item.variables).reduce(
              (output, [currentVariable, currentVariableValue]) => {
                if (currentVariable === variableName) return output;
                return {
                  ...output,
                  [currentVariable]: currentVariableValue,
                };
              },
              {}
            );
            item.variables = newItemVariables;
          }
        }
      }

      //Remove variable from Available products
      for (const platform in state.availableItems) {
        for (const group in state.availableItems[platform]) {
          for (const productIndex in state.availableItems[platform][group]) {
            const item = state.availableItems[platform][group][productIndex];
            const newItemVariables = Object.entries(item.variables).reduce(
              (output, [currentVariable, currentVariableValue]) => {
                if (currentVariable === variableName) return output;
                return {
                  ...output,
                  [currentVariable]: currentVariableValue,
                };
              },
              {}
            );
            item.variables = newItemVariables;
          }
        }
      }
    },
    updateCarouselFieldLabel(
      state,
      action: PayloadAction<{
        variable: string;
        newValue: string;
      }>
    ) {
      const { variable, newValue } = action.payload;
      if (
        !state.paywall?.template['ui.carousels'] ||
        !state.paywall?.template['ui.carousels']['carouselName']
      )
        return;

      state.hasChanges = true;

      const newCarouselFields = state.paywall.template['ui.carousels'][
        'carouselName'
      ].fields.reduce((output, field) => {
        if (field.variable === variable) {
          return [
            ...output,
            {
              ...field,
              label: newValue,
            },
          ];
        }
        return [...output, field];
      }, [] as TFieldSettings[]);
      state.paywall.template['ui.carousels']['carouselName'].fields =
        newCarouselFields;
    },
    updateLaunchCustomAttributes(
      state,
      action: PayloadAction<{
        componentAttributes: {};
      }>
    ) {
      const { componentAttributes } = action.payload;
      state.hasChanges = true;
      const newAttributes = {
        ...state.paywall!.template['ui.launchCustomAttributes'],
        ...componentAttributes,
      };
      state.paywall!.template['ui.launchCustomAttributes'] = newAttributes;
    },
    addSafeAreaMarginToComponent(
      state,
      action: PayloadAction<{
        location: TComponentLocation;
      }>
    ) {
      const { location } = action.payload;

      const component = findComponentFromLocation(
        state.paywall!.template.pages,
        location
      );

      if (component) {
        state.hasChanges = true;
        (component as any).topMargin = '${state.safeAreaTop}';
      }
    },
    toggleCollapseOpen(
      state,
      action: PayloadAction<{
        value: any;
        variable: string;
      }>
    ) {
      const { value, variable } = action.payload;

      if (value) {
        const currentOpenHeaderIds =
          state.paywall?.template.initialState.openHeaderIds || [];
        currentOpenHeaderIds.push(variable);
        state.paywall!.template.initialState.openHeaderIds =
          currentOpenHeaderIds;
      } else {
        const currentOpenHeaderIds = (
          state.paywall?.template.initialState.openHeaderIds || []
        ).reduce((output, value) => {
          if (value === variable) return output;
          return [...output, value];
        }, [] as string[]);
        state.paywall!.template.initialState.openHeaderIds =
          currentOpenHeaderIds;
      }
    },
  },
});

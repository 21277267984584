import React, { useMemo, useState } from 'react';

import {
  CloseOutlined,
  EditOutlined,
  MenuOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  List,
  Row,
  Space,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import { ReactSortable } from 'react-sortablejs';
import { createSelector } from 'reselect';
import { SelectableItemType } from 'src/api/types/paywall.types';
import { TTemplateFormFactor } from 'src/api/types/paywallTemplate.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import TextActionButton from 'src/components/ActionButtons/TextActionButton';
import { namiAliceGray, namiDarkGray } from 'src/variables';
import styled, { css } from 'styled-components';

import SearchInput from '../../../../../components/SearchInput';
import { useActions, useAppSelector } from '../../../../../hooks/redux.hooks';
import store, { RootState } from '../../../../../redux';
import PaywallBuilderSlice from '../../../../../redux/PaywallBuilderSlice';

type TPatchedItem = Omit<SelectableItemType, 'selected'> & {
  isSelected: boolean;
};

const GROUP_NAME = 'sku-list';

const TextFeatured = styled(Typography.Text)`
  display: block;
  font-size: 11px;
  margin-bottom: 0.25rem;

  > span {
    margin-right: 0.4rem;
  }
`;

const TextSkuID = styled(Typography.Text)`
  font-size: 10px;
  font-family: 'Overpass Mono', 'SFMono-Regular', 'Consolas', 'Liberation Mono',
    Menlo, Courier, monospace;
  color: ${namiDarkGray};
`;

const AddedListItem = styled(List.Item)<{ $active: boolean }>`
  justify-content: start;
  cursor: grab;
  padding: 12px !important;
  ${({ $active }) => {
    if ($active) {
      return css`
        background-color: ${namiAliceGray};
      `;
    }
    return css``;
  }}
`;

const AddedListItemMeta = styled(List.Item.Meta)`
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-list-item-meta-title {
    margin-bottom: 2px;
  }

  .ant-list-item-meta-avatar {
    margin-right: 0px;
  }
`;

const AvailableListItem = styled(List.Item)`
  padding: 12px !important;
`;

const ClickableCol = styled(Col)<{
  $canAddToPaywall: boolean;
}>`
  ${({ $canAddToPaywall }) => {
    if ($canAddToPaywall) return 'cursor: pointer';
    return 'cursor: not-allowed';
  }}
`;

const selectedProductsSelector = createSelector(
  [
    ({ paywallBuilder: { skuItems, platformId, groupId } }: RootState) =>
      (platformId && groupId && skuItems[groupId][platformId]) || null,
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall!.template['ui.hasFeaturedFlag'],
    ({ paywallBuilder: { hasSelectedFlag } }: RootState) => hasSelectedFlag,
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall!.template['ui.formFactors'],
    ({ paywallBuilder: { availableItems, platformId, groupId } }: RootState) =>
      (platformId && groupId && availableItems[platformId][groupId]) || [],
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall!.template['ui.maxSkus'],
    ({ paywallBuilder: { editingProductId } }: RootState) => editingProductId,
  ],
  (
    items,
    hasFeaturedFlag,
    hasSelectedFlag,
    formFactors,
    availableItems,
    maxSkus,
    editingProductId
  ) => ({
    list: items ? Object.values(items) : [],
    hasFeaturedFlag,
    hasSelectedFlag,
    formFactors,
    availableList: availableItems ? Object.values(availableItems) : [],
    maxSkus,
    editingProductId,
  })
);

export function SelectedProductList() {
  const actions = useActions(PaywallBuilderSlice.actions);
  const {
    list,
    hasFeaturedFlag,
    hasSelectedFlag,
    formFactors,
    availableList,
    editingProductId,
  } = useAppSelector(selectedProductsSelector);
  const isTelevisionFormFactor = isTVFormFactor(formFactors || []);

  return (
    <List bordered className="intercom-addedProducts">
      <ReactSortable
        list={moveSelectedFlag(list)}
        setList={(newData) => {
          if (containSameIds(newData, list)) return;
          actions.updateProductItems(revertSelectedFlag(newData));
        }}
        group={GROUP_NAME}
        style={{ height: '28vh', overflowY: 'hidden' }}
      >
        {list.map((item: SelectableItemType) => (
          <AddedListItem key={item.id} $active={item.id === editingProductId}>
            <AddedListItemMeta
              avatar={<MenuOutlined style={{ marginRight: 15 }} />}
              title={
                <Row gutter={0} style={{ width: '100%' }}>
                  <Col span={18}>
                    <Tooltip
                      title={
                        <>
                          <p>Name: {item.name}</p>
                          <p>ID: {item.sku_ref_id}</p>
                        </>
                      }
                      mouseEnterDelay={0.8}
                      align={{ offset: [0, 5] }}
                    >
                      <TextActionButton
                        type="text"
                        size="small"
                        style={{
                          paddingLeft: 0,
                          fontWeight: 500,
                          fontSize: 13,
                          maxWidth: 170,
                        }}
                        onClick={() => {
                          actions.setEditingComponentId(null);
                          actions.setEditingProductId(item.id);
                        }}
                      >
                        <Typography.Text ellipsis={true}>
                          {item.name}
                        </Typography.Text>
                      </TextActionButton>
                    </Tooltip>
                  </Col>
                  <Col flex="auto">
                    <Tooltip title="Edit Display" mouseEnterDelay={0.4}>
                      <IconActionButton
                        type="text"
                        size="small"
                        icon={<EditOutlined style={{ fontSize: '13px' }} />}
                        onClick={() => {
                          actions.setEditingComponentId(null);
                          actions.setEditingProductId(item.id);
                        }}
                      />
                    </Tooltip>
                  </Col>
                  <Col flex="auto">
                    <Tooltip
                      title="Remove Product from Paywall"
                      mouseEnterDelay={0.4}
                      placement="topRight"
                    >
                      <IconActionButton
                        type="text"
                        size="small"
                        icon={<CloseOutlined style={{ fontSize: '13px' }} />}
                        onClick={() => {
                          actions.setEditingProductId(null);
                          actions.updateProductItems(getListWithoutItem(item));
                          actions.updateAvailableItems([
                            item,
                            ...availableList,
                          ]);
                        }}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              }
              description={
                hasFeaturedFlag || hasSelectedFlag ? (
                  <Space direction="horizontal" size={10}>
                    {hasFeaturedFlag && (
                      <TextFeatured>
                        <span>
                          {isTelevisionFormFactor ? 'Focused:' : 'Featured:'}
                        </span>
                        <Switch
                          onChange={() => actions.toggleFeaturedFlag(item.id)}
                          size="small"
                          checked={item.featured}
                        />
                      </TextFeatured>
                    )}
                    {hasSelectedFlag && (
                      <TextFeatured>
                        <span>Selected:</span>
                        <Switch
                          onChange={() => actions.setSelectedItem(item.id)}
                          size="small"
                          checked={item.selected}
                        />
                      </TextFeatured>
                    )}
                  </Space>
                ) : undefined
              }
            />
          </AddedListItem>
        ))}
      </ReactSortable>
    </List>
  );

  function isTVFormFactor(formFactors: TTemplateFormFactor[]): boolean {
    return (
      formFactors.length > 0 && formFactors[0].form_factor === 'television'
    );
  }
}

export function AvailableProductList() {
  const [filter, setFilter] = useState('');
  const actions = useActions(PaywallBuilderSlice.actions);
  const { list, availableList, maxSkus } = useAppSelector(
    selectedProductsSelector
  );

  const filteredList = useMemo(() => {
    return availableList.filter((item) => {
      if (!filter) return true;
      return [item.sku_ref_id, item.name].some((value) =>
        value.toLowerCase().includes(filter.toLowerCase())
      );
    });
  }, [availableList, filter]);

  const canAddToPaywall = useMemo(() => {
    return !maxSkus || list.length < maxSkus;
  }, [list.length, maxSkus]);

  return (
    <>
      <SearchInput
        style={{ marginBottom: 12, marginTop: 12 }}
        placeholder="Search by name or SKU id"
        value={filter}
        onChange={setFilter}
      />
      <List
        bordered
        className="intercom-availableProducts"
        style={{ height: '27vh', overflowY: 'scroll' }}
      >
        {filteredList.map((item) => (
          <AvailableListItem key={item.id}>
            <Row style={{ width: '100%' }}>
              <ClickableCol
                $canAddToPaywall={canAddToPaywall}
                span={18}
                onClick={() => addToPaywall(item)}
              >
                <Typography.Text>
                  <span>{item.name}</span>
                  <TextSkuID>
                    <div>{item.sku_ref_id}</div>
                  </TextSkuID>
                </Typography.Text>
              </ClickableCol>
              <Col span={6} style={{ float: 'right' }}>
                <Button
                  type="text"
                  disabled={!canAddToPaywall}
                  icon={<PlusOutlined />}
                  style={{ float: 'right' }}
                  onClick={() => addToPaywall(item)}
                />
              </Col>
            </Row>
          </AvailableListItem>
        ))}
      </List>
    </>
  );

  function addToPaywall(item: SelectableItemType) {
    if (canAddToPaywall) {
      actions.updateProductItems([...list, item]);
      actions.removeFromAvailableItems(item);
    }
  }
}

function getListWithoutItem(item: SelectableItemType): SelectableItemType[] {
  const state = store.getState().paywallBuilder;
  let items = { ...state.skuItems[state.groupId!][state.platformId!] };
  delete items[item.id];
  return Object.values(items);
}

function containSameIds(
  list1: Array<TPatchedItem | SelectableItemType>,
  list2: Array<TPatchedItem | SelectableItemType>
): boolean {
  if (list1.length !== list2.length) return false;
  for (let i = 0; i < list1.length; i++) {
    if (list1[i].id !== list2[i].id) return false;
  }
  return true;
}

function moveSelectedFlag(items: SelectableItemType[]): TPatchedItem[] {
  return items.map(({ selected, ...item }) => ({
    ...item,
    isSelected: selected,
  }));
}

function revertSelectedFlag(items: TPatchedItem[]): SelectableItemType[] {
  return items.map(({ isSelected, ...item }) => ({
    ...item,
    selected: isSelected,
  }));
}

/* eslint-disable no-template-curly-in-string */
import { TDevice } from 'src/api/types/paywall.types';
import {
  TAllComponentDetailValues,
  TAllContainerDetailValues,
  TBaseComponent,
  TButtonContainer,
  TCollapseContainer,
  TComponent,
  TComponentLocation,
  TComponentLocationMetadata,
  TConditionalComponent,
  TContainer,
  TContainerComponentDetailValues,
  TContainerLikeComponent,
  TEditComponentBuilderMeta,
  TField,
  TFieldSettings,
  TFormSection,
  TIdLocations,
  TIdTypeLocation,
  TNewComponentBuilderMeta,
  TPaywallPage,
  TPlayPauseButtonComponent,
  TProductVariable,
  TSymbolComponent,
  TTemplateCapability,
  TTestObject,
  TTextComponent,
  TTextListComponent,
  TVariableNamespace,
  TVariablePattern,
  TVolumeControlComponent,
} from 'src/api/types/paywallTemplate.types';
import {
  createLocationStringFromParsedObject,
  deconstructVariable,
  findComponentFromLocation,
  generateNextIdForType,
  getProductGroupIndexFromId,
  incrementVariable,
  moveUpTreeFromLocation,
  parseLocationString,
  propertyValueIsVariable,
} from 'src/utils/paywall';
import { capitalizeFirstCharacter, toReadable } from 'src/utils/string';

export function generateFullComponent(
  id: string,
  type: TAllComponentDetailValues,
  device: TDevice,
  idsMap: { [key: string]: string },
  page: string = 'page1',
  parentType?: TAllComponentDetailValues
): TNewComponentBuilderMeta {
  const meta = TComponentDetailValueMap[type];
  let allVariables = generateComponentVariables(id, type, device, parentType);

  let componentVariables: { [key: string]: TVariableMeta } = {};
  let skuVariables: { [key: string]: TVariableMeta } = {};
  let slideVariables: { [key: string]: TVariableMeta } = {};
  let stateVariables: { [key: string]: TVariableMeta } = {};
  let topComponent = generateComponent(id, type, allVariables, device);

  if (meta.topComponentTransform)
    topComponent = meta.topComponentTransform(topComponent, page);

  if (type === 'bulletList' || type === 'carouselBulletList') {
    //Handle bullet lists specially
    const leaf = meta.children[0];
    const newId = `${id}${capitalizeFirstCharacter(leaf)}`;
    const leafVariables = generateComponentVariables(newId, leaf, device);
    allVariables = { ...allVariables, ...leafVariables };
    topComponent = {
      ...(topComponent as TTextListComponent),
      bulletComponent: generateComponent(
        newId,
        leaf,
        leafVariables,
        device
      ) as TSymbolComponent,
    };
  } else if (type === 'volumeButton') {
    const leaf = meta.children[0];
    const volumeIconVariables = generateComponentVariables(
      'muteIcon',
      leaf,
      device,
      type
    );
    const volumeIcon = generateComponent(
      'muteIcon',
      leaf,
      volumeIconVariables,
      device
    );

    const mutedIconVariables = generateComponentVariables(
      'volumeOnIcon',
      leaf,
      device,
      type
    );
    const newMutedIconVariables = Object.entries(mutedIconVariables).reduce(
      (output, [key, variable]) => {
        if (variable.propertyType === 'name') {
          return {
            ...output,
            [key]: {
              ...variable,
              value: '${icon.soundFilled}',
            },
          };
        }
        return {
          ...output,
          [key]: variable,
        };
      },
      {} as { [key: string]: TVariableMeta }
    );
    const mutedIcon = generateComponent(
      'volumeOnIcon',
      leaf,
      newMutedIconVariables,
      device
    );
    (topComponent as TVolumeControlComponent).mutedComponents = [mutedIcon];
    (topComponent as TVolumeControlComponent).volumeComponents = [volumeIcon];
    allVariables = {
      ...allVariables,
      ...volumeIconVariables,
      ...newMutedIconVariables,
    };
  } else if (type === 'playPauseButton') {
    const leaf = meta.children[0];
    const playingIconVariables = generateComponentVariables(
      'pauseIcon',
      leaf,
      device,
      type
    );
    const playingIcon = generateComponent(
      'pauseIcon',
      leaf,
      playingIconVariables,
      device
    );

    const pausedIconVariables = generateComponentVariables(
      'playIcon',
      leaf,
      device,
      type
    );
    const newPausedIconVariables = Object.entries(pausedIconVariables).reduce(
      (output, [key, variable]) => {
        if (variable.propertyType === 'name') {
          return {
            ...output,
            [key]: {
              ...variable,
              value: '${icon.play}',
            },
          };
        }
        return {
          ...output,
          [key]: variable,
        };
      },
      {} as { [key: string]: TVariableMeta }
    );
    const pausedIcon = generateComponent(
      'playIcon',
      leaf,
      pausedIconVariables,
      device
    );
    (topComponent as TPlayPauseButtonComponent).playingComponents = [
      playingIcon,
    ];
    (topComponent as TPlayPauseButtonComponent).pausedComponents = [pausedIcon];
    allVariables = {
      ...allVariables,
      ...playingIconVariables,
      ...newPausedIconVariables,
    };
  } else if (type === 'condition') {
    (topComponent as TConditionalComponent).assertions = [];
    (topComponent as TConditionalComponent).components = [];
  } else if (type === 'carousel') {
    meta.children.forEach((child) => {
      const childId = `${id}${capitalizeFirstCharacter(child)}`;
      const childMeta = TComponentDetailValueMap[child];
      const childVariables = generateComponentVariables(
        childId,
        child,
        device,
        type
      );
      let childComponent = generateComponent(
        childId,
        child,
        childVariables,
        device
      );
      allVariables = { ...allVariables, ...childVariables };

      if (childMeta.children.length) {
        childMeta.children.forEach((grandChild) => {
          const grandChildId = `${id}${capitalizeFirstCharacter(grandChild)}`;

          const grandChildVariables = generateComponentVariables(
            grandChildId,
            grandChild,
            device,
            child
          );
          const grandchildComponent = generateComponent(
            grandChildId,
            grandChild,
            grandChildVariables,
            device
          );
          allVariables = { ...allVariables, ...grandChildVariables };
          childComponent = {
            ...(childComponent as TContainerLikeComponent),
            components: [
              ...(childComponent as TContainerLikeComponent).components,
              grandchildComponent,
            ],
          };
        });
      }

      topComponent = {
        ...(topComponent as TContainerLikeComponent),
        components: [childComponent],
      };
    });
  } else if (type === 'productGroupToggle') {
    const leaf = meta.children[0];
    const leafVariables = generateComponentVariables(
      'toggleItem',
      leaf,
      device,
      type
    );
    const item1 = {
      ...generateComponent('toggleItem', leaf, leafVariables, device),
      id: '${state.groups.0.id}',
    };
    const item2 = {
      ...item1,
      id: '${state.groups.1.id}',
      text: '${state.groups.1.displayName}',
    };

    allVariables = { ...allVariables, ...leafVariables };
    topComponent = {
      ...(topComponent as TContainerLikeComponent),
      components: [item1, item2],
    };
  } else if (type === 'footer') {
    meta.children.forEach((leaf) => {
      const newId = `${id}${capitalizeFirstCharacter(leaf)}`;
      const leafVariables = generateComponentVariables(
        newId,
        leaf,
        device,
        type
      );
      allVariables = { ...allVariables, ...leafVariables };
      topComponent = {
        ...(topComponent as TContainerLikeComponent),
        components: [generateComponent(newId, leaf, leafVariables, device)],
      };
    });
  } else if (type === 'customList') {
    if (meta.children.length) {
      const child = meta.children[0];
      const childMeta = TComponentDetailValueMap[child];
      let newComponents: TComponent[] = [];

      const currentIdsMap = idsMap;

      //Create 2 list item placeholders
      for (let i = 0; i < 2; i++) {
        const currentId = generateNextIdForType('listItem', currentIdsMap);
        currentIdsMap[currentId] = 'foo';
        const itemVariables = generateComponentVariables(
          currentId,
          child,
          device,
          type
        );
        let currentItem: TComponent = {
          ...generateComponent(currentId, child, itemVariables, device),
        };
        let allGrandChildVariables = {};

        if (childMeta.children.length) {
          childMeta.children.forEach((grandChild) => {
            const grandChildId = `${currentId}${capitalizeFirstCharacter(
              grandChild
            )}`;
            let grandChildVariables = generateComponentVariables(
              grandChildId,
              grandChild,
              device,
              child
            );

            //Provide different item values for the 2nd item to demonstrate flexibility
            if (i > 0) {
              grandChildVariables[`${grandChildId}IconIcon`] = {
                value: '${icon.smile}',
                propertyType: 'name',
                idSuffix: 'Icon',
              } as TVariableMeta;
              grandChildVariables[`${grandChildId}BodyTextText`] = {
                idSuffix: 'Text',
                propertyType: 'text',
                value: 'This is another list item',
              } as TVariableMeta;
            }

            const grandchildComponent = generateComponent(
              grandChildId,
              grandChild,
              grandChildVariables,
              device
            );

            allGrandChildVariables = {
              ...allGrandChildVariables,
              ...grandChildVariables,
            };
            currentItem = {
              ...(currentItem as TContainerLikeComponent),
              components: [
                ...(currentItem as TContainerLikeComponent).components,
                grandchildComponent,
              ],
            };
          });
        }

        allVariables = {
          ...allVariables,
          ...itemVariables,
          ...allGrandChildVariables,
        };
        newComponents.push(currentItem);
      }

      topComponent = {
        ...(topComponent as TContainerLikeComponent),
        components: newComponents,
      };
    }
  } else if (type === 'collapse') {
    //Generate header component
    const header = generateFullComponent(
      generateNextIdForType('collapseHeader', idsMap),
      'collapseHeader',
      device,
      idsMap,
      page
    );

    //Apply round border conditionals to header
    const newBordersVariable = `${deconstructVariable(
      header.component.id || 'collapseHeader',
      false,
      true
    )}_conditional0`;

    header.component.conditionAttributes = [
      {
        component: 'condition',
        assertions: [
          {
            value: '${state.openHeaderIds}',
            expected: id,
            operator: 'contains',
          },
        ],
        attributes: {
          roundBorders: `\${var.${newBordersVariable}}`,
        },
      },
    ];

    allVariables = {
      ...allVariables,
      [newBordersVariable]: {
        value: ['upperLeft', 'upperRight'],
        propertyType: 'roundBorders',
      },
    };

    const headerIcon = (header.component as TContainer).components.find(
      (value) => {
        return value.namiComponentType === 'icon';
      }
    );

    if (headerIcon) {
      //Apply conditionals to header icon
      const currentIconName = (headerIcon as TSymbolComponent).name;
      const newIconVariable = `${deconstructVariable(
        currentIconName,
        false,
        true
      )}_conditional0`;
      headerIcon.conditionAttributes = [
        {
          component: 'condition',
          assertions: [
            {
              value: '${state.openHeaderIds}',
              expected: id,
              operator: 'contains',
            },
          ],
          attributes: {
            name: `\${var.${newIconVariable}}`,
          },
        },
      ];

      allVariables = {
        ...allVariables,
        [newIconVariable]: {
          value: '${icon.caretDown}',
          propertyType: 'name',
        },
      };
    }

    allVariables = {
      ...allVariables,
      ...header.variables,
    };
    topComponent = {
      ...(topComponent as TCollapseContainer),
      collapseHeader: header.component as TContainer,
    };

    meta.children.forEach((leaf) => {
      const newId = `${id}${capitalizeFirstCharacter(leaf)}`;
      const leafVariables = generateComponentVariables(
        newId,
        leaf,
        device,
        type
      );
      allVariables = { ...allVariables, ...leafVariables };
      topComponent = {
        ...(topComponent as TContainerLikeComponent),
        components: [
          ...((topComponent as TContainerLikeComponent).components || []),
          generateComponent(newId, leaf, leafVariables, device),
        ],
      };
    });
  } else if (meta.children.length) {
    meta.children.forEach((leaf) => {
      const newId = `${id}${capitalizeFirstCharacter(leaf)}`;
      const leafMeta = generateFullComponent(
        newId,
        leaf,
        device,
        idsMap,
        page,
        type
      );
      allVariables = { ...allVariables, ...leafMeta.variables };
      topComponent = {
        ...(topComponent as TContainerLikeComponent),
        components: [
          ...((topComponent as TContainerLikeComponent).components || []),
          leafMeta.component,
        ],
      };
    });
  }

  if (
    type === 'buttonText' &&
    parentType === 'navigatePageButton' &&
    page === 'page2'
  ) {
    allVariables = Object.entries(allVariables).reduce(
      (output, [key, meta]) => {
        if (meta.propertyType === 'text') {
          return {
            ...output,
            [key]: {
              ...meta,
              value: 'Go Back',
            },
          };
        }
        return {
          ...output,
          [key]: meta,
        };
      },
      {} as { [key: string]: TVariableMeta }
    );
  }

  Object.entries(allVariables).reduce((output, [key, meta]) => {
    if (meta.namespace === 'sku') {
      skuVariables = {
        ...skuVariables,
        [key]: meta,
      };
    } else if (meta.namespace === 'slide') {
      slideVariables = {
        ...slideVariables,
        [key]: meta,
      };
    } else if (meta.namespace === 'state') {
      stateVariables = {
        ...stateVariables,
        [key]: meta,
      };
    } else {
      componentVariables = {
        ...componentVariables,
        [key]: meta,
      };
    }
    return output;
  }, {});

  const slideFormFields = generateFormFields(allVariables, 'slide');

  return {
    variables: componentVariables,
    skuVariables: skuVariables,
    component: topComponent,
    stateVariables: stateVariables,
    slideVariables: slideVariables,
    slideFormFields: slideFormFields,
  };
}

function customizeDeeplinkButton(
  component: TComponent,
  _page: string
): TComponent {
  const componentUrl = (component as any).url || '${var.deeplinkButtonUrl}';
  (component as TButtonContainer).onTap = {
    function: 'namiDeeplink',
    parameters: {
      url: componentUrl,
    },
  };
  return component;
}

function customizeNavigatePageButton(
  component: TComponent,
  page: string
): TComponent {
  const screenNumber = page === 'page1' ? 'page2' : 'page1'; //TODO - handle more than 1 page
  (component as TButtonContainer).onTap = {
    function: 'namiNavigateToScreen',
    parameters: {
      screen: screenNumber,
    },
  };
  return component;
}

function customizeCollapseOpen(
  component: TComponent,
  _page: string
): TComponent {
  (component as TCollapseContainer).open = component.id!;
  return component;
}

function generateComponent(
  id: string,
  type: TAllComponentDetailValues,
  variables: { [key: string]: TVariableMeta },
  device: TDevice
): TComponent {
  const propertyTypes: Record<string, TVariablePattern> = Object.keys(
    variables
  ).reduce((output, key) => {
    const currentValue: TVariableMeta = variables[key];
    return {
      ...output,
      [currentValue.propertyType]: `\${${
        currentValue.namespace || 'var'
      }.${key}}`,
    };
  }, {});

  const result: TComponent = ComponentValueFormOptions[type].reduce(
    (output, option) => {
      if (option.tvOnly && device !== 'television') return output;
      if (option.optional) return output;
      return {
        ...output,
        [option.propertyType]:
          propertyTypes[option.propertyType] ||
          ComponentValueFormOptions[type].find(
            (opt) => opt.propertyType === option.propertyType
          )?.value ||
          DefaultComponentProperty[option.propertyType],
      };
    },
    {
      id: id,
      title: toReadable(id),
      namiComponentType: type,
      component: TComponentDetailValueMap[type].type,
    } as TComponent
  );

  return result;
}

function generateComponentVariables(
  id: string,
  type: TAllComponentDetailValues,
  formFactor: TDevice,
  parentType?: TAllComponentDetailValues
): { [key: string]: TVariableMeta } {
  let options: TVariableAndIdMeta[] = [];
  if (
    parentType &&
    ComponentSubValueOverrides[parentType as TContainerComponentDetailValues] &&
    ComponentSubValueOverrides[parentType as TContainerComponentDetailValues][
      type
    ]
  ) {
    options =
      ComponentSubValueOverrides[parentType as TContainerComponentDetailValues][
        type
      ];
  } else {
    options = ComponentValueFormOptions[type];
  }

  return options.reduce((output, option) => {
    if (option.tvOnly && formFactor !== 'television') return output;
    if (option.optional) return output;
    if (option.hasOwnProperty('idSuffix')) {
      const newOption = {
        ...option,
        value:
          formFactor === 'television' && option.tvValue
            ? option.tvValue
            : option.value,
      };
      return {
        ...output,
        [`${id}${option.idSuffix}`]: newOption as TVariableMeta,
      };
    }
    return output;
  }, {});
}

export function generateComponentAttributeOptions(
  component: TComponent,
  capabilities: TTemplateCapability[],
  device: TDevice
): TVariableAndIdMeta[] {
  let availableProperties = new Set<TVariableAndIdMeta>();
  if (
    component.id &&
    ComponentValueFormOptionOverrides.hasOwnProperty(component.id)
  ) {
    availableProperties = new Set(
      ComponentValueFormOptionOverrides[component.id]
    );
  } else if (component.namiComponentType) {
    //Limit conditional settings on videos
    if (component.namiComponentType === 'videoUrl') {
      availableProperties = new Set([
        {
          propertyType: 'url',
          idSuffix: 'Url',
          value: '',
        } as TVariableAndIdMeta,
        {
          propertyType: 'fallbackImage',
          idSuffix: 'FallbackImage',
          value: '${media.default}',
        } as TVariableAndIdMeta,
        {
          value: '100%',
          propertyType: 'width',
          idSuffix: 'Width',
        } as TVariableAndIdMeta,
        {
          value: 200,
          tvValue: 400,
          propertyType: 'height',
          idSuffix: 'Height',
        } as TVariableAndIdMeta,
      ]);
    } else if (
      ComponentValueFormOptions.hasOwnProperty(
        component.namiComponentType || ''
      )
    ) {
      availableProperties = new Set(
        ComponentValueFormOptions[component.namiComponentType]
      );
    }
  }

  if (
    component.namiComponentType === 'productButton' &&
    capabilities.includes('accessibility')
  ) {
    availableProperties.add({
      formType: 'text',
      propertyType: 'screenreaderText',
      // eslint-disable-next-line no-template-curly-in-string
      value: '${sku.screenreaderText}',
      idSuffix: 'Screenreader Text',
    });
  }

  const availablePropertiesList = Array.from(availableProperties);
  const availablePropertiesForDevice = availablePropertiesList.reduce(
    (output, value) => {
      if (value.tvOnly && device !== 'television') return output;
      return [...output, value];
    },
    [] as TVariableAndIdMeta[]
  );

  return availablePropertiesForDevice;
}

export function generateNewComponentVariable(
  id: string,
  componentType: TAllComponentDetailValues,
  propertyType: TNonProtectedComponentProperty,
  index: number = 0,
  conditional: boolean = false
): { key: string; meta: TVariableMeta } | null {
  let options: TVariableAndIdMeta[] = ComponentValueFormOptions[componentType];

  let match = options.filter((value) => value.propertyType === propertyType);
  let idValue = id;

  //productGroupToggleItem id is ${state.groups.#.id} by default which doesn't work here
  if (componentType === 'productGroupToggleItem') {
    const productGroupIndex = getProductGroupIndexFromId(id);
    idValue = `toggleItem${productGroupIndex}`;
  }

  if (match && match.length) {
    return {
      key: `${idValue}${match[0].idSuffix}${
        conditional ? `_conditional${index}` : ''
      }`,
      meta: match[0] as TVariableMeta,
    };
  }

  return null;
}

export function duplicateComponentAndChildren(
  component: TComponent,
  allVariables: { [value: string]: any },
  ids: { [key: string]: string }
): TEditComponentBuilderMeta {
  if ((component as TContainer).components) {
    let children: TEditComponentBuilderMeta[] = [];
    (component as TContainer).components.forEach((component) => {
      const childResult = duplicateComponentAndChildren(
        component,
        allVariables,
        ids
      );
      ids[childResult.component.id!] = 'placeholder';
      children.push(childResult);
    });

    const childVariables = children.reduce((output, child) => {
      return {
        ...output,
        ...child.variables,
      };
    }, {});

    const parentResult = duplicateComponent(component, allVariables, ids);
    (parentResult.component as TContainer).components = children.map(
      (child) => child.component
    );

    return {
      component: parentResult.component,
      skuVariables: {},
      variables: {
        ...parentResult.variables,
        ...childVariables,
      },
    };
  }

  return duplicateComponent(component, allVariables, ids);
}

export function duplicateCollapseComponent(
  component: TComponent,
  allVariables: { [value: string]: any },
  ids: { [key: string]: string }
): TEditComponentBuilderMeta {
  const result = duplicateComponentAndChildren(component, allVariables, ids);

  const newChildren = (
    result.component as TCollapseContainer
  ).components.reduce((output, childComponent) => {
    const newChildConditions = childComponent.conditionAttributes?.reduce(
      (output, condition) => {
        const newCondition = replaceConditionAssertionHeaderId(
          condition,
          result.component.id || ''
        );
        return [...output, newCondition];
      },
      [] as Array<
        Omit<TConditionalComponent, 'components'> & {
          attributes: Partial<TBaseComponent | TTextComponent>;
        }
      >
    );
    return [
      ...output,
      {
        ...childComponent,
        conditionAttributes: newChildConditions,
      },
    ];
  }, [] as TComponent[]);

  const newHeaderConditions = (
    result.component as TCollapseContainer
  ).collapseHeader.conditionAttributes?.reduce(
    (output, condition) => {
      const newCondition = replaceConditionAssertionHeaderId(
        condition,
        result.component.id || ''
      );
      return [...output, newCondition];
    },
    [] as Array<
      Omit<TConditionalComponent, 'components'> & {
        attributes: Partial<TBaseComponent | TTextComponent>;
      }
    >
  );

  const newHeaderChildren = (
    result.component as TCollapseContainer
  ).collapseHeader.components.reduce((output, headerChild) => {
    const newChildConditions = headerChild.conditionAttributes?.reduce(
      (output, condition) => {
        const newCondition = replaceConditionAssertionHeaderId(
          condition,
          result.component.id || ''
        );
        return [...output, newCondition];
      },
      [] as Array<
        Omit<TConditionalComponent, 'components'> & {
          attributes: Partial<TBaseComponent | TTextComponent>;
        }
      >
    );
    return [
      ...output,
      {
        ...headerChild,
        conditionAttributes: newChildConditions,
      },
    ];
  }, [] as TComponent[]);

  let newHeader = (result.component as TCollapseContainer).collapseHeader;
  newHeader.conditionAttributes = newHeaderConditions;
  newHeader.components = newHeaderChildren;
  (result.component as TCollapseContainer).components = newChildren;
  (result.component as TCollapseContainer).collapseHeader = newHeader;
  return result;
}

function replaceConditionAssertionHeaderId(
  condition: Omit<TConditionalComponent, 'components'> & {
    attributes: Partial<TBaseComponent | TTextComponent>;
  },
  newId: string
) {
  const newAssertions = (condition.assertions || []).reduce((output, a) => {
    if (a.value === '${state.openHeaderIds}') {
      return [
        ...output,
        {
          ...a,
          expected: newId,
        } as TTestObject,
      ];
    }
    return [...output, a];
  }, [] as TTestObject[]);

  const newOrAssertions = (condition.orAssertions || []).reduce((output, a) => {
    if (a.value === '${state.openHeaderIds}') {
      return [
        ...output,
        {
          ...a,
          expected: newId,
        } as TTestObject,
      ];
    }
    return [...output, a];
  }, [] as TTestObject[]);
  return {
    ...condition,
    assertions: condition.assertions ? newAssertions : undefined,
    orAssertions: condition.orAssertions ? newOrAssertions : undefined,
  };
}

export function duplicateComponent(
  component: TComponent,
  allVariables: { [value: string]: any },
  ids: { [key: string]: string }
): TEditComponentBuilderMeta {
  const newId = component.id
    ? incrementVariable(component.id, ids)
    : 'duplicateComponent1';
  let variableResult: { [value: string]: any } = {};

  const allComponentVariables: {
    [key: string]: { value: any; newKey: string };
  } = Object.entries(component).reduce((output, [key, value]) => {
    const deconstructedVariable = deconstructVariable(value, false, true);
    const variableNamespace = deconstructVariable(value, true);
    if (propertyValueIsVariable(value)) {
      if (variableNamespace === 'var') {
        return {
          ...output,
          [key]: {
            value: allVariables[deconstructedVariable],
            newKey: `${newId}${capitalizeFirstCharacter(key)}`,
          },
        };
      }
    }
    return output;
  }, {} as { [key: string]: { value: any; newKey: string } });

  const newComponent: { [key: string]: any } = Object.entries(component).reduce(
    (output, [key, value]) => {
      if (key === 'id') {
        return {
          ...output,
          id: newId,
        };
      }
      if (key === 'title') {
        return {
          ...output,
          title: toReadable(newId),
        };
      }
      if (propertyValueIsVariable(value)) {
        const variableNamespace = deconstructVariable(value, true);
        if (variableNamespace === 'var') {
          return {
            ...output,
            [key]: `\${var.${allComponentVariables[key].newKey}}`,
          };
        } else if (variableNamespace === 'media') {
          return {
            ...output,
            [key]: value,
          };
        }
      }
      if (key === 'conditionAttributes') {
        const copiedAttributes = (
          component['conditionAttributes'] || []
        ).reduce(
          (output, condition) => {
            const newAttributeVariables: {
              [key: string]: { value: any; newKey: string; namespace: string };
            } = Object.entries(condition.attributes).reduce(
              (output, [key, value]) => {
                const deconstructedVariable = deconstructVariable(
                  value as string,
                  false,
                  true
                );
                const variableNamespace = deconstructVariable(
                  value as string,
                  true
                );
                if (propertyValueIsVariable(value)) {
                  if (variableNamespace === 'var') {
                    return {
                      ...output,
                      [key]: {
                        value: allVariables[deconstructedVariable],
                        newKey: incrementVariable(deconstructedVariable, ids),
                        namespace: variableNamespace,
                      },
                    };
                  } else if (variableNamespace === 'sku') {
                    return {
                      ...output,
                      [key]: {
                        value: value,
                        newKey: key,
                        namespace: variableNamespace,
                      },
                    };
                  } else {
                    console.warn(
                      `Unsupported namespace in conditionAttribute: ${variableNamespace}`
                    );
                  }
                } else if (key === 'onTap') {
                  return {
                    ...output,
                    [key]: {
                      value: value,
                      newKey: key,
                      namespace: 'sku',
                    },
                  };
                } else {
                  console.warn(
                    `Cannot duplicate conditionAttribute variable ${value}`
                  );
                }
                return output;
              },
              {} as {
                [key: string]: {
                  value: any;
                  newKey: string;
                  namespace: string;
                };
              }
            );

            const newAttributes = Object.keys(newAttributeVariables).reduce(
              (output, key) => {
                if (newAttributeVariables[key].namespace === 'sku') {
                  return {
                    ...output,
                    [key]: newAttributeVariables[key].value,
                  };
                }
                return {
                  ...output,
                  [key]: `\${${newAttributeVariables[key].namespace}.${newAttributeVariables[key].newKey}}`,
                };
              },
              {} as Partial<TBaseComponent | TTextComponent>
            );

            const attributeVariables = Object.keys(
              newAttributeVariables
            ).reduce((output, key) => {
              const variableValues = newAttributeVariables[key];
              return {
                ...output,
                [variableValues.newKey]: variableValues.value,
              };
            }, {});
            variableResult = {
              ...variableResult,
              ...attributeVariables,
            };

            return [
              ...output,
              {
                component: 'condition',
                assertions: condition.assertions,
                orAssertions: condition.orAssertions,
                attributes: newAttributes,
              } as Omit<TConditionalComponent, 'components'> & {
                attributes: Partial<TBaseComponent | TTextComponent>;
              },
            ];
          },
          [] as Array<
            Omit<TConditionalComponent, 'components'> & {
              attributes: Partial<TBaseComponent | TTextComponent>;
            }
          >
        );
        return {
          ...output,
          [key]: copiedAttributes,
        };
      }
      if (key === 'collapseHeader') {
        //Duplicate collapse header and children
        const duplicatedHeader = duplicateComponentAndChildren(
          value,
          allVariables,
          ids
        );
        variableResult = {
          ...variableResult,
          ...duplicatedHeader.variables,
        };
        return {
          ...output,
          [key]: duplicatedHeader.component,
        };
      }
      if (key === 'bulletComponent') {
        const duplicatedBullet = duplicateComponent(value, allVariables, ids);
        variableResult = {
          ...variableResult,
          ...duplicatedBullet.variables,
        };
        return {
          ...output,
          [key]: duplicatedBullet.component,
        };
      }
      if (key === 'components') return output;
      return {
        ...output,
        [key]: value,
      };
    },
    {} as { [key: string]: any }
  );

  const newComponentVariables = Object.keys(allComponentVariables).reduce(
    (output, key) => {
      const variableValues = allComponentVariables[key];
      return {
        ...output,
        [variableValues.newKey]: variableValues.value,
      };
    },
    {}
  );

  variableResult = {
    ...variableResult,
    ...newComponentVariables,
  };

  return {
    variables: variableResult,
    skuVariables: {},
    component: newComponent as TComponent,
  };
}

export function addFeaturedToComponent(
  component: TComponent
): TEditComponentBuilderMeta {
  const id = component.id || '';
  let componentVariables: { [key: string]: TVariableMeta } = {};

  if (component.component !== 'condition') {
    const componentType = component.namiComponentType || 'productText';
    const componentVariableOptions =
      ComponentValueFormOptions[componentType] || [];
    componentVariables = componentVariableOptions.reduce((output, option) => {
      if (option.idSuffix && option.featuredValue) {
        return {
          ...output,
          [`featured_${id}${option.idSuffix}`]: {
            ...option,
            value: option.featuredValue,
          } as TVariableMeta,
        };
      }
      return output;
    }, {});

    if (Object.keys(componentVariables).length) {
      const conditionAttributes = Object.entries(componentVariables).reduce(
        (output, [key, value]) => {
          return {
            ...output,
            [value.propertyType]: `\${var.${key}}`, //Improve namespace handling?
          };
        },
        {}
      );
      const conditionalBlock: Array<
        Omit<TConditionalComponent, 'components'> & {
          attributes: Partial<TBaseComponent>;
        }
      > = [
        ...(component.conditionAttributes ? component.conditionAttributes : []),
        {
          component: 'condition',
          assertions: [
            {
              value: '${sku.featured}',
              expected: true,
              operator: 'equals',
            },
          ],
          attributes: conditionAttributes,
        },
      ];
      component.conditionAttributes = conditionalBlock;
    }
  }

  let result: TEditComponentBuilderMeta = {
    variables: componentVariables,
    skuVariables: {},
    component: component,
  };

  return result;
}

export function addSelectedToComponent(
  component: TComponent,
  hasPromoEligibility: boolean = false
): TEditComponentBuilderMeta {
  const id = component.id || '';

  let componentVariables: { [key: string]: TVariableMeta } = {};

  if (component.component !== 'condition') {
    const componentType = component.namiComponentType || 'productText';
    const componentVariableOptions = ComponentValueFormOptions[componentType];
    componentVariables = componentVariableOptions.reduce((output, option) => {
      if (option.idSuffix && option.selectedValue) {
        return {
          ...output,
          [`selected_${id}${option.idSuffix}`]: {
            ...option,
            value: option.selectedValue,
          } as TVariableMeta,
        };
      }
      return output;
    }, {});

    if (componentType === 'productButton') {
      (component as TButtonContainer).onTap = {
        function: 'namiSelectSKU',
      };

      if (hasPromoEligibility) {
        //Remove onTap conditionAttribute if promo eligible
        const newAssertions = (component.conditionAttributes || []).reduce(
          (output, condition) => {
            if (Object.keys(condition.attributes).includes('onTap'))
              return output;
            return [...output, condition];
          },
          [] as Array<
            Omit<TConditionalComponent, 'components'> & {
              attributes: Partial<TBaseComponent>;
            }
          >
        );
        component.conditionAttributes = newAssertions;
      }
    }

    if (Object.keys(componentVariables).length) {
      const conditionAttributes = Object.entries(componentVariables).reduce(
        (output, [key, value]) => {
          return {
            ...output,
            [value.propertyType]: `\${var.${key}}`, //Improve namespace handling?
          };
        },
        {}
      );
      const conditionalBlock: Array<
        Omit<TConditionalComponent, 'components'> & {
          attributes: Partial<TBaseComponent>;
        }
      > = [
        ...(component.conditionAttributes ? component.conditionAttributes : []),
        {
          component: 'condition',
          assertions: [
            {
              value: '${state.selectedProducts.${state.currentGroupId}}',
              expected: '${sku.id}',
              operator: 'equals',
            },
          ],
          attributes: conditionAttributes,
        },
      ];
      component.conditionAttributes = conditionalBlock;
    }
  }

  let result: TEditComponentBuilderMeta = {
    variables: componentVariables,
    skuVariables: {},
    component: component,
  };

  return result;
}

export function addTrialEligibilityToComponent(
  component: TComponent,
  productChild: boolean = true,
  currentSkuVariables?: TProductVariable[]
): TEditComponentBuilderMeta {
  let componentVariables: { [key: string]: TVariableMeta } = {};
  let skuVariables: { [key: string]: TVariableMeta } = {};

  if (component.component !== 'condition') {
    if (productChild) {
      const currentProductFieldNamespace = deconstructVariable(
        (component as TTextComponent).text,
        true
      );

      if (currentProductFieldNamespace === 'sku') {
        const currentProductField = deconstructVariable(
          (component as TTextComponent).text,
          false,
          true
        );

        const currentProductVariable = currentSkuVariables?.filter((value) => {
          return value.variable === currentProductField;
        });
        const componentType = component.namiComponentType || 'productText';
        const skuVariableOptions = ComponentValueFormOptions[componentType];

        skuVariables = skuVariableOptions.reduce((output, option) => {
          if (option.idSuffix && option.trialValue && currentProductField) {
            return {
              ...output,
              [`trial${currentProductField}`]: {
                ...option,
                value: option.trialValue,
                title:
                  currentProductVariable && currentProductVariable.length
                    ? `Trial ${currentProductVariable[0].display_name}`
                    : `Trial ${toReadable(currentProductField)}`,
              } as TVariableMeta,
            };
          }
          return output;
        }, {});

        const conditionAttributes = Object.entries(skuVariables).reduce(
          (output, [key, value]) => {
            return {
              ...output,
              [value.propertyType]: `\${sku.${key}}`, //Improve namespace handling?
            };
          },
          {}
        );

        if (Object.keys(conditionAttributes).length > 0) {
          const conditionalBlock: Array<
            Omit<TConditionalComponent, 'components'> & {
              attributes: Partial<TBaseComponent>;
            }
          > = [
            ...(component.conditionAttributes
              ? component.conditionAttributes
              : []),
            {
              component: 'condition',
              assertions: [
                {
                  value: '${sku.freeTrialEligible}',
                  expected: true,
                  operator: 'equals',
                },
              ],
              attributes: conditionAttributes,
            },
          ];
          component.conditionAttributes = conditionalBlock;
        }
      }
    } else {
      if (
        component.namiComponentType &&
        ComponentCapabilitySettings.hasOwnProperty(component.namiComponentType)
      ) {
        const componentCapabilitySettings =
          ComponentCapabilitySettings[component.namiComponentType];
        if (componentCapabilitySettings.trialValue) {
          const oldVar = (component as TTextComponent).text;
          componentVariables[
            `${deconstructVariable(oldVar, false, true)}_trial`
          ] = {
            value: componentCapabilitySettings.trialValue,
            propertyType: 'text',
          } as TVariableMeta;

          const conditionAttributes = Object.entries(componentVariables).reduce(
            (output, [key, value]) => {
              return {
                ...output,
                [value.propertyType]: `\${var.${key}}`, //Improve namespace handling?
              };
            },
            {}
          );

          const conditionalBlock: Array<
            Omit<TConditionalComponent, 'components'> & {
              attributes: Partial<TBaseComponent>;
            }
          > = [
            ...(component.conditionAttributes
              ? component.conditionAttributes
              : []),
            {
              component: 'condition',
              assertions: [
                {
                  value: '${state.anySkuHasTrialOffer}',
                  expected: true,
                  operator: 'equals',
                },
              ],
              attributes: conditionAttributes,
            },
          ];
          component.conditionAttributes = conditionalBlock;
        }
      }
    }
  }

  let result: TEditComponentBuilderMeta = {
    variables: componentVariables,
    skuVariables: skuVariables,
    component: component,
  };

  return result;
}

export function canAddTrialEligibilityToComponent(
  component: TComponent
): boolean {
  if (component.component === 'carouselContainer') return false;
  return true;
}

export function addPromoEligibilityToComponent(
  component: TComponent,
  productChild: boolean = true,
  currentSkuVariables?: TProductVariable[],
  selectedStateActive: boolean = false
): TEditComponentBuilderMeta {
  let componentVariables: { [key: string]: TVariableMeta } = {};
  let skuVariables: { [key: string]: TVariableMeta } = {};

  if (component.component !== 'condition') {
    if (productChild) {
      const currentProductFieldNamespace = deconstructVariable(
        (component as TTextComponent).text,
        true
      );
      const componentType = component.namiComponentType || 'productText';

      if (currentProductFieldNamespace === 'sku') {
        const currentProductField = deconstructVariable(
          (component as TTextComponent).text,
          false,
          true
        );
        const currentProductVariable = currentSkuVariables?.filter((value) => {
          return value.variable === currentProductField;
        });
        const skuVariableOptions = ComponentValueFormOptions[componentType];
        skuVariables = skuVariableOptions.reduce((output, option) => {
          if (option.idSuffix && option.promoValue && currentProductField) {
            return {
              ...output,
              [`promo${currentProductField}`]: {
                ...option,
                value: option.promoValue,
                title:
                  currentProductVariable && currentProductVariable.length
                    ? `Promo ${currentProductVariable[0].display_name}`
                    : `Promo ${toReadable(currentProductField)}`,
              } as TVariableMeta,
            };
          }
          return output;
        }, {});
      }

      if (componentType === 'productButton' && !selectedStateActive) {
        skuVariables.promoKey = {
          formType: 'onTap',
          propertyType: 'onTap',
          value: '${sku.promo:0}',
          title: 'Promo Key',
        };
      }

      const conditionAttributes = Object.entries(skuVariables).reduce(
        (output, [key, value]) => {
          if (key === 'promoKey') {
            return output;
          }
          return {
            ...output,
            [value.propertyType]: `\${sku.${key}}`, //Improve namespace handling?
          };
        },
        {}
      );

      if (Object.keys(conditionAttributes).length > 0) {
        const conditionalBlock: Array<
          Omit<TConditionalComponent, 'components'> & {
            attributes: Partial<TBaseComponent>;
          }
        > = [
          ...(component.conditionAttributes
            ? component.conditionAttributes
            : []),
          {
            component: 'condition',
            assertions: [
              {
                value: '${sku.promoEligible}',
                expected: true,
                operator: 'equals',
              },
            ],
            attributes: conditionAttributes,
          },
        ];
        component.conditionAttributes = conditionalBlock;
      }
      if (componentType === 'productButton' && !selectedStateActive) {
        const conditionalBlock: Array<
          Omit<TConditionalComponent, 'components'> & {
            attributes: Partial<TBaseComponent | TButtonContainer>;
          }
        > = [
          ...(component.conditionAttributes
            ? component.conditionAttributes
            : []),
          {
            component: 'condition',
            assertions: [
              {
                value: '${sku.promoEligible}',
                expected: true,
                operator: 'equals',
              },
            ],
            attributes: {
              onTap: {
                function: 'namiBuySKU',
                parameters: {
                  promo: '${sku.promoKey}',
                },
              },
            },
          },
        ];
        component.conditionAttributes = conditionalBlock;
      }
    } else {
      if (component.namiComponentType) {
        if (
          ComponentCapabilitySettings.hasOwnProperty(
            component.namiComponentType
          )
        ) {
          const componentCapabilitySettings =
            ComponentCapabilitySettings[component.namiComponentType];
          if (componentCapabilitySettings.promoValue) {
            const oldVar = (component as TTextComponent).text;
            componentVariables[
              `${deconstructVariable(oldVar, false, true)}_promo`
            ] = {
              value: componentCapabilitySettings.promoValue,
              propertyType: 'text',
            } as TVariableMeta;

            const conditionAttributes = Object.entries(
              componentVariables
            ).reduce((output, [key, value]) => {
              return {
                ...output,
                [value.propertyType]: `\${var.${key}}`, //Improve namespace handling?
              };
            }, {});

            const conditionalBlock: Array<
              Omit<TConditionalComponent, 'components'> & {
                attributes: Partial<TBaseComponent>;
              }
            > = [
              ...(component.conditionAttributes
                ? component.conditionAttributes
                : []),
              {
                component: 'condition',
                orAssertions: [
                  {
                    value: '${state.anySkuHasPromoOffer}',
                    expected: true,
                    operator: 'equals',
                  },
                ],
                attributes: conditionAttributes,
              },
            ];
            component.conditionAttributes = conditionalBlock;
          }
        } else if (
          component.namiComponentType === 'purchaseButton' &&
          selectedStateActive
        ) {
          skuVariables.promoKey = {
            formType: 'onTap',
            propertyType: 'onTap',
            value: '${sku.promo:0}',
            title: 'Promo Key',
          };
          (component as TButtonContainer).onTap = {
            function: 'namiPurchaseSelectedSKU',
            parameters: {
              promo: '${sku.promoKey}',
            },
          };
        }
      }
    }
  }

  let result: TEditComponentBuilderMeta = {
    variables: componentVariables,
    skuVariables: skuVariables,
    component: component,
  };

  return result;
}

export function addIntroEligibilityToComponent(
  component: TComponent,
  productChild: boolean = true,
  currentSkuVariables?: TProductVariable[]
): TEditComponentBuilderMeta {
  let componentVariables: { [key: string]: TVariableMeta } = {};
  let skuVariables: { [key: string]: TVariableMeta } = {};

  if (component.component !== 'condition') {
    if (productChild) {
      const currentProductFieldNamespace = deconstructVariable(
        (component as TTextComponent).text,
        true
      );
      const componentType = component.namiComponentType || 'productText';

      if (currentProductFieldNamespace === 'sku') {
        const currentProductField = deconstructVariable(
          (component as TTextComponent).text,
          false,
          true
        );
        const currentProductVariable = currentSkuVariables?.filter((value) => {
          return value.variable === currentProductField;
        });
        const skuVariableOptions = ComponentValueFormOptions[componentType];
        skuVariables = skuVariableOptions.reduce((output, option) => {
          if (option.idSuffix && option.introValue && currentProductField) {
            return {
              ...output,
              [`intro${currentProductField}`]: {
                ...option,
                value: option.introValue,
                title:
                  currentProductVariable && currentProductVariable.length
                    ? `Intro ${currentProductVariable[0].display_name}`
                    : `Intro ${toReadable(currentProductField)}`,
              } as TVariableMeta,
            };
          }
          return output;
        }, {});
      }

      const conditionAttributes = Object.entries(skuVariables).reduce(
        (output, [key, value]) => {
          if (key === 'promoKey') {
            return output;
          }
          return {
            ...output,
            [value.propertyType]: `\${sku.${key}}`, //Improve namespace handling?
          };
        },
        {}
      );

      if (Object.keys(conditionAttributes).length > 0) {
        const conditionalBlock: Array<
          Omit<TConditionalComponent, 'components'> & {
            attributes: Partial<TBaseComponent>;
          }
        > = [
          ...(component.conditionAttributes
            ? component.conditionAttributes
            : []),
          {
            component: 'condition',
            assertions: [
              {
                value: '${sku.introEligible}',
                expected: true,
                operator: 'equals',
              },
            ],
            attributes: conditionAttributes,
          },
        ];
        component.conditionAttributes = conditionalBlock;
      }
    } else {
      if (component.namiComponentType) {
        if (
          ComponentCapabilitySettings.hasOwnProperty(
            component.namiComponentType
          )
        ) {
          const componentCapabilitySettings =
            ComponentCapabilitySettings[component.namiComponentType];
          if (componentCapabilitySettings.introValue) {
            const oldVar = (component as TTextComponent).text;
            componentVariables[
              `${deconstructVariable(oldVar, false, true)}_intro`
            ] = {
              value: componentCapabilitySettings.introValue,
              propertyType: 'text',
            } as TVariableMeta;

            const conditionAttributes = Object.entries(
              componentVariables
            ).reduce((output, [key, value]) => {
              return {
                ...output,
                [value.propertyType]: `\${var.${key}}`, //Improve namespace handling?
              };
            }, {});

            const conditionalBlock: Array<
              Omit<TConditionalComponent, 'components'> & {
                attributes: Partial<TBaseComponent>;
              }
            > = [
              ...(component.conditionAttributes
                ? component.conditionAttributes
                : []),
              {
                component: 'condition',
                orAssertions: [
                  {
                    value: '${state.anySkuHasIntroOffer}',
                    expected: true,
                    operator: 'equals',
                  },
                ],
                attributes: conditionAttributes,
              },
            ];
            component.conditionAttributes = conditionalBlock;
          }
        }
      }
    }
  }

  let result: TEditComponentBuilderMeta = {
    variables: componentVariables,
    skuVariables: skuVariables,
    component: component,
  };

  return result;
}

export function addScreenreaderTextToComponent(
  component: TComponent
): TEditComponentBuilderMeta {
  let componentVariables: { [key: string]: TVariableMeta } = {};
  const newId = `${component.id}ScreenreaderText`;

  const defaultValueMap: { [key: string]: string } = {
    purchaseButton: 'tap to purchase product',
    loginButton: 'tap to sign in',
    restoreButton: 'tap to restore in-app purchase',
    closeButton: 'tap to close paywall',
    deeplinkButton: 'tap to navigate',
    navigatePageButton: 'tap to navigate between screens',
    volumeButton: 'tap to mute or unmute video',
    playPauseButton: 'tap to play or pause video',
  };

  if (
    component.namiComponentType &&
    component.namiComponentType in defaultValueMap
  ) {
    componentVariables[newId] = {
      formType: 'text',
      propertyType: 'screenreaderText',
      value: defaultValueMap[component.namiComponentType],
    } as TVariableMeta;
    (component as TButtonContainer).screenreaderText = `\${var.${newId}}`;
  } else if (component.namiComponentType === 'productButton') {
    (component as TButtonContainer).screenreaderText =
      '${sku.screenreaderText}';
  }

  let result: TEditComponentBuilderMeta = {
    variables: componentVariables,
    skuVariables: {},
    component: component,
  };

  return result;
}

export function removeFromCapabilitiesArray(
  all: TTemplateCapability[],
  capabilityToRemove: TTemplateCapability
): TTemplateCapability[] {
  return all.reduce((output, capability) => {
    if (capability === capabilityToRemove) return output;
    return [...output, capability];
  }, [] as TTemplateCapability[]);
}

export function addToCapabilitiesArray(
  all: TTemplateCapability[],
  newCapability: TTemplateCapability
): TTemplateCapability[] {
  if (all.includes(newCapability)) return all;
  return [...all, newCapability];
}

export function removeCapability(
  idLocations: TIdLocations,
  idTypeLocations: TIdTypeLocation,
  pages: TPaywallPage[],
  assertions: string[],
  checkContent: boolean,
  partial?: boolean
) {
  const productContainerChildren = findProductContainerChildLocations(
    idLocations,
    idTypeLocations
  );
  let allChildren: TComponent[] = [];

  productContainerChildren.forEach((child) => {
    const parsedProductContainerChildLocation = parseLocationString(child);
    const productContainerChild = findComponentFromLocation(
      pages,
      parsedProductContainerChildLocation
    );
    allChildren.push(productContainerChild);
  });

  if (checkContent) {
    const contentContainerChildren = findContentContainerFooterChildLocations(
      idLocations,
      false
    );

    contentContainerChildren.forEach((child) => {
      const parsedContentContainerChildLocation = parseLocationString(child);
      const contentContainerChild = findComponentFromLocation(
        pages,
        parsedContentContainerChildLocation
      );
      if (contentContainerChild) allChildren.push(contentContainerChild);
    });

    const headerChildren = findHeaderChildLocations(idLocations);
    headerChildren.forEach((child) => {
      const parsedHeaderChildLocation = parseLocationString(child);
      const headerChild = findComponentFromLocation(
        pages,
        parsedHeaderChildLocation
      );
      if (headerChild) allChildren.push(headerChild);
    });
  }

  allChildren.forEach((child) => {
    if (child.component === 'condition') {
      if (
        anyAssertionContainsTestValue(
          child.orAssertions || [],
          assertions,
          partial
        )
      ) {
        child.orAssertions = [];
      }

      if (
        anyAssertionContainsTestValue(
          child.assertions || [],
          assertions,
          partial
        )
      ) {
        child.assertions = [];
      }
    } else {
      if (child.conditionAttributes) {
        const conditions = child.conditionAttributes || [];
        const newConditions = conditions.reduce(
          (output, condition) => {
            if (
              anyAssertionContainsTestValue(
                condition.assertions || [],
                assertions,
                partial
              )
            )
              return output;
            if (
              anyAssertionContainsTestValue(
                condition.orAssertions || [],
                assertions,
                partial
              )
            )
              return output;
            return [...output, condition];
          },
          [] as Array<
            Omit<TConditionalComponent, 'components'> & {
              attributes: Partial<TBaseComponent>;
            }
          >
        );

        if (newConditions.length > 0) {
          child.conditionAttributes = newConditions;
        } else {
          delete child.conditionAttributes;
        }
      }
    }
  });

  //TODO - handle delete ids
}

export function updateComponentConditionals(
  component: TConditionalComponent,
  assertions: TTestObject[]
) {
  component.assertions = assertions;
}

export function anyAssertionContainsTestValue(
  assertions: TTestObject[],
  values: string[],
  partial?: boolean
): boolean {
  let result = false;

  for (const objIndex in assertions) {
    if (partial && values[0]) {
      if (assertions[objIndex].value.includes(values[0])) {
        result = true;
        break;
      }
    } else if (
      values.includes(assertions[objIndex].value) ||
      assertions[objIndex].value.includes(values[0])
    ) {
      result = true;
      break;
    }
  }
  return result;
}

export function generateFormSectionsByComponent(
  component: TComponent,
  componentLocation?: TComponentLocation,
  parentDataSourceRoot?: string | null,
  productGroupCarousel?: boolean
): {
  sections: TFormSection[];
  conditions: TFormConditional[];
} {
  const allowDynamicColorVariables: TNonProtectedComponentProperty[] = [
    'fillColor',
    'borderColor',
    'fontColor',
  ];
  const properties = Object.entries(component).reduce(
    (output, [key, value]) => {
      if (propertyValueIsVariable(value)) {
        return {
          ...output,
          [key as TComponentProperty]: deconstructVariable(String(value)),
        };
      }
      if (
        (key === 'url' || key === 'fallbackImage') &&
        component.namiComponentType !== 'repeatingImageSource'
      ) {
        return {
          ...output,
          [key as TComponentProperty]: deconstructVariable(String(value)),
        };
      }
      return output;
    },
    {} as Record<TComponentProperty, string>
  );

  let conditionalFormSections: TFormConditional[] = [];

  let formSections = Object.entries(ComponentFormGroupProperties).reduce(
    (output, [group, groupProperties]) => {
      if (group !== 'Icon') {
        const fields = groupProperties.reduce((output, property) => {
          if (
            property in properties &&
            !properties[property as TComponentProperty].startsWith('sku.') &&
            !properties[property as TComponentProperty].startsWith('slide.')
          ) {
            if (
              (component.namiComponentType === 'carousel' ||
                component.namiComponentType === 'slide') &&
              property in CarouselPropertyFormDefinition
            ) {
              return [
                ...output,
                {
                  ...CarouselPropertyFormDefinition[property],
                  variable: properties[property as TComponentProperty],
                } as TFieldSettings,
              ];
            } else if (
              properties[property as TComponentProperty] === 'state.safeAreaTop'
            ) {
              return [
                ...output,
                {
                  type: 'safeArea',
                  label: toReadable(property),
                  variable: properties[property as TComponentProperty],
                } as TFieldSettings,
              ];
            } else if (
              properties[property as TComponentProperty].startsWith('state')
            ) {
              //TODO - improve handling other state variables
              if (property === 'open') {
                if (
                  component.namiComponentType &&
                  ComponentPropertyFormDefinitionOverrides[
                    component.namiComponentType
                  ] &&
                  ComponentPropertyFormDefinitionOverrides[
                    component.namiComponentType
                  ][property]
                ) {
                  return [
                    ...output,
                    {
                      ...ComponentPropertyFormDefinitionOverrides[
                        component.namiComponentType
                      ][property],
                      variable: properties[property as TComponentProperty],
                    } as TFieldSettings,
                  ];
                }
                return [
                  ...output,
                  {
                    ...ComponentPropertyFormDefinition[property],
                    variable: properties[property as TComponentProperty],
                  } as TFieldSettings,
                ];
              }
            } else if (
              componentLocation &&
              component.namiComponentType === 'videoUrl' &&
              componentLocation.section === 'backgroundContainer' &&
              property === 'controlsType'
            ) {
              //Hide controls selection for videos in background
              return output;
            } else if (property in ComponentPropertyFormDefinition) {
              if (
                component.namiComponentType &&
                ComponentPropertyFormDefinitionOverrides[
                  component.namiComponentType
                ] &&
                ComponentPropertyFormDefinitionOverrides[
                  component.namiComponentType
                ][property]
              ) {
                return [
                  ...output,
                  {
                    ...ComponentPropertyFormDefinitionOverrides[
                      component.namiComponentType
                    ][property],
                    variable: properties[property as TComponentProperty],
                    parentDataSourceRoot:
                      parentDataSourceRoot &&
                      allowDynamicColorVariables.includes(property)
                        ? parentDataSourceRoot
                        : null,
                  } as TFieldSettings,
                ];
              } else if (
                component.id &&
                ComponentPropertyFormDefinitionOverridesById[component.id] &&
                ComponentPropertyFormDefinitionOverridesById[component.id][
                  property
                ]
              ) {
                return [
                  ...output,
                  {
                    ...ComponentPropertyFormDefinitionOverridesById[
                      component.id
                    ][property],
                    variable: properties[property as TComponentProperty],
                  } as TFieldSettings,
                ];
              }
              return [
                ...output,
                {
                  ...ComponentPropertyFormDefinition[property],
                  variable: properties[property as TComponentProperty],
                  parentDataSourceRoot: allowDynamicColorVariables.includes(
                    property
                  )
                    ? parentDataSourceRoot
                    : null,
                } as TFieldSettings,
              ];
            }
          } else if (
            property in properties &&
            properties[property as TComponentProperty].startsWith('slide.')
          ) {
            //Special handling for sku properties
            if (property in CarouselPropertyFormDefinition) {
              return [
                ...output,
                {
                  ...CarouselPropertyFormDefinition[property],
                  variable: properties[property as TComponentProperty],
                  componentLocation: componentLocation,
                } as TFieldSettings,
              ];
            }
          } else {
            //Special handling for sku properties
            if (
              property in properties &&
              property in SkuPropertyFormDefinition
            ) {
              return [
                ...output,
                {
                  ...SkuPropertyFormDefinition[property],
                  variable: properties[property as TComponentProperty],
                  componentLocation: componentLocation,
                } as TFieldSettings,
              ];
            }
          }
          return output;
        }, [] as TFieldSettings[]);

        if (fields.length > 0) {
          return [
            ...output,
            {
              title: group,
              hint: null,
              collapsible: true,
              displayVar: null,
              fields: fields,
            } as TFormSection,
          ];
        }
      }
      return output;
    },
    [] as TFormSection[]
  );

  if ((component as TTextListComponent).bulletComponent) {
    const bulletProperties = Object.entries(
      (component as TTextListComponent).bulletComponent || {}
    ).reduce((bulletOutput, [bulletKey, bulletValue]) => {
      if (propertyValueIsVariable(bulletValue)) {
        return {
          ...bulletOutput,
          [bulletKey as TComponentProperty]: deconstructVariable(
            String(bulletValue)
          ),
        };
      }
      return bulletOutput;
    }, {} as Record<TComponentProperty, string>);

    let bulletFormSections = Object.entries(
      ComponentFormGroupProperties
    ).reduce((output, [group, groupProperties]) => {
      if (group === 'Icon') {
        const fields = groupProperties.reduce((output, property) => {
          if (
            property in bulletProperties &&
            property in ComponentPropertyFormDefinition
          ) {
            return [
              ...output,
              {
                ...ComponentPropertyFormDefinition[property],
                variable: bulletProperties[property as TComponentProperty],
              } as TFieldSettings,
            ];
          }
          return output;
        }, [] as TFieldSettings[]);

        if (fields.length > 0) {
          return [
            ...output,
            {
              title: group,
              hint: null,
              collapsible: true,
              displayVar: null,
              fields: fields,
            } as TFormSection,
          ];
        }
      }
      return output;
    }, [] as TFormSection[]);

    formSections = [...bulletFormSections, ...formSections];
  }

  if (component.conditionAttributes) {
    conditionalFormSections = component.conditionAttributes.reduce(
      (output, attribute) => {
        const type: TAssertionType = attribute.orAssertions ? 'or' : 'and';
        const conditionalProperties = Object.entries(
          attribute.attributes
        ).reduce((output, [key, value]) => {
          if (propertyValueIsVariable(value)) {
            return {
              ...output,
              [key as TComponentProperty]: deconstructVariable(String(value)),
            };
          }
          if (
            (key === 'url' || key === 'fallbackImage') &&
            component.namiComponentType !== 'repeatingImageSource'
          ) {
            return {
              ...output,
              [key as TComponentProperty]: deconstructVariable(String(value)),
            };
          }
          return output;
        }, {} as Record<TComponentProperty, string>);

        const fields = Object.keys(attribute.attributes).reduce(
          (output, property) => {
            if (property in conditionalProperties) {
              if (
                !conditionalProperties[
                  property as TComponentProperty
                ].startsWith('sku.') &&
                !conditionalProperties[
                  property as TComponentProperty
                ].startsWith('state.') &&
                !conditionalProperties[
                  property as TComponentProperty
                ].startsWith('slide.')
              ) {
                if (
                  component.namiComponentType &&
                  ComponentPropertyFormDefinitionOverrides[
                    component.namiComponentType
                  ] &&
                  ComponentPropertyFormDefinitionOverrides[
                    component.namiComponentType
                  ][property]
                ) {
                  return [
                    ...output,
                    {
                      ...ComponentPropertyFormDefinitionOverrides[
                        component.namiComponentType
                      ][property],
                      variable:
                        conditionalProperties[property as TComponentProperty],
                    } as TFieldSettings,
                  ];
                }
                return [
                  ...output,
                  {
                    ...ComponentPropertyFormDefinition[
                      property as TNonProtectedComponentProperty
                    ],
                    variable:
                      conditionalProperties[property as TComponentProperty],
                  } as TFieldSettings,
                ];
              }
              if (
                conditionalProperties[
                  property as TComponentProperty
                ].startsWith('sku')
              ) {
                return [
                  ...output,
                  {
                    type: 'conditionalProductVariableSelector',
                    label:
                      property === 'screenreaderText'
                        ? 'Screenreader Text'
                        : 'Product Field',
                    variable:
                      conditionalProperties[property as TComponentProperty],
                    componentLocation: componentLocation,
                  } as TFieldSettings,
                ];
              }
              if (
                conditionalProperties[property as TComponentProperty] ===
                'state.safeAreaTop'
              ) {
                return [
                  ...output,
                  {
                    type: 'safeArea',
                    label: toReadable(property),
                    variable:
                      conditionalProperties[property as TComponentProperty],
                  } as TFieldSettings,
                ];
              }
              if (
                conditionalProperties[
                  property as TComponentProperty
                ].startsWith('slide')
              ) {
                if (property === 'text') {
                  return [
                    ...output,
                    {
                      type: 'conditionalCarouselTextInputSelector',
                      label: 'Text',
                      variable:
                        conditionalProperties[property as TComponentProperty],
                      componentLocation: componentLocation,
                    } as TFieldSettings,
                  ];
                } else if (property === 'texts') {
                  return [
                    ...output,
                    {
                      type: 'conditionalCarouselListInputSelector',
                      label: 'Text',
                      variable:
                        conditionalProperties[property as TComponentProperty],
                      componentLocation: componentLocation,
                    } as TFieldSettings,
                  ];
                } else if (property === 'url') {
                  return [
                    ...output,
                    {
                      type: 'conditionalCarouselImageInputSelector',
                      label: 'Image',
                      variable:
                        conditionalProperties[property as TComponentProperty],
                      componentLocation: componentLocation,
                    } as TFieldSettings,
                  ];
                } else if (property === 'fillImage') {
                  return [
                    ...output,
                    {
                      type: 'conditionalCarouselFillImageInputSelector',
                      label: 'Image',
                      variable:
                        conditionalProperties[property as TComponentProperty],
                      componentLocation: componentLocation,
                    } as TFieldSettings,
                  ];
                }
              }
            }
            return output;
          },
          [] as TFieldSettings[]
        );

        return [
          ...output,
          {
            fields: fields,
            assertions:
              type === 'or' ? attribute.orAssertions : attribute.assertions,
            type: type,
          } as TFormConditional,
        ];
      },
      [] as TFormConditional[]
    );
  }

  if (component.component === 'carouselContainer') {
    const variable = productGroupCarousel
      ? 'state.slides.${state.groups.0.id}.carouselName'
      : 'state.slides.${state.currentGroupId}.carouselName';
    const slideSection: TFormSection = {
      title: 'Slides',
      hint: null,
      displayVar: null,
      collapsible: true,
      fields: [
        {
          hint: null,
          type: 'carouselSlides',
          label: '',
          options: null,
          carousel: 'carouselName',
          variable: variable,
        },
      ],
    };
    formSections.unshift(slideSection);
  }

  const typesThatNeedSpecialContentSection: TAllComponentDetailValues[] = [
    // 'image',
    'productContainer',
    'repeatingList',
    'repeatingTextSource',
    'repeatingImageSource',
  ];

  if (
    component.namiComponentType &&
    typesThatNeedSpecialContentSection.includes(component.namiComponentType)
  ) {
    if (formSections[0].title !== 'Content') {
      const dataSourceSection: TFormSection = {
        title: 'Content',
        fields: [],
        hint: null,
        displayVar: null,
        collapsible: true,
      };
      formSections.unshift(dataSourceSection);
    }
  }

  return {
    sections: formSections,
    conditions: conditionalFormSections,
  };
}

export function generateBodyFormSections(): TFormSection[] {
  const fields: (
    | 'productGroupSelector'
    | 'fullscreenToggle'
    | 'productPricingStateSelector'
    | 'productTrialStateSelector'
    | 'trialEligibilityToggle'
    | TFieldSettings
  )[] = ['fullscreenToggle'];
  //TODO - add loading background color
  return [
    {
      title: 'Content',
      hint: null,
      collapsible: false,
      displayVar: null,
      fields: fields,
    } as TFormSection,
  ];
}

/**
 * Function only used for generating carousel and sku form fields
 */
export function generateFormFields(
  variables: { [key: string]: TVariableAndIdMeta },
  namespace: TVariableNamespace
): TFieldSettings[] {
  let result: TFieldSettings[] = [];
  let conditions: TTestObject[] = [];
  Object.entries(variables).forEach(([key, meta]) => {
    if (
      meta.propertyType in ComponentPropertyFormDefinition &&
      (meta.namespace || 'var') === namespace
    ) {
      const field =
        meta.propertyType === 'url'
          ? ({
              type: 'image',
              label: 'Image',
            } as Omit<TFieldSettings, 'variable'>)
          : ComponentPropertyFormDefinition[
              meta.propertyType as TNonProtectedComponentProperty
            ];
      //Carousel fields must use media. or var. variable prefix
      //Sku fields don't have variable prefix
      const variable =
        meta.namespace === 'slide'
          ? String(meta.value).includes('media')
            ? `media.${key}`
            : `var.${key}`
          : `${key}`;

      result.push({
        ...field,
        label: formFieldReadableLabel(key, meta, false),
        variable: variable,
        conditions: conditions,
      });
    }
  });

  return result;
}

export function formFieldReadableLabel(
  variableName: string,
  value: TVariableMeta,
  trialEligible: boolean = false
): string {
  if ((value.namespace || 'var') === 'sku') {
    let variable = variableName.replace('product', '');
    if (value.propertyType === 'text') variable = variable.replace('Text', '');
    if (trialEligible) variable = variable.replace('_trial', '');
    return toReadable(variable);
  } else if ((value.namespace || 'var') === 'slide') {
    return toReadable(variableName.replace('carousel', ''));
  }
  return toReadable(value.propertyType);
}

export function findProductContainerChildLocations(
  idLocations: TIdLocations,
  idTypeLocations: TIdTypeLocation
): string[] {
  const allProductContainerLocations =
    idTypeLocations['productContainer'] || [];
  if (!allProductContainerLocations.length) return [];

  return Object.entries(idLocations).reduce((output, [_key, value]) => {
    if (locationChildOfAnyId(value, allProductContainerLocations)) {
      return [...output, value];
    }
    return output;
  }, [] as string[]);
}

export function findProductContainerChildIds(
  idLocations: TIdLocations,
  idTypeLocations: TIdTypeLocation
): string[] {
  const allProductContainerLocations =
    idTypeLocations['productContainer'] || [];
  if (!allProductContainerLocations.length) return [];

  return Object.entries(idLocations).reduce((output, [key, value]) => {
    if (locationChildOfAnyId(value, allProductContainerLocations)) {
      return [...output, key];
    }
    return output;
  }, [] as string[]);
}

export function findRepeatingGridChildIds(
  idLocations: TIdLocations,
  idTypeLocations: TIdTypeLocation
): string[] {
  const allRepeatingGridLocations = idTypeLocations['repeatingList'] || [];
  if (!allRepeatingGridLocations.length) return [];

  return Object.entries(idLocations).reduce((output, [key, value]) => {
    if (locationChildOfAnyId(value, allRepeatingGridLocations)) {
      return [...output, key];
    }
    return output;
  }, [] as string[]);
}

export function findParentRepeatingGridLocation(
  id: string,
  idLocations: TIdLocations,
  idTypeLocations: TIdTypeLocation
): string | null {
  const idLocation = idLocations[id];
  const allRepeatingGridLocations = idTypeLocations['repeatingList'] || [];
  if (!idLocation || !allRepeatingGridLocations.length) return null;

  for (const loc in allRepeatingGridLocations) {
    if (
      idLocation.startsWith(allRepeatingGridLocations[loc]) &&
      idLocation !== allRepeatingGridLocations[loc]
    ) {
      return allRepeatingGridLocations[loc];
    }
  }

  return null;
}

export function findCarouselChildIds(
  idLocations: TIdLocations,
  idTypeLocations: TIdTypeLocation
): string[] {
  const allRepeatingGridLocations = idTypeLocations['carousel'] || []; //TODO - handle multiple carousels? //TODO - handle carousel in stack
  if (!allRepeatingGridLocations.length) return [];

  return Object.entries(idLocations).reduce((output, [key, value]) => {
    if (locationChildOfAnyId(value, allRepeatingGridLocations)) {
      return [...output, key];
    }
    return output;
  }, [] as string[]);
}

export function findTypeAndSectionById(
  id: string,
  idLocations: TIdLocations,
  idTypeLocations: TIdTypeLocation
): TComponentLocationMetadata {
  if (!id || !idLocations[id])
    return {
      section: 'contentContainer',
      type: 'group',
    };
  const location = idLocations[id];
  const parsedLocation = parseLocationString(location);
  const type = getTypeFromId(location, idTypeLocations);

  return {
    section: parsedLocation.section,
    type: type,
  };
}

export function findParentIdOfComponent(
  id: string,
  idLocations: TIdLocations
): string | null {
  if (id && idLocations[id]) {
    const location = idLocations[id];
    const parsedLocation = parseLocationString(location);
    const parentLocation = moveUpTreeFromLocation(parsedLocation);
    return getIdFromLocation(
      idLocations,
      createLocationStringFromParsedObject(parentLocation)
    );
  }
  return null;
}

export function locationChildOfAnyId(location: string, ids: string[]): boolean {
  let result = false;
  for (const loc in ids) {
    if (location.startsWith(ids[loc]) && location !== ids[loc]) {
      result = true;
    }
  }
  return result;
}

export function findHeaderChildLocations(idLocations: TIdLocations): string[] {
  const allHeaderLocations = HeaderIdOptions.reduce((output, id) => {
    if (!idLocations[id]) return output;
    return [...output, idLocations[id]];
  }, [] as string[]);

  return Object.entries(idLocations).reduce((output, [key, value]) => {
    if (locationChildOfAnyId(value, allHeaderLocations)) {
      return [...output, value];
    }
    return output;
  }, [] as string[]);
}

export function findContentContainerFooterChildLocations(
  idLocations: { [id: string]: string },
  includeProductContainerChildren: boolean = false,
  includeCarouselContainerChildren: boolean = false
): string[] {
  const allContentContainerLocations = ContentContainerFooterIdOptions.reduce(
    (output, id) => {
      if (!idLocations[id]) return output;
      return [...output, idLocations[id]];
    },
    [] as string[]
  );
  const allProductContainerLocations = ProductContainerIdOptions.reduce(
    (output, id) => {
      if (!idLocations[id]) return output;
      return [...output, idLocations[id]];
    },
    [] as string[]
  );
  const allCarouselLocations = CarouselIdOptions.reduce((output, id) => {
    if (!idLocations[id]) return output;
    return [...output, idLocations[id]];
  }, [] as string[]);

  return Object.entries(idLocations).reduce((output, [_key, value]) => {
    if (includeProductContainerChildren && includeCarouselContainerChildren) {
      if (locationChildOfAnyId(value, allContentContainerLocations)) {
        return [...output, value];
      }
    } else if (
      includeProductContainerChildren &&
      !includeCarouselContainerChildren
    ) {
      if (
        locationChildOfAnyId(value, allContentContainerLocations) &&
        !locationChildOfAnyId(value, allCarouselLocations)
      ) {
        return [...output, value];
      }
    } else if (
      !includeProductContainerChildren &&
      includeCarouselContainerChildren
    ) {
      if (
        locationChildOfAnyId(value, allContentContainerLocations) &&
        !locationChildOfAnyId(value, allProductContainerLocations)
      ) {
        return [...output, value];
      }
    } else {
      if (
        locationChildOfAnyId(value, allContentContainerLocations) &&
        !locationChildOfAnyId(value, allProductContainerLocations) &&
        !locationChildOfAnyId(value, allCarouselLocations)
      ) {
        return [...output, value];
      }
    }
    return output;
  }, [] as string[]);
}

export function isChildOfDeletedComponent(
  deletedComponentLocations: string[],
  location: string
): boolean {
  for (const loc in deletedComponentLocations) {
    if (location.startsWith(deletedComponentLocations[loc])) return true;
    return false;
  }
  return false;
}

export function getIdFromLocation(
  ids: TIdLocations,
  location: string
): string | null {
  let result: string | null = null;
  const idKeyLocations = Object.entries(ids);
  for (const idKeyLocationIndex in idKeyLocations) {
    if (idKeyLocations[idKeyLocationIndex][1] === location) {
      result = idKeyLocations[idKeyLocationIndex][0];
      break;
    }
  }
  return result;
}

export function getTypeFromId(
  location: string,
  idTypeLocations: TIdTypeLocation
): TAllComponentDetailValues {
  let result: TAllComponentDetailValues = 'group';
  const typeIndices = Object.keys(idTypeLocations);
  for (const typeIndex in typeIndices) {
    const currentType = typeIndices[typeIndex];
    for (const locationIndex in idTypeLocations[currentType]) {
      const curLocation = idTypeLocations[currentType][locationIndex];
      if (curLocation === location) {
        result = currentType as TAllComponentDetailValues;
        break;
      }
    }
  }
  return result;
}

const ContentContainerFooterIdOptions = [
  'contentContainer',
  'contentContainer0',
  'footer',
  'footer0',
];

const HeaderIdOptions = ['header', 'header0'];

export const ProductContainerIdOptions = [
  'productContainer',
  'productContainer0',
  'productContainer1',
  'productContainer2',
  'productContainer3',
];

//TODO - improve
//TODO - handle carousel in header
const CarouselIdOptions = ['carousel', 'carousel0'];

export type TAssertionType = 'and' | 'or';

export type TConditionEditorType =
  | 'conditionalComponent'
  | 'conditionAttributes'
  | 'loopSource';

export type TFormConditional = {
  fields: TFieldSettings[];
  assertions: TTestObject[];
  type: TAssertionType;
};

//These properties cannot be edited via form field
export type TProtectedComponentProperty =
  | 'components'
  | 'playingComponents'
  | 'pausedComponents'
  | 'volumeComponents'
  | 'mutedComponents'
  | 'textType'
  | 'loopSource'
  | 'loopVariable'
  | 'ui.name'
  | 'segmentId'
  | 'products'
  | 'title';
/**
 * Properties that can be edited in a form field
 */
export type TNonProtectedComponentProperty =
  | 'fillColor'
  | 'fillColorFallback'
  | 'borderColor'
  | 'borderRadius'
  | 'borderWidth'
  | 'url'
  // | 'image'
  | 'text'
  | 'texts'
  | 'name'
  | 'fontName'
  | 'fontSize'
  | 'alignment'
  | 'verticalAlignment'
  | 'horizontalAlignment'
  | 'fontColor'
  | 'linkColor'
  | 'strikethrough'
  | 'width'
  | 'height'
  | 'leftPadding'
  | 'rightPadding'
  | 'topPadding'
  | 'bottomPadding'
  | 'leftMargin'
  | 'rightMargin'
  | 'topMargin'
  | 'bottomMargin'
  | 'direction'
  | 'spacing'
  | 'imageCropping'
  | 'dropShadow'
  | 'moveX'
  | 'moveY'
  | 'zIndex'
  | 'fillImage'
  | 'indicatorColor'
  | 'activeIndicatorColor'
  | 'autoplay'
  | 'autoplaySeconds'
  | 'showIndicators'
  | 'roundBorders'
  | 'grow'
  | 'onTap'
  | 'activeFontName'
  | 'activeFontSize'
  | 'activeFontColor'
  | 'activeFillColor'
  | 'activeBorderColor'
  | 'activeBorderWidth'
  | 'activeBorderRadius'
  | 'activeRoundBorders'
  | 'inactiveFontName'
  | 'inactiveFontSize'
  | 'inactiveFontColor'
  | 'inactiveFillColor'
  | 'inactiveBorderColor'
  | 'inactiveBorderWidth'
  | 'inactiveBorderRadius'
  | 'inactiveRoundBorders'
  | 'screenreaderText'
  | 'focusedFillColor'
  | 'focusedFillColorFallback'
  | 'focusedBorderColor'
  | 'focusedBorderWidth'
  | 'focusedBorderRadius'
  | 'focusedFontColor'
  | 'fallbackImage'
  | 'controlsType'
  | 'autoplayVideo'
  | 'mute'
  | 'open'
  | 'loopVideo'
  | 'playingOnTap'
  | 'pausedOnTap'
  | 'volumeOnTap'
  | 'mutedOnTap'
  | 'dateTimeFormat';

type TComponentProperty =
  | TNonProtectedComponentProperty
  | TProtectedComponentProperty;

export type TVariableMeta = {
  formType?: TField | null;
  propertyType: TComponentProperty;
  value: any;
  title?: string; //TODO
  namespace?: TVariableNamespace;
  featuredValue?: any;
  selectedValue?: any;
  trialValue?: any;
  introValue?: any;
  promoValue?: any;
  opacity?: boolean;
  optional?: boolean;
  tvValue?: any;
  tvOnly?: boolean;
  //TODO - handle max/min limits?
};

export type TVariableAndIdMeta = TVariableMeta & {
  idSuffix?: string;
};

/**
 * Sections of editable properties for a component
 */
type TComponentPropertyFormGroups =
  | 'Content'
  | 'Styling'
  | 'Layout'
  | 'Typography'
  | 'Size'
  | 'Position'
  | 'Spacing'
  | 'Icon' //Special type
  | 'Carousel'
  | 'Video'
  | 'Accessibility';

/**
 * For each section of editable properties, which properties are editable and in what order
 */
const ComponentFormGroupProperties: Record<
  TComponentPropertyFormGroups,
  Array<TNonProtectedComponentProperty>
> = {
  Carousel: [
    'autoplay',
    'autoplaySeconds',
    'showIndicators',
    'indicatorColor',
    'activeIndicatorColor',
  ],
  Content: [
    'text',
    'texts',
    'url',
    'fillImage',
    'name',
    'onTap',
    'fallbackImage',
    'open',
    'dateTimeFormat',
  ],
  Video: ['autoplayVideo', 'mute', 'loopVideo', 'controlsType'],
  Typography: [
    'fontName',
    'activeFontName',
    'inactiveFontName',
    'fontSize',
    'activeFontSize',
    'inactiveFontSize',
    'fontColor',
    'focusedFontColor',
    'activeFontColor',
    'inactiveFontColor',
    'linkColor',
    'strikethrough',
  ],
  Styling: [
    'fillColor',
    'fillColorFallback',
    'focusedFillColor',
    'focusedFillColorFallback',
    'activeFillColor',
    'inactiveFillColor',
    'borderColor',
    'focusedBorderColor',
    'activeBorderColor',
    'inactiveBorderColor',
    'borderWidth',
    'focusedBorderWidth',
    'activeBorderWidth',
    'inactiveBorderWidth',
    'borderRadius',
    'focusedBorderRadius',
    'activeBorderRadius',
    'inactiveBorderRadius',
    'roundBorders',
    'activeRoundBorders',
    'inactiveRoundBorders',
    'dropShadow',
  ],
  Size: ['width', 'height', 'grow'],
  Layout: [
    'alignment',
    'verticalAlignment',
    'horizontalAlignment',
    'imageCropping',
    'direction',
  ],
  Spacing: [
    'spacing',
    'leftPadding',
    'rightPadding',
    'topPadding',
    'bottomPadding',
    'leftMargin',
    'rightMargin',
    'topMargin',
    'bottomMargin',
  ],
  Position: ['moveX', 'moveY', 'zIndex'],
  Icon: ['name', 'fontColor', 'fontSize'], //Should only be used for bulletComponent
  Accessibility: ['screenreaderText'],
};

/**
 * For each editable property, what is the form field definition
 */
const ComponentPropertyFormDefinition: Record<
  TNonProtectedComponentProperty,
  Omit<TFieldSettings, 'variable'>
> = {
  text: {
    type: 'text',
    label: 'Content',
    showVariableCloud: true,
  },
  texts: {
    type: 'splitTextArea',
    label: 'Content',
  },
  screenreaderText: {
    type: 'text',
    label: 'Screenreader Text',
  },
  name: {
    type: 'iconSelect',
    label: 'Icon',
  },
  imageCropping: {
    type: 'imageCropping',
    label: 'Crop',
  },
  url: {
    type: 'imagePicker',
    label: 'Image',
  },
  fontName: {
    type: 'fontSelect',
    label: 'Font Name',
  },
  fontSize: {
    type: 'number',
    label: 'Font Size',
    maxLimit: 200,
    minLimit: 6,
  },
  alignment: {
    type: 'alignment',
    label: 'Alignment',
  },
  verticalAlignment: {
    type: 'verticalAlignment',
    label: 'Vertical Alignment',
  },
  horizontalAlignment: {
    type: 'horizontalAlignment',
    label: 'Horizontal Alignment',
  },
  fontColor: {
    type: 'color',
    label: 'Font Color',
    showOpacity: true,
  },
  linkColor: {
    type: 'color',
    label: 'Link Color',
    showOpacity: true,
  },
  strikethrough: {
    type: 'toggle',
    label: 'Strikethrough',
  },
  width: {
    type: 'width',
    label: 'Width',
  },
  height: {
    type: 'height',
    label: 'Height',
  },
  leftPadding: {
    type: 'bigNumber',
    label: 'Left Padding',
  },
  rightPadding: {
    type: 'bigNumber',
    label: 'Right Padding',
  },
  topPadding: {
    type: 'bigNumber',
    label: 'Top Padding',
  },
  bottomPadding: {
    type: 'bigNumber',
    label: 'Bottom Padding',
  },
  leftMargin: {
    type: 'bigNumber',
    label: 'Left Margin',
  },
  rightMargin: {
    type: 'bigNumber',
    label: 'Right Margin',
  },
  topMargin: {
    type: 'bigNumber',
    label: 'Top Margin',
  },
  bottomMargin: {
    type: 'bigNumber',
    label: 'Bottom Margin',
  },
  spacing: {
    type: 'number',
    label: 'Spacing',
  },
  direction: {
    type: 'direction',
    label: 'Direction',
  },
  fillColor: {
    type: 'colorGradient',
    label: 'Fill Color',
    showOpacity: true,
  },
  borderColor: {
    type: 'color',
    label: 'Border Color',
    showOpacity: true,
  },
  borderWidth: {
    type: 'number',
    label: 'Border Width',
  },
  borderRadius: {
    type: 'number',
    label: 'Corner Radius',
  },
  roundBorders: {
    type: 'roundBorders',
    label: 'Round Borders',
  },
  dropShadow: {
    type: 'dropShadow',
    label: 'Elevation',
  },
  moveX: {
    type: 'ptPxSelect',
    label: 'Move X',
  },
  moveY: {
    type: 'ptPxSelect',
    label: 'Move Y',
  },
  zIndex: {
    type: 'number',
    label: 'Z-Index',
  },
  autoplay: {
    type: 'toggle',
    label: 'Autoplay',
  },
  autoplayVideo: {
    type: 'toggle',
    label: 'Autoplay',
  },
  autoplaySeconds: {
    type: 'number',
    label: 'Transition Speed',
    minLimit: 1,
    maxLimit: 60,
  },
  indicatorColor: {
    type: 'color',
    showOpacity: true,
    label: 'Inactive Indicator Color',
  },
  activeIndicatorColor: {
    type: 'color',
    showOpacity: true,
    label: 'Active Indicator Color',
  },
  fillImage: {
    type: 'image',
    label: 'Image',
  },
  showIndicators: {
    type: 'toggle',
    label: 'Show Indicators',
  },
  grow: {
    type: 'toggle',
    label: 'Grow',
  },
  onTap: {
    type: 'onTap',
    label: 'Action',
  },
  activeFontName: {
    type: 'fontSelect',
    label: 'Active Font Name',
  },
  activeFontSize: {
    type: 'number',
    label: 'Active Font Size',
  },
  activeFontColor: {
    type: 'color',
    label: 'Active Font Color',
    showOpacity: true,
  },
  activeFillColor: {
    type: 'colorGradient',
    label: 'Fill Color',
    showOpacity: true,
  },
  activeBorderColor: {
    type: 'color',
    label: 'Border Color',
    showOpacity: true,
  },
  activeBorderWidth: {
    type: 'number',
    label: 'Border Width',
  },
  activeBorderRadius: {
    type: 'number',
    label: 'Corner Radius',
  },
  activeRoundBorders: {
    type: 'roundBorders',
    label: 'Active Round Borders',
  },
  inactiveFontName: {
    type: 'fontSelect',
    label: 'Inactive Font Name',
  },
  inactiveFontSize: {
    type: 'number',
    label: 'Inactive Font Size',
  },
  inactiveFontColor: {
    type: 'color',
    label: 'Inactive Font Color',
    showOpacity: true,
  },
  inactiveFillColor: {
    type: 'colorGradient',
    label: 'Inactive Fill Color',
    showOpacity: true,
  },
  inactiveBorderColor: {
    type: 'color',
    label: 'Inactive Border Color',
    showOpacity: true,
  },
  inactiveBorderWidth: {
    type: 'number',
    label: 'Inactive Border Width',
  },
  inactiveBorderRadius: {
    type: 'number',
    label: 'Inactive Corner Radius',
  },
  inactiveRoundBorders: {
    type: 'roundBorders',
    label: 'Inactive Round Borders',
  },
  fillColorFallback: {
    type: 'color',
    showOpacity: true,
    label: 'Fill Color Fallback',
  },
  focusedFillColor: {
    type: 'colorGradient',
    label: 'Focused Fill Color',
    showOpacity: true,
  },
  focusedFillColorFallback: {
    type: 'color',
    showOpacity: true,
    label: 'Focused Fill Fallback',
  },
  focusedBorderColor: {
    type: 'color',
    label: 'Focused Border Color',
  },
  focusedBorderRadius: {
    type: 'number',
    label: 'Focused Corner Radius',
  },
  focusedBorderWidth: {
    type: 'number',
    label: 'Focused Border Width',
  },
  focusedFontColor: {
    type: 'color',
    label: 'Focused Font Color',
  },
  fallbackImage: {
    type: 'fallbackImagePicker',
    label: 'Fallback Image',
  },
  controlsType: {
    type: 'option',
    label: 'Type of Video Controls',
    options: [
      { value: 'none', label: 'None' },
      { value: 'nativeUi', label: 'Native UI' },
    ],
  },
  mute: {
    type: 'toggle',
    label: 'Mute by Default',
  },
  open: {
    type: 'collapseOpenToggle',
    label: 'Open by Default',
  },
  loopVideo: {
    type: 'toggle',
    label: 'Loop Video',
  },
  pausedOnTap: {
    type: 'onTap',
    label: 'Action',
  },
  playingOnTap: {
    type: 'onTap',
    label: 'Action',
  },
  volumeOnTap: {
    type: 'onTap',
    label: 'Action',
  },
  mutedOnTap: {
    type: 'onTap',
    label: 'Action',
  },
  dateTimeFormat: {
    type: 'dateTimeFormat',
    label: 'Date Time Format',
  },
};

/**
 * Custom form field definitions by id > property
 */
const ComponentPropertyFormDefinitionOverrides: {
  [key: string]: Record<string, Omit<TFieldSettings, 'variable'>>;
} = {
  legalText: {
    text: {
      type: 'textArea',
      label: 'Content',
      showVariableCloud: true,
    },
  },
  bodyText: {
    text: {
      type: 'textArea',
      label: 'Content',
      showVariableCloud: true,
    },
  },
  deeplinkButton: {
    url: {
      type: 'url',
      label: 'Destination',
    },
  },
  image: {
    width: {
      type: 'widthFillFixed',
      label: 'Width',
    },
    height: {
      type: 'heightFillFixed',
      label: 'Height',
    },
  },
  hostedImage: {
    url: {
      type: 'url',
      label: 'Image URL',
      showVariableCloud: true,
    },
    width: {
      type: 'widthFillFixed',
      label: 'Width',
    },
    height: {
      type: 'heightFillFixed',
      label: 'Height',
    },
  },
  repeatingImageSource: {
    width: {
      type: 'widthFillFixed',
      label: 'Width',
    },
    height: {
      type: 'heightFillFixed',
      label: 'Height',
    },
  },
  videoUrl: {
    url: {
      type: 'url',
      label: 'Video URL',
      showVariableCloud: true,
    },
  },
  slide: {
    height: {
      type: 'heightFitFixed',
      label: 'Height',
    },
  },
  stack: {
    height: {
      type: 'bigNumber',
      label: 'Height',
    },
  },
};

/**
 * Custom form field definitions by id > property
 */
const ComponentPropertyFormDefinitionOverridesById: {
  [key: string]: Record<string, Omit<TFieldSettings, 'variable'>>;
} = {
  // purchaseButtonButtonText: {
  //   text: {
  //     type: 'text',
  //     label: 'Content',
  //     showSmartTextCloud: true,
  //   },
  // },
};

/**
 * Custom form field definitions for product container items
 * */
const SkuPropertyFormDefinition: Record<
  string,
  Omit<TFieldSettings, 'variable'>
> = {
  text: {
    type: 'productVariableSelector',
    label: 'Product Field',
  },
  screenreaderText: {
    type: 'productVariableSelector',
    label: 'Screenreader Text',
  },
};

/**
 * Custom form field definitions for carousel items
 */
const CarouselPropertyFormDefinition: Record<
  string,
  Omit<TFieldSettings, 'variable'>
> = {
  text: {
    type: 'carouselTextInputSelector',
    label: 'Text',
  },
  texts: {
    type: 'carouselListInputSelector',
    label: 'Text',
  },
  fillImage: {
    type: 'carouselFillImageInputSelector',
    label: 'Fill Image',
  },
  url: {
    type: 'carouselImageInputSelector',
    label: 'Image',
  },
  height: {
    type: 'heightFitFixed',
    label: 'Height',
  },
};

/**
 * For each new component, what editable properties are available and what are the defaults for these properties
 */
export const ComponentValueFormOptions: Record<
  TAllComponentDetailValues,
  Array<TVariableAndIdMeta>
> = {
  contentContainer: [
    {
      value: 'Content',
      propertyType: 'title',
    },
    {
      value: [],
      propertyType: 'components',
    },
    {
      value: 'transparent',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: '100%',
      propertyType: 'width',
    },
    {
      value: 'fitContent',
      tvValue: '100%',
      propertyType: 'height',
    },
    {
      value: 20,
      propertyType: 'spacing',
      idSuffix: 'Spacing',
    },
    {
      value: 20,
      tvValue: 96,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 20,
      tvValue: 96,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 0,
      tvValue: 80,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 40,
      tvValue: 80,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 'vertical',
      propertyType: 'direction',
    },
    {
      value: 'top',
      tvValue: 'center',
      propertyType: 'alignment',
    },
  ],
  header: [
    {
      value: 'Header',
      propertyType: 'title',
    },
    {
      value: [],
      propertyType: 'components',
    },
    {
      value: 'transparent',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: '100%',
      propertyType: 'width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 0,
      propertyType: 'spacing',
      idSuffix: 'Spacing',
    },
    {
      value: 20,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 20,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: '${state.safeAreaTop}',
      propertyType: 'topPadding',
    },
    {
      value: 0,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 'horizontal',
      propertyType: 'direction',
      idSuffix: 'Direction',
    },
    {
      value: 'right',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
  ],
  backgroundContainer: [
    {
      value: 'Background',
      propertyType: 'title',
    },
    {
      value: [],
      propertyType: 'components',
    },
    {
      value: '#ffffff',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: 'vertical',
      propertyType: 'direction',
    },
  ],
  titleText: [
    {
      value: 'title',
      propertyType: 'textType',
    },
    {
      value: 'Get Started',
      propertyType: 'text',
      idSuffix: 'Text',
    },
    {
      value: 'Helvetica',
      propertyType: 'fontName',
      idSuffix: 'FontName',
    },
    {
      value: '#000000',
      propertyType: 'fontColor',
      idSuffix: 'FontColor',
    },
    {
      value: 24,
      propertyType: 'fontSize',
      idSuffix: 'FontSize',
      tvValue: 56,
    },
    {
      value: 'center',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
      optional: true,
    },
    {
      value: false,
      propertyType: 'strikethrough',
      idSuffix: 'Strikethrough',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 0,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 0,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 0,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  bodyText: [
    {
      value: 'body',
      propertyType: 'textType',
    },
    {
      value:
        'This is body text. Use body text to tell users why they should purchase.',
      propertyType: 'text',
      idSuffix: 'Text',
    },
    {
      value: 'Helvetica',
      propertyType: 'fontName',
      idSuffix: 'FontName',
    },
    {
      value: '#000000',
      propertyType: 'fontColor',
      idSuffix: 'FontColor',
    },
    {
      value: 16,
      propertyType: 'fontSize',
      idSuffix: 'FontSize',
      tvValue: 24,
    },
    {
      value: 'left',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: false,
      propertyType: 'strikethrough',
      idSuffix: 'Strikethrough',
    },
    {
      value: 0,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  productText: [
    {
      value: 'body',
      propertyType: 'textType',
    },
    {
      value: '${sku.productDisplayTextText}',
      formType: 'text',
      propertyType: 'text',
      idSuffix: 'Text',
      namespace: 'sku',
      trialValue: '${sku.freeTrialDuration} free',
      introValue: '${sku.introductoryPrice}/${sku.introductoryPeriod}',
      promoValue: '${sku.promoPrice:0}/${sku.promoPeriod:0}',
    },
    {
      value: 'Helvetica',
      propertyType: 'fontName',
      idSuffix: 'FontName',
    },
    {
      value: '#000000',
      propertyType: 'fontColor',
      idSuffix: 'FontColor',
      featuredValue: '#000000',
      selectedValue: '#1374de',
    },
    {
      value: '#eeeeee',
      propertyType: 'focusedFontColor',
      idSuffix: 'FocusedFontColor',
      tvOnly: true,
    },
    {
      value: 16,
      propertyType: 'fontSize',
      idSuffix: 'FontSize',
      tvValue: 24,
    },
    {
      value: 'left',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: false,
      propertyType: 'strikethrough',
      idSuffix: 'Strikethrough',
    },
    {
      value: 0,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
    {
      value: '0%',
      propertyType: 'moveX',
      idSuffix: 'MoveX',
      optional: true,
    },
    {
      value: '0%',
      propertyType: 'moveY',
      idSuffix: 'MoveY',
      optional: true,
    },
  ],
  legalText: [
    {
      value: 'legal',
      propertyType: 'textType',
    },
    {
      value: '${legal.clickwrap_text}',
      propertyType: 'text',
      idSuffix: 'Text',
    },
    {
      value: 'Helvetica',
      propertyType: 'fontName',
      idSuffix: 'FontName',
    },
    {
      value: '#000000',
      propertyType: 'fontColor',
      idSuffix: 'FontColor',
    },
    {
      value: '#0096FF',
      propertyType: 'linkColor',
      idSuffix: 'LinkColor',
    },
    {
      value: 12,
      tvValue: 18,
      propertyType: 'fontSize',
      idSuffix: 'FontSize',
    },
    {
      value: 'center',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 0,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  bulletList: [
    {
      value: 'body', //TODO - not sure what text type is expected for list
      propertyType: 'textType',
    },
    {
      value: [
        'This is a list',
        'List your *best* features',
        'Keep the items short!',
      ],
      propertyType: 'texts',
      idSuffix: 'Text',
    },
    {
      value: 'Helvetica',
      propertyType: 'fontName',
      idSuffix: 'FontName',
    },
    {
      value: '#000000',
      propertyType: 'fontColor',
      idSuffix: 'FontColor',
    },
    {
      value: 16,
      tvValue: 24,
      propertyType: 'fontSize',
      idSuffix: 'FontSize',
    },
    {
      value: 'left',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 10,
      tvValue: 15,
      propertyType: 'spacing',
      idSuffix: 'Spacing',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
  ],
  bulletComponent: [
    {
      value: '${icon.checkmarkCircle}',
      propertyType: 'name',
      idSuffix: 'ListIcon',
    },
    {
      value: '#000000',
      propertyType: 'fontColor',
      idSuffix: 'FontColor',
    },
    {
      value: 16,
      tvValue: 24,
      propertyType: 'fontSize',
      idSuffix: 'FontSize',
    },
  ],
  customList: [
    {
      value: 'vertical',
      propertyType: 'direction',
      idSuffix: 'Direction',
    },
    {
      propertyType: 'alignment',
      value: 'left',
      idSuffix: 'Alignment',
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      propertyType: 'spacing',
      value: 12,
      tvValue: 18,
      idSuffix: 'Spacing',
    },
  ],
  listItem: [
    {
      value: [],
      propertyType: 'components',
    },
    {
      value: 'transparent',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 'horizontal',
      propertyType: 'direction',
      idSuffix: 'Direction',
    },
    {
      value: 8,
      propertyType: 'spacing',
      idSuffix: 'Spacing',
    },
    {
      value: 0,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  icon: [
    {
      value: '${icon.checkmarkCircle}',
      propertyType: 'name',
      idSuffix: 'Icon',
    },
    {
      value: '#000000',
      propertyType: 'fontColor',
      idSuffix: 'FontColor',
    },
    {
      value: 16,
      tvValue: 24,
      propertyType: 'fontSize',
      idSuffix: 'FontSize',
    },
  ],
  loginButton: [
    {
      value: {
        function: 'namiSignIn',
      },
      propertyType: 'onTap',
      idSuffix: 'OnTap',
    },
    {
      value: 'transparent',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: 'transparent',
      propertyType: 'fillColorFallback',
      idSuffix: 'FillColorFallback',
      opacity: true,
      tvOnly: true,
      optional: true,
    },
    {
      value: 'transparent',
      propertyType: 'borderColor',
      idSuffix: 'BorderColor',
      opacity: true,
    },
    {
      value: 0,
      propertyType: 'borderWidth',
      idSuffix: 'BorderWidth',
    },
    {
      value: 0,
      propertyType: 'borderRadius',
      idSuffix: 'BorderRadius',
    },
    {
      value: '#eeeeee',
      propertyType: 'focusedFillColor',
      idSuffix: 'FocusedFillColor',
      opacity: true,
      tvOnly: true,
    },
    {
      value: '#eeeeee',
      propertyType: 'focusedFillColorFallback',
      idSuffix: 'FocusedFillColorFallback',
      tvOnly: true,
      optional: true,
    },
    {
      value: '#ffffff',
      propertyType: 'focusedBorderColor',
      idSuffix: 'FocusedBorderColor',
      opacity: true,
      tvOnly: true,
    },
    {
      value: 2,
      propertyType: 'focusedBorderWidth',
      idSuffix: 'FocusedBorderWidth',
      tvOnly: true,
    },
    {
      value: 0,
      propertyType: 'focusedBorderRadius',
      idSuffix: 'FocusedBorderRadius',
      tvOnly: true,
    },
    {
      value: '0 0 0 rgba(0 0 0 / 0.0)',
      propertyType: 'dropShadow',
      idSuffix: 'DropShadow',
      optional: true,
    },
    {
      value: 'center',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: 'fitContent',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 20,
      tvValue: 28,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 20,
      tvValue: 28,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 10,
      tvValue: 16,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 10,
      tvValue: 16,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  restoreButton: [
    {
      value: {
        function: 'namiRestorePurchases',
      },
      propertyType: 'onTap',
      idSuffix: 'OnTap',
    },
    {
      value: 'transparent',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: 'transparent',
      propertyType: 'fillColorFallback',
      idSuffix: 'FillColorFallback',
      opacity: true,
      tvOnly: true,
      optional: true,
    },
    {
      value: 'transparent',
      propertyType: 'borderColor',
      idSuffix: 'BorderColor',
      opacity: true,
    },
    {
      value: 0,
      propertyType: 'borderWidth',
      idSuffix: 'BorderWidth',
    },
    {
      value: 0,
      propertyType: 'borderRadius',
      idSuffix: 'BorderRadius',
    },
    {
      value: '#eeeeee',
      propertyType: 'focusedFillColor',
      idSuffix: 'FocusedFillColor',
      opacity: true,
      tvOnly: true,
    },
    {
      value: '#eeeeee',
      propertyType: 'focusedFillColorFallback',
      idSuffix: 'FocusedFillColorFallback',
      tvOnly: true,
      optional: true,
    },
    {
      value: '#ffffff',
      propertyType: 'focusedBorderColor',
      idSuffix: 'FocusedBorderColor',
      opacity: true,
      tvOnly: true,
    },
    {
      value: 2,
      propertyType: 'focusedBorderWidth',
      idSuffix: 'FocusedBorderWidth',
      tvOnly: true,
    },
    {
      value: 0,
      propertyType: 'focusedBorderRadius',
      idSuffix: 'FocusedBorderRadius',
      tvOnly: true,
    },
    {
      value: '0 0 0 rgba(0 0 0 / 0.0)',
      propertyType: 'dropShadow',
      idSuffix: 'DropShadow',
      optional: true,
    },
    {
      value: 'center',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: 'fitContent',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 20,
      tvValue: 28,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 20,
      tvValue: 28,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 10,
      tvValue: 16,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 10,
      tvValue: 16,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  closeButton: [
    {
      value: {
        function: 'namiClosePaywall',
      },
      propertyType: 'onTap',
      idSuffix: 'OnTap',
    },
    {
      value: 'transparent',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: 'transparent',
      propertyType: 'fillColorFallback',
      idSuffix: 'FillColorFallback',
      opacity: true,
      tvOnly: true,
      optional: true,
    },
    {
      value: 'transparent',
      propertyType: 'borderColor',
      idSuffix: 'BorderColor',
      opacity: true,
      optional: true,
    },
    {
      value: 0,
      propertyType: 'borderWidth',
      idSuffix: 'BorderWidth',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'borderRadius',
      idSuffix: 'BorderRadius',
      optional: true,
    },
    {
      value: '#eeeeee',
      propertyType: 'focusedFillColor',
      idSuffix: 'FocusedFillColor',
      opacity: true,
      tvOnly: true,
    },
    {
      value: '#eeeeee',
      propertyType: 'focusedFillColorFallback',
      idSuffix: 'FocusedFillColorFallback',
      tvOnly: true,
      optional: true,
    },
    {
      value: '#ffffff',
      propertyType: 'focusedBorderColor',
      idSuffix: 'FocusedBorderColor',
      opacity: true,
      tvOnly: true,
    },
    {
      value: 2,
      propertyType: 'focusedBorderWidth',
      idSuffix: 'FocusedBorderWidth',
      tvOnly: true,
    },
    {
      value: 0,
      propertyType: 'focusedBorderRadius',
      idSuffix: 'FocusedBorderRadius',
      tvOnly: true,
    },
    {
      value: '0 0 0 rgba(0 0 0 / 0.0)',
      propertyType: 'dropShadow',
      idSuffix: 'DropShadow',
      optional: true,
    },
    {
      value: 'center',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: 'fitContent',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 20,
      tvValue: 28,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 20,
      tvValue: 28,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 10,
      tvValue: 16,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 10,
      tvValue: 16,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  buttonText: [
    {
      value: 'body',
      propertyType: 'textType',
    },
    {
      value: 'Button text',
      propertyType: 'text',
      idSuffix: 'Text',
    },
    {
      value: 'Helvetica',
      propertyType: 'fontName',
      idSuffix: 'FontName',
    },
    {
      value: '#000000',
      propertyType: 'fontColor',
      idSuffix: 'FontColor',
    },
    {
      value: '#FFFFFF',
      propertyType: 'focusedFontColor',
      idSuffix: 'FocusedFontColor',
      tvOnly: true,
    },
    {
      value: '#000000',
      propertyType: 'linkColor',
      idSuffix: 'LinkColor',
    },
    {
      value: 16,
      tvValue: 24,
      propertyType: 'fontSize',
      idSuffix: 'FontSize',
    },
    {
      value: 'center',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: false,
      propertyType: 'strikethrough',
      idSuffix: 'Strikethrough',
      optional: true,
    },
    {
      value: 'fitContent',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 0,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  group: [
    {
      value: [],
      propertyType: 'components',
    },
    {
      value: 'transparent',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: 'transparent',
      propertyType: 'fillColorFallback',
      idSuffix: 'FillColorFallback',
      opacity: true,
      tvOnly: true,
      optional: true,
    },
    {
      value: '#eeeeee',
      propertyType: 'focusedFillColor',
      idSuffix: 'FocusedFillColor',
      opacity: true,
      tvOnly: true,
      optional: true,
    },
    {
      value: '#eeeeee',
      propertyType: 'focusedFillColorFallback',
      idSuffix: 'FocusedFillColorFallback',
      tvOnly: true,
      optional: true,
    },
    {
      value: '#ffffff',
      propertyType: 'focusedBorderColor',
      idSuffix: 'FocusedBorderColor',
      opacity: true,
      tvOnly: true,
      optional: true,
    },
    {
      value: 2,
      propertyType: 'focusedBorderWidth',
      idSuffix: 'FocusedBorderWidth',
      tvOnly: true,
      optional: true,
    },
    {
      value: 8,
      propertyType: 'focusedBorderRadius',
      idSuffix: 'FocusedBorderRadius',
      tvOnly: true,
      optional: true,
    },
    // {
    //   value: '${media.default}',
    //   propertyType: 'fillImage',
    //   idSuffix: 'FillImage',
    //   namespace: 'media',
    // },
    {
      value: 'transparent',
      propertyType: 'borderColor',
      idSuffix: 'BorderColor',
      opacity: true,
    },
    {
      value: 0,
      propertyType: 'borderWidth',
      idSuffix: 'BorderWidth',
    },
    {
      value: 0,
      propertyType: 'borderRadius',
      idSuffix: 'BorderRadius',
    },
    {
      value: ['upperLeft', 'upperRight', 'lowerLeft', 'lowerRight'],
      propertyType: 'roundBorders',
      idSuffix: 'RoundBorders',
      optional: true,
    },
    {
      value: '0 0 0 rgba(0 0 0 / 0.0)',
      propertyType: 'dropShadow',
      idSuffix: 'DropShadow',
      optional: true,
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 'top',
      propertyType: 'verticalAlignment',
      idSuffix: 'VerticalAlignment',
    },
    {
      value: 'center',
      propertyType: 'horizontalAlignment',
      idSuffix: 'HorizontalAlignment',
    },
    {
      value: '0%',
      propertyType: 'moveX',
      idSuffix: 'MoveX',
      optional: true,
    },
    {
      value: '0%',
      propertyType: 'moveY',
      idSuffix: 'MoveY',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'zIndex',
      idSuffix: 'ZIndex',
      optional: true,
    },
    {
      value: 'horizontal',
      propertyType: 'direction',
      idSuffix: 'Direction',
    },
    {
      value: 10,
      tvValue: 16,
      propertyType: 'spacing',
      idSuffix: 'Spacing',
    },
    {
      value: 0,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 0,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 0,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 0,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  productWrapper: [
    {
      value: [],
      propertyType: 'components',
    },
    {
      value: 'transparent',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: 'transparent',
      propertyType: 'fillColorFallback',
      idSuffix: 'FillColorFallback',
      opacity: true,
      tvOnly: true,
      optional: true,
    },
    {
      value: '#eeeeee',
      propertyType: 'focusedFillColor',
      idSuffix: 'FocusedFillColor',
      opacity: true,
      tvOnly: true,
      optional: true,
    },
    {
      value: '#eeeeee',
      propertyType: 'focusedFillColorFallback',
      idSuffix: 'FocusedFillColorFallback',
      tvOnly: true,
      optional: true,
    },
    {
      value: 'transparent',
      propertyType: 'borderColor',
      idSuffix: 'BorderColor',
      opacity: true,
    },
    {
      value: '#ffffff',
      propertyType: 'focusedBorderColor',
      idSuffix: 'FocusedBorderColor',
      opacity: true,
      tvOnly: true,
      optional: true,
    },
    {
      value: 0,
      propertyType: 'borderWidth',
      idSuffix: 'BorderWidth',
    },
    {
      value: 2,
      propertyType: 'focusedBorderWidth',
      idSuffix: 'FocusedBorderWidth',
      tvOnly: true,
      optional: true,
    },
    {
      value: 0,
      propertyType: 'borderRadius',
      idSuffix: 'BorderRadius',
    },
    {
      value: 0,
      propertyType: 'focusedBorderRadius',
      idSuffix: 'FocusedBorderRadius',
      tvOnly: true,
      optional: true,
    },
    {
      value: ['upperLeft', 'upperRight', 'lowerLeft', 'lowerRight'],
      propertyType: 'roundBorders',
      idSuffix: 'RoundBorders',
      optional: true,
    },
    {
      value: '0 0 0 rgba(0 0 0 / 0)',
      propertyType: 'dropShadow',
      idSuffix: 'DropShadow',
      optional: true,
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 'top',
      propertyType: 'verticalAlignment',
      idSuffix: 'VerticalAlignment',
    },
    {
      value: 'center',
      propertyType: 'horizontalAlignment',
      idSuffix: 'HorizontalAlignment',
    },
    {
      value: 'horizontal',
      propertyType: 'direction',
      idSuffix: 'Direction',
    },
    {
      value: 5,
      tvValue: 10,
      propertyType: 'spacing',
      idSuffix: 'Spacing',
    },
    {
      value: '0%',
      propertyType: 'moveX',
      idSuffix: 'MoveX',
      optional: true,
    },
    {
      value: '0%',
      propertyType: 'moveY',
      idSuffix: 'MoveY',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'zIndex',
      idSuffix: 'ZIndex',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 0,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 0,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 0,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  productContainer: [
    {
      value: 'Repeating Product Group',
      propertyType: 'title',
    },
    {
      value: [],
      propertyType: 'components',
    },
    {
      value: 'all',
      propertyType: 'products',
    },
    {
      value: 20,
      propertyType: 'spacing',
      idSuffix: 'Spacing',
    },
    {
      value: 'top',
      propertyType: 'verticalAlignment',
      idSuffix: 'VerticalAlignment',
    },
    {
      value: 'center',
      propertyType: 'horizontalAlignment',
      idSuffix: 'HorizontalAlignment',
    },
    {
      value: 'horizontal',
      propertyType: 'direction',
      idSuffix: 'Direction',
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
    },
    {
      value: 0,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 0,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 0,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 0,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  productButton: [
    {
      value: {
        function: 'namiBuySKU',
      },
      propertyType: 'onTap',
      selectedValue: {
        function: 'namiSelectSKU',
      },
      promoValue: {
        function: 'namiBuySKU',
        parameters: {
          promo: '${sku.promoKey}',
        },
      },
    },
    {
      value: [],
      propertyType: 'components',
    },
    {
      value: 'transparent',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      featuredValue: '#cacaca',
      selectedValue: 'transparent',
      opacity: true,
    },
    {
      value: 'transparent',
      propertyType: 'fillColorFallback',
      idSuffix: 'FillColorFallback',
      opacity: true,
      tvOnly: true,
      optional: true,
    },
    {
      value: '#9b9b9b',
      propertyType: 'borderColor',
      idSuffix: 'BorderColor',
      featuredValue: '#9b9b9b',
      selectedValue: '#1374de',
      opacity: true,
    },
    {
      value: 2,
      tvValue: 2,
      propertyType: 'borderWidth',
      idSuffix: 'BorderWidth',
      featuredValue: 1,
      selectedValue: 2,
    },
    {
      value: 8,
      tvValue: 16,
      propertyType: 'borderRadius',
      idSuffix: 'BorderRadius',
      featuredValue: 8,
      selectedValue: 8,
    },
    {
      value: '#eeeeee',
      propertyType: 'focusedFillColor',
      idSuffix: 'FocusedFillColor',
      opacity: true,
      tvOnly: true,
    },
    {
      value: '#eeeeee',
      propertyType: 'focusedFillColorFallback',
      idSuffix: 'FocusedFillColorFallback',
      tvOnly: true,
      optional: true,
    },
    {
      value: '#ffffff',
      propertyType: 'focusedBorderColor',
      idSuffix: 'FocusedBorderColor',
      opacity: true,
      tvOnly: true,
    },
    {
      value: 2,
      propertyType: 'focusedBorderWidth',
      idSuffix: 'FocusedBorderWidth',
      tvOnly: true,
    },
    {
      value: 8,
      propertyType: 'focusedBorderRadius',
      idSuffix: 'FocusedBorderRadius',
      tvOnly: true,
    },
    {
      value: ['upperLeft', 'upperRight', 'lowerLeft', 'lowerRight'],
      propertyType: 'roundBorders',
      idSuffix: 'RoundBorders',
      optional: true,
    },
    {
      value: '0 0 0 rgba(0 0 0 / 0.0)',
      propertyType: 'dropShadow',
      idSuffix: 'DropShadow',
      optional: true,
    },
    {
      value: 'center',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: 'vertical',
      propertyType: 'direction',
      idSuffix: 'Direction',
    },
    {
      value: 10,
      propertyType: 'spacing',
      idSuffix: 'Spacing',
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 125,
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 15,
      tvValue: 28,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 15,
      tvValue: 28,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 0,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 0,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  image: [
    {
      propertyType: 'url',
      value: '${media.default}',
    },
    {
      propertyType: 'imageCropping',
      idSuffix: 'Cropping',
      value: 'fit',
    },
    {
      propertyType: 'alignment',
      idSuffix: 'Alignment',
      value: 'center',
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 100,
      tvValue: 150,
      propertyType: 'height',
      idSuffix: 'Height',
    },
  ],
  hostedImage: [
    {
      propertyType: 'url',
      idSuffix: 'Url',
      value: '',
    },
    {
      propertyType: 'imageCropping',
      idSuffix: 'Cropping',
      value: 'fit',
    },
    {
      propertyType: 'alignment',
      idSuffix: 'Alignment',
      value: 'center',
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 50,
      tvValue: 75,
      propertyType: 'height',
      idSuffix: 'Height',
    },
  ],
  videoUrl: [
    {
      propertyType: 'url',
      idSuffix: 'Url',
      value: '',
    },
    {
      propertyType: 'imageCropping',
      idSuffix: 'Cropping',
      value: 'fit',
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 200,
      tvValue: 400,
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      propertyType: 'fallbackImage',
      value: '${media.default}',
    },
    {
      propertyType: 'controlsType',
      idSuffix: 'ControlsType',
      value: 'none',
    },
    {
      propertyType: 'autoplayVideo',
      idSuffix: 'Autoplay',
      value: true,
    },
    {
      propertyType: 'mute',
      idSuffix: 'Mute',
      value: true,
    },
    {
      propertyType: 'loopVideo',
      idSuffix: 'Loop',
      value: true,
    },
  ],
  productBadge: [
    {
      value: 'body',
      propertyType: 'textType',
    },
    {
      value: 'BEST VALUE',
      formType: 'text',
      propertyType: 'text',
      idSuffix: 'Text',
      namespace: 'sku',
      trialValue: '${sku.freeTrialDuration} free',
      introValue: 'Introductory offer',
      promoValue: 'Special offer',
    },
    {
      value: 'Helvetica',
      propertyType: 'fontName',
      idSuffix: 'FontName',
    },
    {
      value: '#f0f0f0',
      propertyType: 'fontColor',
      idSuffix: 'FontColor',
    },
    {
      value: '#FFFFFF',
      propertyType: 'focusedFontColor',
      idSuffix: 'FocusedFontColor',
      tvOnly: true,
    },
    {
      value: 14,
      tvValue: 20,
      propertyType: 'fontSize',
      idSuffix: 'FontSize',
    },
    {
      value: 'center',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: false,
      propertyType: 'strikethrough',
      idSuffix: 'Strikethrough',
    },
    {
      value: '#1374de',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: '#eeeeee',
      propertyType: 'focusedFillColor',
      idSuffix: 'FocusedFillColor',
      opacity: true,
      tvOnly: true,
    },
    {
      value: '#eeeeee',
      propertyType: 'focusedFillColorFallback',
      idSuffix: 'FocusedFillColorFallback',
      tvOnly: true,
      optional: true,
    },
    {
      value: 'transparent',
      propertyType: 'borderColor',
      idSuffix: 'BorderColor',
      opacity: true,
    },
    {
      value: 'transparent',
      propertyType: 'focusedBorderColor',
      idSuffix: 'FocusedBorderColor',
      opacity: true,
      tvOnly: true,
    },
    {
      value: 0,
      propertyType: 'borderWidth',
      idSuffix: 'BorderWidth',
    },
    {
      value: 0,
      propertyType: 'focusedBorderWidth',
      idSuffix: 'FocusedBorderWidth',
      tvOnly: true,
    },
    {
      value: 20,
      tvValue: 24,
      propertyType: 'borderRadius',
      idSuffix: 'BorderRadius',
    },
    {
      value: 24,
      propertyType: 'focusedBorderRadius',
      idSuffix: 'FocusedBorderRadius',
      tvOnly: true,
    },
    {
      value: ['upperLeft', 'upperRight', 'lowerLeft', 'lowerRight'],
      propertyType: 'roundBorders',
      idSuffix: 'RoundBorders',
      optional: true,
    },
    {
      value: '0 0 0 rgba(0 0 0 / 0.0)',
      propertyType: 'dropShadow',
      idSuffix: 'DropShadow',
      optional: true,
    },
    {
      value: 'fitContent',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: '0%',
      propertyType: 'moveX',
      idSuffix: 'MoveX',
    },
    {
      value: '0%',
      propertyType: 'moveY',
      idSuffix: 'MoveY',
    },
    {
      value: 1,
      propertyType: 'zIndex',
      idSuffix: 'ZIndex',
    },
    {
      value: 15,
      tvValue: 28,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 15,
      tvValue: 28,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 6,
      tvValue: 12,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 6,
      tvValue: 12,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  textBlock: [
    {
      value: 'body',
      propertyType: 'textType',
    },
    {
      value: 'This is a text item',
      formType: 'text',
      propertyType: 'text',
      idSuffix: 'Text',
    },
    {
      value: 'Helvetica',
      propertyType: 'fontName',
      idSuffix: 'FontName',
    },
    {
      value: '#f0f0f0',
      propertyType: 'fontColor',
      idSuffix: 'FontColor',
    },
    {
      value: 14,
      tvValue: 24,
      propertyType: 'fontSize',
      idSuffix: 'FontSize',
    },
    {
      value: 'center',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: false,
      propertyType: 'strikethrough',
      idSuffix: 'Strikethrough',
      optional: true,
    },
    {
      value: '#1374de',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: 'transparent',
      propertyType: 'borderColor',
      idSuffix: 'BorderColor',
      opacity: true,
    },
    {
      value: 0,
      propertyType: 'borderWidth',
      idSuffix: 'BorderWidth',
    },
    {
      value: 20,
      tvValue: 24,
      propertyType: 'borderRadius',
      idSuffix: 'BorderRadius',
    },
    {
      value: ['upperLeft', 'upperRight', 'lowerLeft', 'lowerRight'],
      propertyType: 'roundBorders',
      idSuffix: 'RoundBorders',
      optional: true,
    },
    {
      value: '0 0 5 rgba(0 0 0 / 0.2)',
      propertyType: 'dropShadow',
      idSuffix: 'DropShadow',
      optional: true,
    },
    {
      value: 'fitContent',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: '0%',
      propertyType: 'moveX',
      idSuffix: 'MoveX',
      optional: true,
    },
    {
      value: '0%',
      propertyType: 'moveY',
      idSuffix: 'MoveY',
      optional: true,
    },
    {
      value: 1,
      propertyType: 'zIndex',
      idSuffix: 'ZIndex',
      optional: true,
    },
    {
      value: 15,
      tvValue: 28,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 15,
      tvValue: 28,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 15,
      tvValue: 24,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 15,
      tvValue: 24,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  condition: [],
  purchaseButton: [
    {
      value: {
        function: 'namiPurchaseSelectedSKU',
      },
      propertyType: 'onTap',
    },
    {
      value: [],
      propertyType: 'components',
    },
    {
      value: '#1374de',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: '#1374de',
      propertyType: 'borderColor',
      idSuffix: 'BorderColor',
      opacity: true,
    },
    {
      value: 1,
      tvValue: 2,
      propertyType: 'borderWidth',
      idSuffix: 'BorderWidth',
    },
    {
      value: 8,
      tvValue: 20,
      propertyType: 'borderRadius',
      idSuffix: 'BorderRadius',
    },
    {
      value: '0 0 5 rgba(0 0 0 / 0.2)',
      propertyType: 'dropShadow',
      idSuffix: 'DropShadow',
    },
    {
      value: 'center',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 15,
      tvValue: 28,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 15,
      tvValue: 28,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 15,
      tvValue: 28,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 15,
      tvValue: 28,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  spacer: [
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
      optional: true,
    },
    {
      value: 20,
      tvValue: 100,
      propertyType: 'height',
      idSuffix: 'Height',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
  ],
  carousel: [
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
    },
    {
      value: 0,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 0,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 0,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 0,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
    {
      value: true,
      propertyType: 'autoplay',
      idSuffix: 'Autoplay',
    },
    {
      value: 5,
      propertyType: 'autoplaySeconds',
      idSuffix: 'AutoplaySeconds',
    },
    {
      value: 'carouselName',
      propertyType: 'ui.name',
    },
    {
      value: '${state.slides.${state.currentGroupId}.carouselName}',
      propertyType: 'loopSource',
    },
    {
      value: 'slide',
      propertyType: 'loopVariable',
    },
    {
      value: true,
      propertyType: 'showIndicators',
      idSuffix: 'ShowIndicators',
    },
    {
      value: '#ffffff',
      propertyType: 'indicatorColor',
      idSuffix: 'IndicatorColor',
    },
    {
      value: '#767777',
      propertyType: 'activeIndicatorColor',
      idSuffix: 'ActiveIndicatorColor',
    },
  ],
  slide: [
    {
      value: [],
      propertyType: 'components',
    },
    {
      value: '${slide.image}',
      propertyType: 'fillImage',
      idSuffix: 'FillImage',
      namespace: 'slide',
    },
    {
      value: 'transparent',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: 'transparent',
      propertyType: 'borderColor',
      idSuffix: 'BorderColor',
      opacity: true,
    },
    {
      value: 0,
      propertyType: 'borderWidth',
      idSuffix: 'BorderWidth',
    },
    {
      value: 0,
      propertyType: 'borderRadius',
      idSuffix: 'BorderRadius',
    },
    {
      value: '0 0 0 rgba(0 0 0 / 0.0)',
      propertyType: 'dropShadow',
      idSuffix: 'DropShadow',
      optional: true,
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 300,
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 'vertical',
      propertyType: 'direction',
      idSuffix: 'Direction',
    },
    {
      value: 10,
      propertyType: 'spacing',
      idSuffix: 'Spacing',
    },
    {
      value: 0,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 0,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 0,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 0,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  divider: [
    {
      value: 'rgb(255 255 255 / 0.15)',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 2,
      tvValue: 4,
      propertyType: 'height',
      idSuffix: 'Height',
    },
  ],
  productGroupToggle: [
    {
      value: 'rgb(0 0 0 / 0.75)',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: 'rgba(255 255 255 / 0.3)',
      propertyType: 'borderColor',
      idSuffix: 'BorderColor',
      opacity: true,
    },
    {
      value: 2,
      propertyType: 'borderWidth',
      idSuffix: 'BorderWidth',
    },
    {
      value: 30,
      propertyType: 'borderRadius',
      idSuffix: 'BorderRadius',
    },
    {
      value: '0 0 0 rgba(0 0 0 / 0.0)',
      propertyType: 'dropShadow',
      idSuffix: 'DropShadow',
    },
    {
      value: 200,
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 50,
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 2,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 2,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 2,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 2,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
    {
      value: 'horizontal',
      propertyType: 'direction',
    },
    {
      propertyType: 'onTap',
      value: {
        function: 'setState',
        parameters: {
          partialState: {
            currentGroupId: '${inactiveSegmentPickerId}',
          },
        },
      },
    },
  ],
  productGroupToggleItem: [
    {
      value: '${state.groups.0.displayName}',
      propertyType: 'text',
    },
    {
      value: 'Helvetica',
      propertyType: 'activeFontName',
      idSuffix: 'ActiveFontName',
    },
    {
      value: '100%',
      propertyType: 'width',
    },
    {
      value: '100%',
      propertyType: 'height',
    },
    {
      value: '#000000',
      propertyType: 'activeFontColor',
      idSuffix: 'ActiveFontColor',
    },
    {
      value: 14,
      propertyType: 'activeFontSize',
      idSuffix: 'ActiveFontSize',
    },
    {
      value: '#ffffff',
      propertyType: 'activeFillColor',
      idSuffix: 'ActiveFillColor',
    },
    {
      value: '#ffffff',
      propertyType: 'activeBorderColor',
      idSuffix: 'ActiveBorderColor',
      opacity: true,
    },
    {
      value: 0,
      propertyType: 'activeBorderWidth',
      idSuffix: 'ActiveBorderWidth',
    },
    {
      value: 30,
      propertyType: 'activeBorderRadius',
      idSuffix: 'ActiveBorderRadius',
    },
    {
      value: ['upperLeft', 'upperRight', 'lowerLeft', 'lowerRight'],
      propertyType: 'activeRoundBorders',
      idSuffix: 'ActiveRoundBorders',
    },
    {
      value: 'Helvetica',
      propertyType: 'inactiveFontName',
      idSuffix: 'InactiveFontName',
    },
    {
      value: '#ffffff',
      propertyType: 'inactiveFontColor',
      idSuffix: 'InactiveFontColor',
    },
    {
      value: 14,
      propertyType: 'inactiveFontSize',
      idSuffix: 'InactiveFontSize',
    },
    // {
    //   value: '#ffffff',
    //   propertyType: 'inactiveBorderColor',
    //   idSuffix: 'InactiveBorderColor',
    //   opacity: true,
    // },
    {
      value: 0,
      propertyType: 'inactiveBorderWidth',
    },
    {
      value: 30,
      propertyType: 'inactiveBorderRadius',
    },
    // {
    //   value: ['upperLeft', 'upperRight', 'lowerLeft', 'lowerRight'],
    //   propertyType: 'inactiveRoundBorders',
    //   idSuffix: 'InactiveRoundBorders',
    // },
  ],
  linkButton: [
    {
      value: 'body',
      propertyType: 'textType',
    },
    {
      value: '[Get Support](https://myapp.com/sample-link)',
      propertyType: 'text',
      idSuffix: 'Text',
    },
    {
      value: 'Helvetica',
      propertyType: 'fontName',
      idSuffix: 'FontName',
    },
    {
      value: '#000000',
      propertyType: 'fontColor',
      idSuffix: 'FontColor',
    },
    {
      value: '#000000',
      propertyType: 'linkColor',
      idSuffix: 'LinkColor',
    },
    {
      value: 14,
      propertyType: 'fontSize',
      idSuffix: 'FontSize',
    },
    {
      value: 'center',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: false,
      propertyType: 'strikethrough',
      idSuffix: 'Strikethrough',
      optional: true,
    },
    {
      value: 'transparent',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: 'transparent',
      propertyType: 'borderColor',
      idSuffix: 'BorderColor',
      opacity: true,
    },
    {
      value: 0,
      propertyType: 'borderWidth',
      idSuffix: 'BorderWidth',
    },
    {
      value: 0,
      propertyType: 'borderRadius',
      idSuffix: 'BorderRadius',
    },
    {
      value: ['upperLeft', 'upperRight', 'lowerLeft', 'lowerRight'],
      propertyType: 'roundBorders',
      idSuffix: 'RoundBorders',
      optional: true,
    },
    {
      value: '0 0 0 rgba(0 0 0 / 0.0)',
      propertyType: 'dropShadow',
      idSuffix: 'DropShadow',
      optional: true,
    },
    {
      value: 'fitContent',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: '0%',
      propertyType: 'moveX',
      idSuffix: 'MoveX',
      optional: true,
    },
    {
      value: '0%',
      propertyType: 'moveY',
      idSuffix: 'MoveY',
      optional: true,
    },
    {
      value: 1,
      propertyType: 'zIndex',
      idSuffix: 'ZIndex',
      optional: true,
    },
    {
      value: 15,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 15,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 15,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 15,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  deeplinkButton: [
    {
      value: {
        function: 'namiDeeplink',
        parameters: {
          url: '${var.deeplinkButtonUrl}',
        },
      },
      propertyType: 'onTap',
    },
    {
      propertyType: 'url',
      idSuffix: 'Url',
      value: '',
    },
    {
      value: 'transparent',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: 'transparent',
      propertyType: 'fillColorFallback',
      idSuffix: 'FillColorFallback',
      opacity: true,
      tvOnly: true,
      optional: true,
    },
    {
      value: 'transparent',
      propertyType: 'borderColor',
      idSuffix: 'BorderColor',
      opacity: true,
    },
    {
      value: 0,
      propertyType: 'borderWidth',
      idSuffix: 'BorderWidth',
    },
    {
      value: 0,
      propertyType: 'borderRadius',
      idSuffix: 'BorderRadius',
    },
    {
      value: '#eeeeee',
      propertyType: 'focusedFillColor',
      idSuffix: 'FocusedFillColor',
      opacity: true,
      tvOnly: true,
    },
    {
      value: '#eeeeee',
      propertyType: 'focusedFillColorFallback',
      idSuffix: 'FocusedFillColorFallback',
      tvOnly: true,
      optional: true,
    },
    {
      value: '#ffffff',
      propertyType: 'focusedBorderColor',
      idSuffix: 'FocusedBorderColor',
      opacity: true,
      tvOnly: true,
    },
    {
      value: 2,
      propertyType: 'focusedBorderWidth',
      idSuffix: 'FocusedBorderWidth',
      tvOnly: true,
    },
    {
      value: 0,
      propertyType: 'focusedBorderRadius',
      idSuffix: 'FocusedBorderRadius',
      tvOnly: true,
    },
    {
      value: '0 0 0 rgba(0 0 0 / 0.0)',
      propertyType: 'dropShadow',
      idSuffix: 'DropShadow',
      optional: true,
    },
    {
      value: 'center',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 20,
      tvValue: 28,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 20,
      tvValue: 28,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 10,
      tvValue: 16,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 10,
      tvValue: 16,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  navigatePageButton: [
    {
      value: {
        function: 'namiNavigateToScreen',
        parameters: {
          screen: 'page2', //TODO - handle navigating back to page1
        },
      },
      propertyType: 'onTap',
    },
    {
      value: 'transparent',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: 'transparent',
      propertyType: 'fillColorFallback',
      idSuffix: 'FillColorFallback',
      opacity: true,
      tvOnly: true,
      optional: true,
    },
    {
      value: 'transparent',
      propertyType: 'borderColor',
      idSuffix: 'BorderColor',
      opacity: true,
    },
    {
      value: 0,
      propertyType: 'borderWidth',
      idSuffix: 'BorderWidth',
    },
    {
      value: 0,
      propertyType: 'borderRadius',
      idSuffix: 'BorderRadius',
    },
    {
      value: '#eeeeee',
      propertyType: 'focusedFillColor',
      idSuffix: 'FocusedFillColor',
      opacity: true,
      tvOnly: true,
    },
    {
      value: '#eeeeee',
      propertyType: 'focusedFillColorFallback',
      idSuffix: 'FocusedFillColorFallback',
      tvOnly: true,
      optional: true,
    },
    {
      value: '#ffffff',
      propertyType: 'focusedBorderColor',
      idSuffix: 'FocusedBorderColor',
      opacity: true,
      tvOnly: true,
    },
    {
      value: 2,
      propertyType: 'focusedBorderWidth',
      idSuffix: 'FocusedBorderWidth',
      tvOnly: true,
    },
    {
      value: 0,
      propertyType: 'focusedBorderRadius',
      idSuffix: 'FocusedBorderRadius',
      tvOnly: true,
    },
    {
      value: '0 0 0 rgba(0 0 0 / 0.0)',
      propertyType: 'dropShadow',
      idSuffix: 'DropShadow',
      optional: true,
    },
    {
      value: 'center',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 20,
      tvValue: 28,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 20,
      tvValue: 28,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 10,
      tvValue: 16,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 10,
      tvValue: 16,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  footer: [
    {
      value: [],
      propertyType: 'components',
    },
    {
      value: '#eeeeee',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: 'transparent',
      propertyType: 'borderColor',
      idSuffix: 'BorderColor',
      opacity: true,
      optional: true,
    },
    {
      value: 0,
      propertyType: 'borderWidth',
      idSuffix: 'BorderWidth',
      optional: true,
    },
    {
      value: 15,
      propertyType: 'borderRadius',
      idSuffix: 'BorderRadius',
    },
    {
      value: ['upperLeft', 'upperRight'],
      propertyType: 'roundBorders',
      idSuffix: 'RoundBorders',
    },
    {
      value: '0 0 0 rgba(0 0 0 / 0.0)',
      propertyType: 'dropShadow',
      idSuffix: 'DropShadow',
      optional: true,
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 'vertical',
      propertyType: 'direction',
      idSuffix: 'Direction',
    },
    {
      value: 15,
      propertyType: 'spacing',
      idSuffix: 'Spacing',
    },
    {
      value: 20,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 20,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 20,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 40,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  collapse: [
    {
      value: [],
      propertyType: 'components',
    },
    {
      value: [],
      propertyType: 'open',
      idSuffix: 'HeaderIds',
      namespace: 'state',
    },
    {
      value: '100%',
      propertyType: 'width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
    },
    {
      value: 'vertical',
      propertyType: 'direction',
    },
    {
      value: 'transparent',
      propertyType: 'borderColor',
      idSuffix: 'BorderColor',
      opacity: true,
    },
    {
      value: 0,
      propertyType: 'borderWidth',
      idSuffix: 'BorderWidth',
    },
    {
      value: 0,
      propertyType: 'borderRadius',
      idSuffix: 'BorderRadius',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  collapseHeader: [
    {
      value: [],
      propertyType: 'components',
    },
    {
      value: '100%',
      propertyType: 'width',
    },
    {
      value: 4,
      propertyType: 'spacing',
      idSuffix: 'Spacing',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: '#dcdcdc',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: 0,
      propertyType: 'borderRadius',
      idSuffix: 'BorderRadius',
    },
    {
      value: ['lowerLeft', 'lowerRight', 'upperLeft', 'upperRight'],
      propertyType: 'roundBorders',
      idSuffix: 'RoundBorders',
    },
    {
      value: 'horizontal',
      propertyType: 'direction',
      idSuffix: 'Direction',
    },
    {
      value: 15,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 15,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 15,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 15,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
  ],
  collapseBody: [
    {
      value: [],
      propertyType: 'components',
    },
    {
      value: '#dcdcdc',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: 0,
      propertyType: 'borderRadius',
      idSuffix: 'BorderRadius',
    },
    {
      value: ['lowerLeft', 'lowerRight'],
      propertyType: 'roundBorders',
      idSuffix: 'RoundBorders',
    },
    {
      value: '100%',
      propertyType: 'width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
    },
    {
      value: 'center',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: 'vertical',
      propertyType: 'direction',
      idSuffix: 'Direction',
    },
    {
      value: 10,
      propertyType: 'spacing',
      idSuffix: 'Spacing',
    },
    {
      value: 15,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 15,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 15,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 15,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  stack: [
    {
      value: [],
      propertyType: 'components',
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 300,
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 'vertical',
      propertyType: 'direction',
    },
    {
      value: 0,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
      optional: true,
    },
  ],
  repeatingList: [
    {
      value: [],
      propertyType: 'components',
    },
    //TODO
    // {
    //   value: '${state.slides.${state.currentGroupId}.carouselName}',
    //   propertyType: 'loopSource',
    // },
    {
      value: 'block',
      propertyType: 'loopVariable',
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 'vertical',
      propertyType: 'direction',
      idSuffix: 'Direction',
    },
    {
      value: 10,
      propertyType: 'spacing',
      idSuffix: 'Spacing',
    },
  ],
  repeatingTextSource: [
    {
      value: 'body',
      propertyType: 'textType',
    },
    {
      value: 'Helvetica',
      propertyType: 'fontName',
      idSuffix: 'FontName',
    },
    {
      value: '#000000',
      propertyType: 'fontColor',
      idSuffix: 'FontColor',
      featuredValue: '#000000',
    },
    {
      value: 16,
      propertyType: 'fontSize',
      idSuffix: 'FontSize',
    },
    {
      value: 'left',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 'EEEE, MMM d',
      propertyType: 'dateTimeFormat',
      idSuffix: 'DateTimeFormat',
      optional: true,
    },
    {
      value: false,
      propertyType: 'strikethrough',
      idSuffix: 'Strikethrough',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
    {
      value: '0%',
      propertyType: 'moveX',
      idSuffix: 'MoveX',
      optional: true,
    },
    {
      value: '0%',
      propertyType: 'moveY',
      idSuffix: 'MoveY',
      optional: true,
    },
  ],
  repeatingImageSource: [
    {
      propertyType: 'imageCropping',
      idSuffix: 'Cropping',
      value: 'fit',
    },
    {
      propertyType: 'alignment',
      idSuffix: 'Alignment',
      value: 'center',
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 50,
      propertyType: 'height',
      idSuffix: 'Height',
    },
  ],
  playPauseButton: [
    {
      value: {
        function: 'namiPlayVideo',
      },
      propertyType: 'pausedOnTap',
    },
    {
      value: {
        function: 'namiPauseVideo',
      },
      propertyType: 'playingOnTap',
    },
    {
      value: 'rgba(23 32 41 / 0.4)',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: 'transparent',
      propertyType: 'borderColor',
      idSuffix: 'BorderColor',
      opacity: true,
    },
    {
      value: 0,
      propertyType: 'borderWidth',
      idSuffix: 'BorderWidth',
    },
    {
      value: 6,
      propertyType: 'borderRadius',
      idSuffix: 'BorderRadius',
    },
    {
      value: '#eeeeee',
      propertyType: 'focusedFillColor',
      idSuffix: 'FocusedFillColor',
      opacity: true,
      tvOnly: true,
    },
    {
      value: '#ffffff',
      propertyType: 'focusedBorderColor',
      idSuffix: 'FocusedBorderColor',
      opacity: true,
      tvOnly: true,
    },
    {
      value: 2,
      propertyType: 'focusedBorderWidth',
      idSuffix: 'FocusedBorderWidth',
      tvOnly: true,
    },
    {
      value: 0,
      propertyType: 'focusedBorderRadius',
      idSuffix: 'FocusedBorderRadius',
      tvOnly: true,
    },
    {
      value: '0 0 0 rgba(0 0 0 / 0.0)',
      propertyType: 'dropShadow',
      idSuffix: 'DropShadow',
      optional: true,
    },
    {
      value: 'center',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: 'fitContent',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 8,
      tvValue: 16,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 8,
      tvValue: 18,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 8,
      tvValue: 16,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 8,
      tvValue: 16,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  volumeButton: [
    {
      value: {
        function: 'namiMuteVideo',
      },
      propertyType: 'volumeOnTap',
    },
    {
      value: {
        function: 'namiUnmuteVideo',
      },
      propertyType: 'mutedOnTap',
    },
    {
      value: 'rgba(23 32 41 / 0.4)',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: 'transparent',
      propertyType: 'borderColor',
      idSuffix: 'BorderColor',
      opacity: true,
    },
    {
      value: 0,
      propertyType: 'borderWidth',
      idSuffix: 'BorderWidth',
    },
    {
      value: 6,
      propertyType: 'borderRadius',
      idSuffix: 'BorderRadius',
    },
    {
      value: '#eeeeee',
      propertyType: 'focusedFillColor',
      idSuffix: 'FocusedFillColor',
      opacity: true,
      tvOnly: true,
    },
    {
      value: '#ffffff',
      propertyType: 'focusedBorderColor',
      idSuffix: 'FocusedBorderColor',
      opacity: true,
      tvOnly: true,
    },
    {
      value: 2,
      propertyType: 'focusedBorderWidth',
      idSuffix: 'FocusedBorderWidth',
      tvOnly: true,
    },
    {
      value: 0,
      propertyType: 'focusedBorderRadius',
      idSuffix: 'FocusedBorderRadius',
      tvOnly: true,
    },
    {
      value: '0 0 0 rgba(0 0 0 / 0.0)',
      propertyType: 'dropShadow',
      idSuffix: 'DropShadow',
      optional: true,
    },
    {
      value: 'center',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: 'fitContent',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 8,
      tvValue: 16,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 8,
      tvValue: 18,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 8,
      tvValue: 16,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 8,
      tvValue: 16,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  flowButton: [
    {
      value: {
        function: 'namiFlowSkipStep',
      },
      propertyType: 'onTap',
      idSuffix: 'OnTap',
    },
    {
      value: 'transparent',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: 'transparent',
      propertyType: 'fillColorFallback',
      idSuffix: 'FillColorFallback',
      opacity: true,
      tvOnly: true,
      optional: true,
    },
    {
      value: 'transparent',
      propertyType: 'borderColor',
      idSuffix: 'BorderColor',
      opacity: true,
    },
    {
      value: 0,
      propertyType: 'borderWidth',
      idSuffix: 'BorderWidth',
    },
    {
      value: 0,
      propertyType: 'borderRadius',
      idSuffix: 'BorderRadius',
    },
    {
      value: '#eeeeee',
      propertyType: 'focusedFillColor',
      idSuffix: 'FocusedFillColor',
      opacity: true,
      tvOnly: true,
    },
    {
      value: '#eeeeee',
      propertyType: 'focusedFillColorFallback',
      idSuffix: 'FocusedFillColorFallback',
      tvOnly: true,
      optional: true,
    },
    {
      value: '#ffffff',
      propertyType: 'focusedBorderColor',
      idSuffix: 'FocusedBorderColor',
      opacity: true,
      tvOnly: true,
    },
    {
      value: 2,
      propertyType: 'focusedBorderWidth',
      idSuffix: 'FocusedBorderWidth',
      tvOnly: true,
    },
    {
      value: 0,
      propertyType: 'focusedBorderRadius',
      idSuffix: 'FocusedBorderRadius',
      tvOnly: true,
    },
    {
      value: '0 0 0 rgba(0 0 0 / 0.0)',
      propertyType: 'dropShadow',
      idSuffix: 'DropShadow',
      optional: true,
    },
    {
      value: 'center',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 20,
      tvValue: 28,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 20,
      tvValue: 28,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 10,
      tvValue: 16,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 10,
      tvValue: 16,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
      optional: true,
    },
  ],
  carouselTitleText: [
    {
      value: 'title',
      propertyType: 'textType',
    },
    {
      value: 'Slide Title',
      propertyType: 'text',
      idSuffix: 'TitleText',
      namespace: 'slide',
    },
    {
      value: 'Helvetica',
      propertyType: 'fontName',
      idSuffix: 'FontName',
    },
    {
      value: '#000000',
      propertyType: 'fontColor',
      idSuffix: 'FontColor',
    },
    {
      value: 24,
      propertyType: 'fontSize',
      idSuffix: 'FontSize',
    },
    {
      value: 'center',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 0,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 0,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 0,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 0,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
    },
  ],
  carouselBodyText: [
    {
      value: 'body',
      propertyType: 'textType',
    },
    {
      value: 'Slide Subtitle',
      propertyType: 'text',
      idSuffix: 'SubtitleText',
      namespace: 'slide',
    },
    {
      value: 'Helvetica',
      propertyType: 'fontName',
      idSuffix: 'FontName',
    },
    {
      value: '#000000',
      propertyType: 'fontColor',
      idSuffix: 'FontColor',
    },
    {
      value: 18,
      propertyType: 'fontSize',
      idSuffix: 'FontSize',
    },
    {
      value: 'center',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 0,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 0,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 0,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 35,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
    },
    {
      value: 25,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
    },
  ],
  carouselImage: [
    {
      propertyType: 'url',
      value: '${media.default}',
      namespace: 'slide',
      idSuffix: 'FillImage',
    },
    {
      propertyType: 'imageCropping',
      idSuffix: 'Cropping',
      value: 'fit',
    },
    {
      propertyType: 'alignment',
      idSuffix: 'Alignment',
      value: 'center',
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 100,
      tvValue: 150,
      propertyType: 'height',
      idSuffix: 'Height',
    },
  ],
  carouselBulletList: [
    {
      value: 'body', //TODO - not sure what text type is expected for list
      propertyType: 'textType',
    },
    {
      value: [
        'This is a list',
        'List your *best* features',
        'Keep the items short!',
      ],
      propertyType: 'texts',
      idSuffix: 'Text',
      namespace: 'slide',
    },
    {
      value: 'Helvetica',
      propertyType: 'fontName',
      idSuffix: 'FontName',
    },
    {
      value: '#000000',
      propertyType: 'fontColor',
      idSuffix: 'FontColor',
    },
    {
      value: 16,
      tvValue: 24,
      propertyType: 'fontSize',
      idSuffix: 'FontSize',
    },
    {
      value: 'left',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: '100%',
      propertyType: 'width',
      idSuffix: 'Width',
    },
    {
      value: 10,
      tvValue: 15,
      propertyType: 'spacing',
      idSuffix: 'Spacing',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
      optional: true,
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
      optional: true,
    },
  ],
};

/**
 * Special editable properties
 */
export const ComponentValueFormOptionOverrides: Record<
  string,
  Array<TVariableAndIdMeta>
> = {
  contentContainer: [
    {
      value: 'transparent',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: 'transparent',
      propertyType: 'fillColorFallback',
      idSuffix: 'FillColorFallback',
      opacity: true,
      tvOnly: true,
      optional: true,
    },
    {
      value: 10,
      tvValue: 16,
      propertyType: 'spacing',
      idSuffix: 'Spacing',
    },
    {
      value: 0,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 0,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 0,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 0,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
    },
  ],
  backgroundContainer: [
    {
      value: '#ffffff',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: '#ffffff',
      propertyType: 'fillColorFallback',
      idSuffix: 'FillColorFallback',
      opacity: true,
      tvOnly: true,
      optional: true,
    },
    //TODO - consider exposing direction, alignment
  ],
  header: [
    {
      value: 'transparent',
      propertyType: 'fillColor',
      idSuffix: 'FillColor',
      opacity: true,
    },
    {
      value: 'fitContent',
      propertyType: 'height',
      idSuffix: 'Height',
    },
    {
      value: 'right',
      propertyType: 'alignment',
      idSuffix: 'Alignment',
    },
    {
      value: 0,
      tvValue: 0,
      propertyType: 'spacing',
      idSuffix: 'Spacing',
    },
    {
      value: 20,
      propertyType: 'leftPadding',
      idSuffix: 'LeftPadding',
    },
    {
      value: 20,
      propertyType: 'rightPadding',
      idSuffix: 'RightPadding',
    },
    {
      value: 0,
      propertyType: 'topPadding',
      idSuffix: 'TopPadding',
    },
    {
      value: 0,
      propertyType: 'bottomPadding',
      idSuffix: 'BottomPadding',
    },
    {
      value: 0,
      propertyType: 'leftMargin',
      idSuffix: 'LeftMargin',
    },
    {
      value: 0,
      propertyType: 'rightMargin',
      idSuffix: 'RightMargin',
    },
    {
      value: 0,
      propertyType: 'topMargin',
      idSuffix: 'TopMargin',
    },
    {
      value: 0,
      propertyType: 'bottomMargin',
      idSuffix: 'BottomMargin',
    },
  ],
};

/**
 * Special default handling for components that are children of other components
 * Note: you cannot mark a value as optional here if required by component in ComponentValueFormOptions
 */
const ComponentSubValueOverrides: Record<
  TAllContainerDetailValues,
  Record<string, Array<TVariableAndIdMeta>>
> = {
  contentContainer: {},
  header: {
    closeButton: [
      {
        value: {
          function: 'namiClosePaywall',
        },
        propertyType: 'onTap',
        idSuffix: 'OnTap',
      },
      {
        value: 'transparent',
        propertyType: 'fillColor',
        idSuffix: 'FillColor',
        opacity: true,
      },
      {
        value: 'transparent',
        propertyType: 'fillColorFallback',
        idSuffix: 'FillColorFallback',
        opacity: true,
        tvOnly: true,
        optional: true,
      },
      {
        value: 'transparent',
        propertyType: 'borderColor',
        idSuffix: 'BorderColor',
        opacity: true,
        optional: true,
      },
      {
        value: 0,
        propertyType: 'borderWidth',
        idSuffix: 'BorderWidth',
        optional: true,
      },
      {
        value: 0,
        propertyType: 'borderRadius',
        idSuffix: 'BorderRadius',
        optional: true,
      },
      {
        value: '#eeeeee',
        propertyType: 'focusedFillColor',
        idSuffix: 'FocusedFillColor',
        opacity: true,
        tvOnly: true,
      },
      {
        value: '#eeeeee',
        propertyType: 'focusedFillColorFallback',
        idSuffix: 'FocusedFillColorFallback',
        tvOnly: true,
        optional: true,
      },
      {
        value: '#ffffff',
        propertyType: 'focusedBorderColor',
        idSuffix: 'FocusedBorderColor',
        opacity: true,
        tvOnly: true,
      },
      {
        value: 2,
        propertyType: 'focusedBorderWidth',
        idSuffix: 'FocusedBorderWidth',
        tvOnly: true,
      },
      {
        value: 0,
        propertyType: 'focusedBorderRadius',
        idSuffix: 'FocusedBorderRadius',
        tvOnly: true,
      },
      {
        value: '0 0 0 rgba(0 0 0 / 0.0)',
        propertyType: 'dropShadow',
        idSuffix: 'DropShadow',
        optional: true,
      },
      {
        value: 'center',
        propertyType: 'alignment',
        idSuffix: 'Alignment',
      },
      {
        value: 'fitContent',
        propertyType: 'width',
        idSuffix: 'Width',
      },
      {
        value: 50,
        propertyType: 'height',
        idSuffix: 'Height',
      },
      {
        value: 0,
        tvValue: 0,
        propertyType: 'leftPadding',
        idSuffix: 'LeftPadding',
      },
      {
        value: 0,
        tvValue: 0,
        propertyType: 'rightPadding',
        idSuffix: 'RightPadding',
      },
      {
        value: 0,
        tvValue: 0,
        propertyType: 'topPadding',
        idSuffix: 'TopPadding',
      },
      {
        value: 0,
        tvValue: 0,
        propertyType: 'bottomPadding',
        idSuffix: 'BottomPadding',
      },
      {
        value: 0,
        propertyType: 'leftMargin',
        idSuffix: 'LeftMargin',
        optional: true,
      },
      {
        value: 0,
        propertyType: 'rightMargin',
        idSuffix: 'RightMargin',
        optional: true,
      },
      {
        value: 0,
        propertyType: 'topMargin',
        idSuffix: 'TopMargin',
        optional: true,
      },
      {
        value: 0,
        propertyType: 'bottomMargin',
        idSuffix: 'BottomMargin',
        optional: true,
      },
    ],
  },
  footer: {
    legalText: [
      {
        value: 'legal',
        propertyType: 'textType',
      },
      {
        value: '${legal.clickwrap_text}',
        propertyType: 'text',
        idSuffix: 'Text',
      },
      {
        value: 'Helvetica',
        propertyType: 'fontName',
        idSuffix: 'FontName',
      },
      {
        value: '#000000',
        propertyType: 'fontColor',
        idSuffix: 'FontColor',
      },
      {
        value: '#0096FF',
        propertyType: 'linkColor',
        idSuffix: 'LinkColor',
      },
      {
        value: 12,
        propertyType: 'fontSize',
        idSuffix: 'FontSize',
      },
      {
        value: 'center',
        propertyType: 'alignment',
        idSuffix: 'Alignment',
      },
      {
        value: '100%',
        propertyType: 'width',
        idSuffix: 'Width',
      },
      {
        value: 'fitContent',
        propertyType: 'height',
        idSuffix: 'Height',
      },
      {
        value: 0,
        propertyType: 'leftPadding',
        idSuffix: 'LeftPadding',
      },
      {
        value: 0,
        propertyType: 'rightPadding',
        idSuffix: 'RightPadding',
      },
      {
        value: 0,
        propertyType: 'topPadding',
        idSuffix: 'TopPadding',
      },
      {
        value: 0,
        propertyType: 'bottomPadding',
        idSuffix: 'BottomPadding',
      },
      {
        value: 0,
        propertyType: 'leftMargin',
        idSuffix: 'LeftMargin',
      },
      {
        value: 0,
        propertyType: 'rightMargin',
        idSuffix: 'RightMargin',
      },
      {
        value: 0,
        propertyType: 'topMargin',
        idSuffix: 'TopMargin',
      },
      {
        value: 0,
        propertyType: 'bottomMargin',
        idSuffix: 'BottomMargin',
      },
    ],
  },
  backgroundContainer: {},
  bulletList: {},
  carouselBulletList: {},
  customList: {},
  listItem: {
    icon: [
      {
        value: '${icon.check}',
        propertyType: 'name',
        idSuffix: 'IconIcon',
      },
      {
        value: '#000000',
        propertyType: 'fontColor',
        idSuffix: 'IconFontColor',
      },
      {
        value: 16,
        tvValue: 24,
        propertyType: 'fontSize',
        idSuffix: 'IconFontSize',
      },
    ],
    bodyText: [
      {
        value: 'body',
        propertyType: 'textType',
      },
      {
        value: 'This is a list item',
        propertyType: 'text',
        idSuffix: 'BodyTextText',
      },
      {
        value: 'Helvetica',
        propertyType: 'fontName',
        idSuffix: 'BodyTextFontName',
      },
      {
        value: '#000000',
        propertyType: 'fontColor',
        idSuffix: 'BodyTextFontColor',
      },
      {
        value: 16,
        tvValue: 24,
        propertyType: 'fontSize',
        idSuffix: 'BodyTextFontSize',
      },
      {
        value: 'left',
        propertyType: 'alignment',
        idSuffix: 'BodyTextAlignment',
      },
      {
        value: '100%',
        propertyType: 'width',
        idSuffix: 'BodyTextWidth',
      },
      {
        value: 'fitContent',
        propertyType: 'height',
        idSuffix: 'BodyTextHeight',
      },
      {
        value: false,
        propertyType: 'strikethrough',
        idSuffix: 'BodyTextStrikethrough',
      },
      {
        value: 0,
        propertyType: 'leftPadding',
        idSuffix: 'BodyTextLeftPadding',
      },
      {
        value: 0,
        propertyType: 'rightPadding',
        idSuffix: 'BodyTextRightPadding',
      },
      {
        value: 0,
        propertyType: 'topPadding',
        idSuffix: 'BodyTextTopPadding',
      },
      {
        value: 0,
        propertyType: 'bottomPadding',
        idSuffix: 'BodyTextBottomPadding',
      },
      {
        value: 0,
        propertyType: 'leftMargin',
        idSuffix: 'BodyTextLeftMargin',
      },
      {
        value: 0,
        propertyType: 'rightMargin',
        idSuffix: 'BodyTextRightMargin',
      },
      {
        value: 0,
        propertyType: 'topMargin',
        idSuffix: 'BodyTextTopMargin',
      },
      {
        value: 0,
        propertyType: 'bottomMargin',
        idSuffix: 'BodyTextBottomMargin',
      },
    ],
  },
  loginButton: {
    buttonText: [
      {
        value: 'body',
        propertyType: 'textType',
        idSuffix: 'TextType',
      },
      {
        value: 'Sign In',
        propertyType: 'text',
        idSuffix: 'Text',
      },
      {
        value: 'Helvetica',
        propertyType: 'fontName',
        idSuffix: 'FontName',
      },
      {
        value: '#000000',
        propertyType: 'fontColor',
        idSuffix: 'FontColor',
      },
      {
        value: '#FFFFFF',
        propertyType: 'focusedFontColor',
        idSuffix: 'FocusedFontColor',
        tvOnly: true,
      },
      {
        value: 16,
        tvValue: 24,
        propertyType: 'fontSize',
        idSuffix: 'FontSize',
      },
      {
        value: 'center',
        propertyType: 'alignment',
        idSuffix: 'Alignment',
      },
      {
        value: false,
        propertyType: 'strikethrough',
        idSuffix: 'Strikethrough',
      },
      {
        value: 'fitContent',
        propertyType: 'width',
        idSuffix: 'Width',
      },
    ],
  },
  restoreButton: {
    buttonText: [
      {
        value: 'body',
        propertyType: 'textType',
      },
      {
        value: 'Restore Purchase',
        propertyType: 'text',
        idSuffix: 'Text',
      },
      {
        value: 'Helvetica',
        propertyType: 'fontName',
        idSuffix: 'FontName',
      },
      {
        value: '#000000',
        propertyType: 'fontColor',
        idSuffix: 'FontColor',
      },
      {
        value: '#FFFFFF',
        propertyType: 'focusedFontColor',
        idSuffix: 'FocusedFontColor',
        tvOnly: true,
      },
      {
        value: 16,
        tvValue: 24,
        propertyType: 'fontSize',
        idSuffix: 'FontSize',
      },
      {
        value: 'center',
        propertyType: 'alignment',
        idSuffix: 'Alignment',
      },
      {
        value: false,
        propertyType: 'strikethrough',
        idSuffix: 'Strikethrough',
      },
      {
        value: 'fitContent',
        propertyType: 'width',
        idSuffix: 'Width',
      },
    ],
  },
  closeButton: {
    buttonText: [
      {
        value: 'body',
        propertyType: 'textType',
      },
      {
        value: 'Close',
        propertyType: 'text',
        idSuffix: 'Text',
      },
      {
        value: 'Helvetica',
        propertyType: 'fontName',
        idSuffix: 'FontName',
      },
      {
        value: '#000000',
        propertyType: 'fontColor',
        idSuffix: 'FontColor',
      },
      {
        value: '#FFFFFF',
        propertyType: 'focusedFontColor',
        idSuffix: 'FocusedFontColor',
        tvOnly: true,
      },
      {
        value: 16,
        tvValue: 24,
        propertyType: 'fontSize',
        idSuffix: 'FontSize',
      },
      {
        value: 'center',
        propertyType: 'alignment',
        idSuffix: 'Alignment',
      },
      {
        value: false,
        propertyType: 'strikethrough',
        idSuffix: 'Strikethrough',
      },
      {
        value: 'fitContent',
        propertyType: 'width',
        idSuffix: 'Width',
      },
    ],
    icon: [
      {
        value: '${icon.close}',
        propertyType: 'name',
        idSuffix: 'Icon',
      },
      {
        value: '#000000',
        propertyType: 'fontColor',
        idSuffix: 'FontColor',
      },
      {
        value: 20,
        tvValue: 40,
        propertyType: 'fontSize',
        idSuffix: 'FontSize',
      },
    ],
  },
  productButton: {
    productText: [
      {
        value: 'body',
        propertyType: 'textType',
      },
      {
        value: '${sku.productDisplayTextText}',
        formType: 'text',
        propertyType: 'text',
        namespace: 'sku',
      },
      {
        value: 'Helvetica',
        propertyType: 'fontName',
        idSuffix: 'FontName',
      },
      {
        value: '#4a4a4a',
        propertyType: 'fontColor',
        idSuffix: 'FontColor',
        featuredValue: '#000000',
        selectedValue: '#1374de',
      },
      {
        value: '#000000',
        propertyType: 'focusedFontColor',
        idSuffix: 'FocusedFontColor',
        tvOnly: true,
      },
      {
        value: 28,
        propertyType: 'fontSize',
        idSuffix: 'FontSize',
        tvValue: 24,
      },
      {
        value: 'center',
        propertyType: 'alignment',
        idSuffix: 'Alignment',
      },
      {
        value: '100%',
        propertyType: 'width',
        idSuffix: 'Width',
      },
      {
        value: 'fitContent',
        propertyType: 'height',
        idSuffix: 'Height',
      },
      {
        value: false,
        propertyType: 'strikethrough',
        idSuffix: 'Strikethrough',
      },
      {
        value: 0,
        propertyType: 'leftPadding',
        idSuffix: 'LeftPadding',
        optional: true,
      },
      {
        value: 0,
        propertyType: 'rightPadding',
        idSuffix: 'RightPadding',
        optional: true,
      },
      {
        value: 0,
        propertyType: 'topPadding',
        idSuffix: 'TopPadding',
        optional: true,
      },
      {
        value: 0,
        propertyType: 'bottomPadding',
        idSuffix: 'BottomPadding',
        optional: true,
      },
      {
        value: 0,
        propertyType: 'leftMargin',
        idSuffix: 'LeftMargin',
        optional: true,
      },
      {
        value: 0,
        propertyType: 'rightMargin',
        idSuffix: 'RightMargin',
        optional: true,
      },
      {
        value: 0,
        propertyType: 'topMargin',
        idSuffix: 'TopMargin',
        optional: true,
      },
      {
        value: 0,
        propertyType: 'bottomMargin',
        idSuffix: 'BottomMargin',
        optional: true,
      },
      {
        value: '0%',
        propertyType: 'moveX',
        idSuffix: 'MoveX',
        optional: true,
      },
      {
        value: '0%',
        propertyType: 'moveY',
        idSuffix: 'MoveY',
        optional: true,
      },
    ],
  },
  productContainer: {},
  purchaseButton: {
    buttonText: [
      {
        value: 'body',
        propertyType: 'textType',
      },
      {
        value: 'Subscribe Now',
        propertyType: 'text',
        idSuffix: 'Text',
      },
      {
        value: 'Helvetica',
        propertyType: 'fontName',
        idSuffix: 'FontName',
      },
      {
        value: '#ffffff',
        propertyType: 'fontColor',
        idSuffix: 'FontColor',
      },
      {
        value: 18,
        propertyType: 'fontSize',
        idSuffix: 'FontSize',
      },
      {
        value: 'center',
        propertyType: 'alignment',
        idSuffix: 'Alignment',
      },
      {
        value: '100%',
        propertyType: 'width',
        idSuffix: 'Width',
      },
    ],
  },
  carousel: {
    slide: [
      {
        value: [],
        propertyType: 'components',
      },
      {
        value: '${media.default}',
        propertyType: 'fillImage',
        idSuffix: 'FillImage',
        namespace: 'slide',
      },
      {
        value: 'transparent',
        propertyType: 'fillColor',
        idSuffix: 'FillColor',
        opacity: true,
      },
      {
        value: 'transparent',
        propertyType: 'borderColor',
        idSuffix: 'BorderColor',
        opacity: true,
      },
      {
        value: 0,
        propertyType: 'borderWidth',
        idSuffix: 'BorderWidth',
      },
      {
        value: 0,
        propertyType: 'borderRadius',
        idSuffix: 'BorderRadius',
      },
      {
        value: '0 0 0 rgba(0 0 0 / 0.0)',
        propertyType: 'dropShadow',
        idSuffix: 'DropShadow',
      },
      {
        value: '100%',
        propertyType: 'width',
        idSuffix: 'Width',
      },
      {
        value: 300,
        propertyType: 'height',
        idSuffix: 'Height',
      },
      {
        value: 'vertical',
        propertyType: 'direction',
        idSuffix: 'Direction',
      },
      {
        value: 10,
        propertyType: 'spacing',
        idSuffix: 'Spacing',
      },
      {
        value: 0,
        propertyType: 'leftPadding',
        idSuffix: 'LeftPadding',
      },
      {
        value: 0,
        propertyType: 'rightPadding',
        idSuffix: 'RightPadding',
      },
      {
        value: 0,
        propertyType: 'topPadding',
        idSuffix: 'TopPadding',
      },
      {
        value: 0,
        propertyType: 'bottomPadding',
        idSuffix: 'BottomPadding',
      },
      {
        value: 0,
        propertyType: 'leftMargin',
        idSuffix: 'LeftMargin',
      },
      {
        value: 0,
        propertyType: 'rightMargin',
        idSuffix: 'RightMargin',
      },
      {
        value: 0,
        propertyType: 'topMargin',
        idSuffix: 'TopMargin',
      },
      {
        value: 0,
        propertyType: 'bottomMargin',
        idSuffix: 'BottomMargin',
      },
    ],
  },
  slide: {
    spacer: [
      {
        value: '100%',
        propertyType: 'width',
        idSuffix: 'Width',
      },
      {
        value: '100%',
        propertyType: 'height',
        idSuffix: 'Height',
      },
    ],
    titleText: [
      {
        value: 'title',
        propertyType: 'textType',
      },
      {
        value: 'Slide Title',
        propertyType: 'text',
        idSuffix: '',
        namespace: 'slide',
      },
      {
        value: 'Helvetica',
        propertyType: 'fontName',
        idSuffix: 'FontName',
      },
      {
        value: '#000000',
        propertyType: 'fontColor',
        idSuffix: 'FontColor',
      },
      {
        value: 24,
        propertyType: 'fontSize',
        idSuffix: 'FontSize',
      },
      {
        value: 'center',
        propertyType: 'alignment',
        idSuffix: 'Alignment',
      },
      {
        value: '100%',
        propertyType: 'width',
        idSuffix: 'Width',
      },
      {
        value: 'fitContent',
        propertyType: 'height',
        idSuffix: 'Height',
      },
      {
        value: 0,
        propertyType: 'leftPadding',
        idSuffix: 'LeftPadding',
      },
      {
        value: 0,
        propertyType: 'rightPadding',
        idSuffix: 'RightPadding',
      },
      {
        value: 0,
        propertyType: 'topPadding',
        idSuffix: 'TopPadding',
      },
      {
        value: 0,
        propertyType: 'bottomPadding',
        idSuffix: 'BottomPadding',
      },
      {
        value: 0,
        propertyType: 'leftMargin',
        idSuffix: 'LeftMargin',
      },
      {
        value: 0,
        propertyType: 'rightMargin',
        idSuffix: 'RightMargin',
      },
      {
        value: 0,
        propertyType: 'topMargin',
        idSuffix: 'TopMargin',
      },
      {
        value: 0,
        propertyType: 'bottomMargin',
        idSuffix: 'BottomMargin',
      },
    ],
    bodyText: [
      {
        value: 'body',
        propertyType: 'textType',
      },
      {
        value: 'Slide Subtitle',
        propertyType: 'text',
        idSuffix: '',
        namespace: 'slide',
      },
      {
        value: 'Helvetica',
        propertyType: 'fontName',
        idSuffix: 'FontName',
      },
      {
        value: '#000000',
        propertyType: 'fontColor',
        idSuffix: 'FontColor',
      },
      {
        value: 18,
        propertyType: 'fontSize',
        idSuffix: 'FontSize',
      },
      {
        value: 'center',
        propertyType: 'alignment',
        idSuffix: 'Alignment',
      },
      {
        value: '100%',
        propertyType: 'width',
        idSuffix: 'Width',
      },
      {
        value: 'fitContent',
        propertyType: 'height',
        idSuffix: 'Height',
      },
      {
        value: 0,
        propertyType: 'leftPadding',
        idSuffix: 'LeftPadding',
      },
      {
        value: 0,
        propertyType: 'rightPadding',
        idSuffix: 'RightPadding',
      },
      {
        value: 0,
        propertyType: 'topPadding',
        idSuffix: 'TopPadding',
      },
      {
        value: 35,
        propertyType: 'bottomPadding',
        idSuffix: 'BottomPadding',
      },
      {
        value: 0,
        propertyType: 'leftMargin',
        idSuffix: 'LeftMargin',
      },
      {
        value: 0,
        propertyType: 'rightMargin',
        idSuffix: 'RightMargin',
      },
      {
        value: 0,
        propertyType: 'topMargin',
        idSuffix: 'TopMargin',
      },
      {
        value: 25,
        propertyType: 'bottomMargin',
        idSuffix: 'BottomMargin',
      },
    ],
  },
  productGroupToggle: {
    productGroupToggleItem: [
      {
        value: '${state.groups.0.displayName}',
        propertyType: 'text',
      },
      {
        value: 'Helvetica',
        propertyType: 'activeFontName',
        idSuffix: 'ActiveFontName',
      },
      {
        value: '#000000',
        propertyType: 'activeFontColor',
        idSuffix: 'ActiveFontColor',
      },
      {
        value: 14,
        propertyType: 'activeFontSize',
        idSuffix: 'ActiveFontSize',
      },
      {
        value: '#ffffff',
        propertyType: 'activeFillColor',
        idSuffix: 'ActiveFillColor',
      },
      {
        value: '#ffffff',
        propertyType: 'activeBorderColor',
        idSuffix: 'ActiveBorderColor',
        opacity: true,
      },
      {
        value: 0,
        propertyType: 'activeBorderWidth',
        idSuffix: 'ActiveBorderWidth',
      },
      {
        value: 30,
        propertyType: 'activeBorderRadius',
        idSuffix: 'ActiveBorderRadius',
      },
      {
        value: ['upperLeft', 'upperRight', 'lowerLeft', 'lowerRight'],
        propertyType: 'activeRoundBorders',
        idSuffix: 'ActiveRoundBorders',
      },
      {
        value: 'Helvetica',
        propertyType: 'inactiveFontName',
        idSuffix: 'InactiveFontName',
      },
      {
        value: '#ffffff',
        propertyType: 'inactiveFontColor',
        idSuffix: 'InactiveFontColor',
      },
      {
        value: 14,
        propertyType: 'inactiveFontSize',
        idSuffix: 'InactiveFontSize',
      },
      {
        value: 'transparent',
        propertyType: 'inactiveFillColor',
        idSuffix: 'InactiveFillColor',
      },
      {
        value: '#ffffff',
        propertyType: 'inactiveBorderColor',
        idSuffix: 'InactiveBorderColor',
        opacity: true,
      },
      {
        value: 0,
        propertyType: 'inactiveBorderWidth',
        idSuffix: 'InactiveBorderWidth',
      },
      {
        value: 30,
        propertyType: 'inactiveBorderRadius',
        idSuffix: 'InactiveBorderRadius',
      },
      {
        value: ['upperLeft', 'upperRight', 'lowerLeft', 'lowerRight'],
        propertyType: 'inactiveRoundBorders',
        idSuffix: 'InactiveRoundBorders',
      },
    ],
  },
  deeplinkButton: {
    buttonText: [
      {
        value: 'body',
        propertyType: 'textType',
        idSuffix: 'TextType',
      },
      {
        value: 'Go to Settings',
        propertyType: 'text',
        idSuffix: 'Text',
      },
      {
        value: 'Helvetica',
        propertyType: 'fontName',
        idSuffix: 'FontName',
      },
      {
        value: '#000000',
        propertyType: 'fontColor',
        idSuffix: 'FontColor',
      },
      {
        value: '#FFFFFF',
        propertyType: 'focusedFontColor',
        idSuffix: 'FocusedFontColor',
        tvOnly: true,
      },
      {
        value: 16,
        tvValue: 24,
        propertyType: 'fontSize',
        idSuffix: 'FontSize',
      },
      {
        value: 'center',
        propertyType: 'alignment',
        idSuffix: 'Alignment',
      },
      {
        value: false,
        propertyType: 'strikethrough',
        idSuffix: 'Strikethrough',
      },
      {
        value: '100%',
        propertyType: 'width',
        idSuffix: 'Width',
      },
    ],
  },
  navigatePageButton: {
    buttonText: [
      {
        value: 'body',
        propertyType: 'textType',
        idSuffix: 'TextType',
      },
      {
        value: 'View All Plans',
        propertyType: 'text',
        idSuffix: 'Text',
      },
      {
        value: 'Helvetica',
        propertyType: 'fontName',
        idSuffix: 'FontName',
      },
      {
        value: '#000000',
        propertyType: 'fontColor',
        idSuffix: 'FontColor',
      },
      {
        value: '#FFFFFF',
        propertyType: 'focusedFontColor',
        idSuffix: 'FocusedFontColor',
        tvOnly: true,
      },
      {
        value: 16,
        tvValue: 24,
        propertyType: 'fontSize',
        idSuffix: 'FontSize',
      },
      {
        value: 'center',
        propertyType: 'alignment',
        idSuffix: 'Alignment',
      },
      {
        value: false,
        propertyType: 'strikethrough',
        idSuffix: 'Strikethrough',
      },
      {
        value: '100%',
        propertyType: 'width',
        idSuffix: 'Width',
      },
    ],
  },
  collapse: {},
  collapseHeader: {
    titleText: [
      {
        value: 'title',
        propertyType: 'textType',
      },
      {
        value: 'More Features',
        propertyType: 'text',
        idSuffix: 'Text',
      },
      {
        value: 'Helvetica',
        propertyType: 'fontName',
        idSuffix: 'FontName',
      },
      {
        value: '#000000',
        propertyType: 'fontColor',
        idSuffix: 'FontColor',
      },
      {
        value: 16,
        propertyType: 'fontSize',
        idSuffix: 'FontSize',
      },
      {
        value: 'left',
        propertyType: 'alignment',
        idSuffix: 'Alignment',
      },
      {
        value: '100%',
        propertyType: 'width',
        idSuffix: 'Width',
      },
      {
        value: 'fitContent',
        propertyType: 'height',
        idSuffix: 'Height',
      },
    ],
    icon: [
      {
        value: '${icon.caretRight}',
        propertyType: 'name',
        idSuffix: 'IconIcon',
      },
      {
        value: '#000000',
        propertyType: 'fontColor',
        idSuffix: 'IconFontColor',
      },
      {
        value: 16,
        tvValue: 24,
        propertyType: 'fontSize',
        idSuffix: 'IconFontSize',
      },
    ],
  },
  collapseBody: {},
  stack: {},
  repeatingList: {
    group: [
      {
        value: [],
        propertyType: 'components',
      },
      {
        value: '#eeeeee',
        propertyType: 'fillColor',
        idSuffix: 'FillColor',
        opacity: true,
      },
      {
        value: 'transparent',
        propertyType: 'borderColor',
        idSuffix: 'BorderColor',
        opacity: true,
      },
      {
        value: 0,
        propertyType: 'borderWidth',
        idSuffix: 'BorderWidth',
      },
      {
        value: 0,
        propertyType: 'borderRadius',
        idSuffix: 'BorderRadius',
      },
      {
        value: ['upperLeft', 'upperRight', 'lowerLeft', 'lowerRight'],
        propertyType: 'roundBorders',
        idSuffix: 'RoundBorders',
        optional: true,
      },
      {
        value: '0 0 0 rgba(0 0 0 / 0.0)',
        propertyType: 'dropShadow',
        idSuffix: 'DropShadow',
        optional: true,
      },
      {
        value: '100%',
        propertyType: 'width',
        idSuffix: 'Width',
      },
      {
        value: 'fitContent',
        propertyType: 'height',
        idSuffix: 'Height',
      },
      {
        value: 'center',
        propertyType: 'verticalAlignment',
        idSuffix: 'VerticalAlignment',
      },
      {
        value: 'center',
        propertyType: 'horizontalAlignment',
        idSuffix: 'HorizontalAlignment',
      },
      {
        value: '0%',
        propertyType: 'moveX',
        idSuffix: 'MoveX',
        optional: true,
      },
      {
        value: '0%',
        propertyType: 'moveY',
        idSuffix: 'MoveY',
        optional: true,
      },
      {
        value: 0,
        propertyType: 'zIndex',
        idSuffix: 'ZIndex',
        optional: true,
      },
      {
        value: 'vertical',
        propertyType: 'direction',
        idSuffix: 'Direction',
      },
      {
        value: 10,
        propertyType: 'spacing',
        idSuffix: 'Spacing',
      },
      {
        value: 10,
        propertyType: 'leftPadding',
        idSuffix: 'LeftPadding',
      },
      {
        value: 10,
        propertyType: 'rightPadding',
        idSuffix: 'RightPadding',
      },
      {
        value: 10,
        propertyType: 'topPadding',
        idSuffix: 'TopPadding',
      },
      {
        value: 10,
        propertyType: 'bottomPadding',
        idSuffix: 'BottomPadding',
      },
      {
        value: 0,
        propertyType: 'leftMargin',
        idSuffix: 'LeftMargin',
        optional: true,
      },
      {
        value: 0,
        propertyType: 'rightMargin',
        idSuffix: 'RightMargin',
        optional: true,
      },
      {
        value: 0,
        propertyType: 'topMargin',
        idSuffix: 'TopMargin',
        optional: true,
      },
      {
        value: 0,
        propertyType: 'bottomMargin',
        idSuffix: 'BottomMargin',
        optional: true,
      },
    ],
  },
  playPauseButton: {
    icon: [
      {
        value: '${icon.pause}',
        propertyType: 'name',
        idSuffix: 'Icon',
      },
      {
        value: '#000000',
        propertyType: 'fontColor',
        idSuffix: 'FontColor',
      },
      {
        value: 16,
        tvValue: 24,
        propertyType: 'fontSize',
        idSuffix: 'FontSize',
      },
    ],
  },
  volumeButton: {
    icon: [
      {
        value: '${icon.mutedFilled}',
        propertyType: 'name',
        idSuffix: 'Icon',
      },
      {
        value: '#000000',
        propertyType: 'fontColor',
        idSuffix: 'FontColor',
      },
      {
        value: 16,
        tvValue: 24,
        propertyType: 'fontSize',
        idSuffix: 'FontSize',
      },
    ],
  },
  flowButton: {
    buttonText: [
      {
        value: 'body',
        propertyType: 'textType',
      },
      {
        value: 'Skip',
        propertyType: 'text',
        idSuffix: 'Text',
      },
      {
        value: 'Helvetica',
        propertyType: 'fontName',
        idSuffix: 'FontName',
      },
      {
        value: '#000000',
        propertyType: 'fontColor',
        idSuffix: 'FontColor',
      },
      {
        value: '#FFFFFF',
        propertyType: 'focusedFontColor',
        idSuffix: 'FocusedFontColor',
        tvOnly: true,
      },
      {
        value: 16,
        tvValue: 24,
        propertyType: 'fontSize',
        idSuffix: 'FontSize',
      },
      {
        value: 'center',
        propertyType: 'alignment',
        idSuffix: 'Alignment',
      },
      {
        value: false,
        propertyType: 'strikethrough',
        idSuffix: 'Strikethrough',
      },
      {
        value: '100%',
        propertyType: 'width',
        idSuffix: 'Width',
      },
    ],
    icon: [
      {
        value: '${icon.caretRight}',
        propertyType: 'name',
        idSuffix: 'Icon',
      },
      {
        value: '#000000',
        propertyType: 'fontColor',
        idSuffix: 'FontColor',
      },
      {
        value: 12,
        tvValue: 24,
        propertyType: 'fontSize',
        idSuffix: 'FontSize',
      },
    ],
  },
};

/**
 * Settings for adding capabilities to existing static components
 */
export const ComponentCapabilitySettings: {
  [type: string]: {
    trialValue?: string;
    promoValue?: string;
    introValue?: string;
  };
} = {
  titleText: {
    trialValue: 'Get started for free',
    introValue: 'Introductory offer',
    promoValue: 'Special offer',
  },
  legalText: {
    trialValue:
      'Free trial offer available to new subscribers only. ${legal.clickwrap_text}',
    promoValue: 'Subscription renews at regular price. ${legal.clickwrap_text}',
    introValue:
      'Offer available to new subscribers only. Subscription renews at regular price. ${legal.clickwrap_text}',
  },
};

/**
 * Fallback values if property value isn't declared in for component in ComponentValueFormOptions or ComponentSubValueOverrides
 */
const DefaultComponentProperty: Record<TComponentProperty, any> = {
  width: '100%',
  height: 'fitContent',
  url: '${media.default}',
  fillImage: '${media.default}',
  text: 'This is text',
  texts: ['This is a list', 'List your best features'],
  textType: 'bodyText',
  fontSize: 12,
  fontColor: '#000000',
  linkColor: '#1374de',
  fontName: 'Helvetica',
  alignment: 'left',
  verticalAlignment: 'top',
  horizontalAlignment: 'left',
  spacing: 0,
  strikethrough: false,
  leftPadding: 0,
  rightPadding: 0,
  topPadding: 0,
  bottomPadding: 0,
  leftMargin: 0,
  rightMargin: 0,
  topMargin: 0,
  bottomMargin: 0,
  name: '${icon.checkmarkCircle}',
  fillColor: 'transparent',
  borderColor: 'transparent',
  borderRadius: 0,
  roundBorders: ['upperLeft', 'upperRight', 'lowerLeft', 'lowerRight'],
  borderWidth: 1,
  onTap: {
    function: 'closePaywall',
  },
  direction: 'horizontal',
  components: [],
  imageCropping: 'fill',
  dropShadow: '0 0 0 rgba(0 0 0 / 0.0)',
  moveX: '0%',
  moveY: '0%',
  zIndex: 1,
  loopSource: '${state.slides.${state.currentGroupId}.carouselName}',
  loopVariable: 'slide',
  'ui.name': 'carouselName',
  activeIndicatorColor: '#76777',
  indicatorColor: '#ffffff',
  autoplay: true,
  autoplayVideo: true,
  autoplaySeconds: 5,
  showIndicators: true,
  products: 'all',
  grow: false,
  activeFontName: 'Helvetica',
  activeFontSize: 15,
  activeFillColor: '#ffffff',
  activeFontColor: '#000000',
  activeBorderColor: '#ffffff',
  activeBorderWidth: 0,
  activeBorderRadius: 30,
  activeRoundBorders: ['upperLeft', 'upperRight', 'lowerLeft', 'lowerRight'],
  inactiveFontName: 'Helvetica',
  inactiveFontSize: 15,
  inactiveFillColor: 'transparent',
  inactiveFontColor: '#ffffff',
  inactiveBorderColor: '#ffffff',
  inactiveBorderWidth: 0,
  inactiveBorderRadius: 30,
  inactiveRoundBorders: ['upperLeft', 'upperRight', 'lowerLeft', 'lowerRight'],
  segmentId: '${state.groups.0.id}',
  screenreaderText: '',
  focusedFillColor: '#eeeee',
  focusedFillColorFallback: '#eeeeee',
  fillColorFallback: '#ffffff',
  focusedBorderColor: '#ffffff',
  focusedBorderRadius: 30,
  focusedBorderWidth: 2,
  focusedFontColor: '#000000',
  fallbackImage: '${media.default}',
  controlsType: 'none',
  mute: true,
  open: false,
  loopVideo: true,
  title: 'Component Name',
  pausedOnTap: {
    function: 'namiPlayVideo',
  },
  playingOnTap: {
    function: 'namiPauseVideo',
  },
  volumeOnTap: {
    function: 'namiMuteVideo',
  },
  mutedOnTap: {
    function: 'namiUnmuteVideo',
  },
  playingComponents: [],
  pausedComponents: [],
  volumeComponents: [],
  mutedComponents: [],
  dateTimeFormat: 'EEEE, MMM d',
};

const TComponentDetailValueMap: Record<
  TAllComponentDetailValues,
  {
    type: string;
    children: Array<TAllComponentDetailValues>;
    topComponentTransform?: Function;
  }
> = {
  contentContainer: {
    type: 'container',
    children: [],
  },
  header: {
    type: 'container',
    children: ['closeButton'],
  },
  backgroundContainer: {
    type: 'container',
    children: [],
  },
  titleText: {
    type: 'text',
    children: [],
  },
  bodyText: {
    type: 'text',
    children: [],
  },
  carouselTitleText: {
    type: 'text',
    children: [],
  },
  carouselBodyText: {
    type: 'text',
    children: [],
  },
  carouselBulletList: {
    type: 'text-list',
    children: ['bulletComponent'],
  },
  carouselImage: {
    type: 'image',
    children: [],
  },
  textBlock: {
    type: 'text',
    children: [],
  },
  productText: {
    type: 'text',
    children: [],
  },
  buttonText: {
    type: 'text',
    children: [],
  },
  legalText: {
    type: 'text',
    children: [],
  },
  bulletList: {
    type: 'text-list',
    children: ['bulletComponent'],
  },
  bulletComponent: {
    type: 'symbol',
    children: [],
  },
  customList: {
    type: 'container',
    children: ['listItem'],
  },
  listItem: {
    type: 'container',
    children: ['icon', 'bodyText'],
  },
  icon: {
    type: 'symbol',
    children: [],
  },
  loginButton: {
    type: 'button',
    children: ['buttonText'],
  },
  restoreButton: {
    type: 'button',
    children: ['buttonText'],
  },
  closeButton: {
    type: 'button',
    children: ['icon'],
  },
  group: {
    type: 'container',
    children: [],
  },
  productContainer: {
    type: 'productContainer',
    children: ['productButton'], //TODO
  },
  productWrapper: {
    type: 'container',
    children: [],
  },
  productButton: {
    type: 'button',
    children: ['productText'],
  },
  image: {
    type: 'image',
    children: [],
  },
  hostedImage: {
    type: 'image',
    children: [],
  },
  videoUrl: {
    type: 'videoUrl',
    children: [],
  },
  productBadge: {
    type: 'text',
    children: [],
  },
  condition: {
    type: 'condition',
    children: [],
  },
  purchaseButton: {
    type: 'button',
    children: ['buttonText'],
  },
  spacer: {
    type: 'spacer',
    children: [],
  },
  carousel: {
    type: 'carouselContainer',
    children: ['slide'],
  },
  slide: {
    type: 'container',
    children: ['spacer', 'carouselTitleText', 'carouselBodyText'],
  },
  divider: {
    type: 'container',
    children: [],
  },
  productGroupToggle: {
    type: 'segmentPicker',
    children: ['productGroupToggleItem'],
  },
  productGroupToggleItem: {
    type: 'segmentPickerItem',
    children: [],
  },
  deeplinkButton: {
    type: 'button',
    children: ['buttonText'],
    topComponentTransform: customizeDeeplinkButton,
  },
  navigatePageButton: {
    type: 'button',
    children: ['buttonText'],
    topComponentTransform: customizeNavigatePageButton,
  },
  linkButton: {
    type: 'text',
    children: [],
  },
  footer: {
    type: 'container',
    children: ['legalText'],
  },
  collapse: {
    type: 'collapseContainer',
    children: ['collapseBody'],
    topComponentTransform: customizeCollapseOpen,
  },
  collapseHeader: {
    type: 'container',
    children: ['titleText', 'icon'],
  },
  collapseBody: {
    type: 'container',
    children: [],
  },
  stack: {
    type: 'stack',
    children: [],
  },
  repeatingList: {
    type: 'responsiveGrid',
    children: ['group'],
  },
  repeatingTextSource: {
    type: 'text',
    children: [],
  },
  repeatingImageSource: {
    type: 'image',
    children: [],
  },
  playPauseButton: {
    type: 'playPauseButton',
    children: ['icon'],
  },
  volumeButton: {
    type: 'volumeButton',
    children: ['icon'],
  },
  flowButton: {
    type: 'button',
    children: ['buttonText'],
  },
};
